import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { MDBBtn, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Link, useHistory } from "react-router-dom";
// config
import configData from "../../config/config.json";
//
import { DomainContext, LangIdContext } from "../../App";
import { validate } from "./validate";
// components
import MetaTagsEndUserPage from "../meta-tags-seo/MetaTagsEndUserPage";
import Footer from "../footer/Footer";
import AlertSuccess from "../Dashbord/AlertSuccess/AlertSuccess";
// imgs
import logo from "../../images/home-body/logo gishniz.png";

const ForgetPass = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);
  //submit btn disable
  const [btnDisable, setBtnDisable] = useState(false);
  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  const [ApiDataCome, setApiDataCome] = useState({
    // header: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  //
  const [showAlert, setshowAlert] = useState(false);
  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 5000);
  };

  const [inputData, setInputData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const history = useHistory();

  useEffect(() => {
    setErrors(validate(inputData, "forget-pass"));
  }, [inputData, touched]);

  const inputChangeHandler = (event) => {
    setInputData({ ...inputData, [event.target.name]: event.target.value });
  };

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };
  //api header
  const headerMeta = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Page: "forget-password",
  };

  useEffect(() => {
    axios
      .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
        headers: headerMeta,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setMTagsCome(true);
          setMetaData(response.data.data);
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 2 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  const [apiError, setApiError] = useState([]);

  const submitHandler = (event) => {
    event.preventDefault();
    setApiError([]);

    if (!Object.keys(errors).length) {
      setBtnDisable(true);

      axios
        .post(
          configData.API_DOMAIN + "/api/v1/Members/forget-password",
          { email: inputData.email },
          {
            headers: header,
          }
        )
        .then((response) => {
          setBtnDisable(false);

          if (response.data.isSuccess) {
            alertHandeler();
          } else {
            setApiError(response.data.message);
          }
        })
        .catch((error) => {
          // alert(error);
        });

      // success
      // history.push("/change-password");
    } else {
      setTouched({
        email: true,
      });
    }
  };

  return (
    <>
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} /> : ""}

      <div className="centerr">
        <div className="centerr d-none d-md-block">
          <Link to="/" className=" logo-login-a ">
            <img src={logo} alt="logo gishniz" className="logo-login-lap " />
          </Link>
        </div>

        <div className="login-container centerr login-container-forget">
          <MDBContainer className="">
            <MDBRow className="">
              <div className="col-12 text-center">
                <div className="centerr">
                  <Link to="/" className="logo-login-a d-block d-md-none">
                    <img
                      src={logo}
                      alt="logo gishniz"
                      className="logo-login logo-forget-pass"
                    />
                  </Link>
                </div>

                <p className=" login-matn1 fs-xss-21 color-515151 fw-bold mt-3 mt-md-0 mb-2">
                  فراموشی رمز عبور
                </p>
                <p className="fs-xss-12 color-8d8c8c mb-4 pb-2 pb-md-0 login-matn2">
                  برای دریافت رمز جدید ایمیل خود را وارد کنید.
                </p>
              </div>
              <div className="col-12 mt-1 padding-xss-32">
                <div className="">
                  <form>
                    <input
                      dir="ltr"
                      name="email"
                      value={inputData.email}
                      onChange={inputChangeHandler}
                      // onFocus={inputFocusHanlder}
                      className={
                        errors.email && touched.email
                          ? "input-login font-family-en mb-md-1 border-error"
                          : "input-login font-family-en mb-md-1"
                      }
                      type="email"
                      placeholder="ایمیل"
                    />
                    {errors.email && touched.email && (
                      <span className="input-error">{errors.email}</span>
                    )}
                    {apiError.map((item) => (
                      <span className="input-error">{item}</span>
                    ))}

                    <MDBBtn
                      disabled={btnDisable}
                      onClick={submitHandler}
                      type="submit"
                      className="shadow-off hov-shadow-btn submit-login-btn text-center mb-forget-pass"
                    >
                      ارسال ایمیل
                    </MDBBtn>
                  </form>
                </div>
              </div>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
      <Footer data={footerData} dataCome={ApiDataCome.footer} />
      <AlertSuccess
        showAlert={showAlert}
        text={
          "لینک بازیابی رمز عبور به ایمیل شما ارسال شد.اگر لینک را دریافت نکردید، لطفا اسپم را چک کنید."
        }
      />
    </>
  );
};

export default ForgetPass;
