import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { MDBBtn, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import axios from "axios";
//
import { DomainContext, LangIdContext, CookieContext } from "../../App";
import { validate } from "../login/validate";
// config
import configData from "../../config/config.json";
// components
import MetaTagsEndUserPage from "../meta-tags-seo/MetaTagsEndUserPage";
import Facebook from "../login/Facebook";
import Google from "../login/Google";
import Footer from "../footer/Footer";
import RulesModal from "../rules-modal/RulesModal";
// imgs
import closeIcon from "../../images/header/close.svg";
import logo from "../../images/home-body/logo gishniz.png";
//
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Signup = (props) => {
  const { state } = props.location;

  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);
  // cookie context
  const { cookieState, setCookieState } = useContext(CookieContext);

  const [render, setRender] = useState(false);

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  const [existErr, setExistErr] = useState();
  const [fillErr, setFillErr] = useState();
  const [serverErr, setServerErr] = useState();
  const [disable, setDisable] = useState(false);
  const [apiError, setApiError] = useState([]);

  const [inputData, setInputData] = useState({
    fname: "",
    lname: "",
    email: "",
    username: "",
    password: "",
    isAccepted: false,
  });

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const history = useHistory();

  useEffect(() => {
    setErrors(validate(inputData, "signup"));
  }, [inputData, touched]);

  const inputChangeHandler = (event) => {
    if (event.target.name === "isAccepted") {
      if (inputData.isAccepted) {
        setInputData({
          ...inputData,
          [event.target.name]: false,
        });
      } else {
        setInputData({
          ...inputData,
          [event.target.name]: true,
        });
      }
    } else {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
    }
  };

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };

  const submitHandler = (event) => {
    event.preventDefault();
    // console.log("err" + Object.keys(errors));

    if (!Object.keys(errors).length) {
      // success
      // if (state) {
      //   props.history.push({
      //     pathname: "/introduce-business/introduce-business-thanks",
      //     state: state, // your data array of objects
      //   });
      setApiError([]);
      setExistErr(false);
      setServerErr(false);
      setFillErr(false);
      setDisable(true);

      const data = {
        email: inputData.email,
        password: inputData.password,
        lastname: inputData.lname,
        firstname: inputData.fname,
        username: inputData.username,
        // google login data
        oAuthProvider: "webapp",
        oAuthUID: "",
        locale: "",
        photoPath: "",
      };

      axios
        .post(configData.API_DOMAIN + "/api/v1/Members", data, {
          headers: header,
        })
        .then((response) => {
          setDisable(false);
          if (response.data.isSuccess) {
            setCookie("token", response.data.data.token.token, 1);
            setCookie("MemId", response.data.data.memberDetail.mId, 1);

            localStorage.setItem(
              "MemberDetail",
              JSON.stringify(response.data.data.memberDetail)
            ); //set local
            setCookieState(response.data.data.memberDetail.mId); // set context

            if (state == null) {
              props.history.push({
                pathname: "/",
                // state: response.data.OwnerDetail.OwnerId, // your data array of objects
              });
            } else {
              if (state == "from-introduce") {
                // az moarefi miad
                props.history.push({
                  pathname: "/introduce-business/introduce-business-thanks",
                });
              } else {
                // az biz page miad
                props.history.push({
                  pathname: state,
                });
              }
            }
          } else {
            // isSuccess = false
            setApiError(response.data.message);
          }

          // if (response.data.msg == "done") {
          //   setCookie("MemId", response.data.MemberDetail.MemId, 1);
          //   localStorage.setItem(
          //     "MemberDetail",
          //     JSON.stringify(response.data.MemberDetail)
          //   ); //set local
          //   setCookieState(response.data.MemberDetail.MemId);

          //   if (state == null) {
          //     props.history.push({
          //       pathname: "/",
          //       // state: response.data.OwnerDetail.OwnerId, // your data array of objects
          //     });
          //   } else {
          //     if (state == "from-introduce") {
          //       // az moarefi miad
          //       props.history.push({
          //         pathname: "/introduce-business/introduce-business-thanks",
          //       });
          //     } else {
          //       // az biz page miad
          //       props.history.push({
          //         pathname: state,
          //       });
          //     }
          //   }
          // } else if (response.data.msg == "member-exist") {
          //   setExistErr(true);
          // } else if (response.data.msg == "error") {
          //   setServerErr(true);
          // } else if (response.data.msg == "fill-all") {
          //   setFillErr(true);
          // }
        });
      // }
    } else {
      setTouched({
        // fname: true,
        // lname: true,
        email: true,
        username: true,
        password: true,
        isAccepted: true,
      });
    }
  };

  //   setCookie
  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const [ApiDataCome, setApiDataCome] = useState({
    footer: false,
  });
  const [footerData, setFooterData] = useState({});

  //api header
  const header2 = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };

  //api header
  const headerMeta = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Page: "register",
  };

  useEffect(() => {
    setRender(false);
    if (getCookie("MemId")) {
      props.history.push({
        pathname: "/members/profile",
      });
    } else {
      setRender(true);
      axios
        .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
          headers: headerMeta,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setMTagsCome(true);
            setMetaData(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
      // 2 footer
      axios
        .get(configData.API_DOMAIN + "/api/v1/Footers", {
          headers: header,
        })
        .then((response) => {
          setFooterData(response.data.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
        })
        .catch((error) => {
          // alert(error);
        });
    }
  }, []);

  // for password btn icon
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // username check
  const [usernameLoading, setUsernameLoading] = useState("");
  const [usernameCheckError, setUsernameCheckError] = useState("");
  const usernameCheck = () => {
    // console.log(inputData.username);
    if (inputData.username !== "") {
      setUsernameCheckError("");
      setUsernameLoading("spinner");
      setDisable(true);

      var username = inputData.username;
      axios
        .get(
          configData.API_DOMAIN + "/api/v1/Members/exists-username/" + username,
          {
            headers: header,
          }
        )
        .then((response) => {
          if (response.data.isSuccess) {
            if (!response.data.data) {
              setUsernameLoading("done");
              setDisable(false);
            } else {
              setUsernameCheckError(
                "این نام کاربری قبلا در گیشنیز ثبت شده است."
              );
              setUsernameLoading("");
              setDisable(false);
            }
          } else {
            setUsernameLoading("");
            setDisable(false);
            setApiError(response.data.message);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setUsernameLoading("");
    }
  };
  // email check
  const [emailLoading, setEmailLoading] = useState("");
  const [emailCheckError, setEmailCheckError] = useState("");
  const emailCheck = () => {
    setEmailLoading("");
    setEmailCheckError("");

    if (inputData.email !== "" && !/\S+@\S+\.\S+/.test(inputData.email)) {
      setEmailCheckError("آدرس ایمیل نامعتبر است.");
      setEmailLoading("");
    } else if (inputData.email !== "" && !errors.email) {
      setEmailCheckError("");
      setEmailLoading("spinner");
      setDisable(true);

      var email = inputData.email;
      axios
        .get(configData.API_DOMAIN + "/api/v1/Members/exists-email/" + email, {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            if (!response.data.data) {
              setEmailLoading("done");
              setDisable(false);
            } else {
              setEmailCheckError("این ایمیل قبلا در گیشنیز ثبت شده است.");
              setEmailLoading("");
              setDisable(false);
            }
          } else {
            setEmailLoading("");
            setDisable(false);
            setApiError(response.data.message);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setEmailLoading("");
    }
  };

  return (
    <>
      {render ? (
        <>
          {MTagsCome ? <MetaTagsEndUserPage data={MetaData} /> : ""}

          <div className="centerr">
            <div className="centerr d-none d-md-block">
              <Link to="/" className=" logo-login-a ">
                <img src={logo} alt="logo gishniz" className="logo-login-lap" />
              </Link>
            </div>

            <div className="login-container centerr">
              <MDBContainer className="">
                <MDBRow className="text-end">
                  <Link to="/" className="a-close-img">
                    <img
                      className="login-close-img me-0"
                      src={closeIcon}
                      alt="close icon"
                    />
                  </Link>
                </MDBRow>
                <MDBRow className="">
                  <div className="col-12 text-center">
                    <div className="centerr">
                      <Link to="/" className=" logo-login-a d-block d-md-none">
                        <img
                          src={logo}
                          alt="logo gishniz"
                          className="logo-login "
                        />
                      </Link>
                    </div>

                    <p className=" login-matn1 fs-xss-21 color-515151 fw-bold mt-3 mt-md-0 mb-2">
                      ثبت نام
                    </p>
                    <p className="fs-xss-12 color-8d8c8c mb-4 pb-2 pb-md-0 login-matn2">
                      برای استفاده از امکانات وبسایت لطفا ثبت‌نام کنید.
                    </p>
                  </div>
                  <div className="col-12 mt-1 padding-xss-32">
                    {/* <MDBBtn className="shadow-off account-login-btn account-signup-btn text-start">
                  ثبت‌نام با فیسبوک
                  <img
                    className="account-login-img"
                    src={facebook_login}
                    alt=""
                  />
                </MDBBtn> */}
                    {/* <Facebook /> */}

                    {/* <Google backUrl={state} />  ?????? */}
                    <Google
                      backUrl={
                        state == "from-introduce"
                          ? "/introduce-business/introduce-business-thanks"
                          : state
                      }
                    />

                    <div className="centerr">
                      <div className="divider-login d-flex align-items-center">
                        <p className="text-center mb-0 divider-login-text fs-xss-12">
                          ثبت‌نام با نام کاربری
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <form>
                        <input
                          dir="ltr"
                          name="fname"
                          value={inputData.fname}
                          onChange={inputChangeHandler}
                          className={
                            errors.fname && touched.fname
                              ? "input-login mb-md-1 mb-md-1 border-error"
                              : "input-login mb-md-1 mb-md-1"
                          }
                          type="text"
                          placeholder="نام"
                        />

                        {errors.fname && touched.fname && (
                          <span className="input-error">{errors.fname}</span>
                        )}

                        <input
                          dir="ltr"
                          name="lname"
                          value={inputData.lname}
                          onChange={inputChangeHandler}
                          // onFocus={inputFocusHanlder}
                          className={
                            errors.lname && touched.lname
                              ? "input-login mb-md-1 border-error mt-2"
                              : "input-login mb-md-1 mt-2"
                          }
                          type="text"
                          placeholder="نام خانوادگی"
                        />
                        {errors.lname && touched.lname && (
                          <span className="input-error">{errors.lname}</span>
                        )}

                        <div className="position-relative w-md-fit">
                          <input
                            dir="ltr"
                            name="email"
                            value={inputData.email}
                            onChange={inputChangeHandler}
                            onBlur={emailCheck}
                            // onFocus={inputFocusHanlder}
                            className={
                              errors.email && touched.email
                                ? "input-login font-family-en mb-md-1 border-error mt-2"
                                : "input-login font-family-en mb-md-1 mt-2"
                            }
                            type="email"
                            placeholder="ایمیل"
                          />
                          {emailCheckError !== "" && (
                            <span className="input-error mx-0">
                              {emailCheckError}
                            </span>
                          )}
                          {emailLoading == "done" ? (
                            <CheckCircleIcon
                              color="inherit"
                              sx={{ fontSize: 20, color: "#33e0a0" }}
                              className="signup-input-spinner"
                            />
                          ) : (
                            ""
                          )}
                          {emailLoading == "spinner" ? (
                            <CircularProgress
                              color="inherit"
                              size={18}
                              className="signup-input-spinner"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.email && touched.email && (
                          <span className="input-error">{errors.email}</span>
                        )}
                        <div className="position-relative w-md-fit">
                          <input
                            dir="ltr"
                            name="username"
                            value={inputData.username}
                            onChange={inputChangeHandler}
                            onBlur={usernameCheck}
                            // onFocus={inputFocusHanlder}
                            className={
                              errors.username && touched.username
                                ? "input-login font-family-en mb-md-1 border-error mt-2"
                                : "input-login font-family-en mb-md-1 mt-2"
                            }
                            type="text"
                            placeholder="نام کاربری"
                          />
                          {usernameCheckError !== "" && (
                            <span className="input-error mx-0">
                              {usernameCheckError}
                            </span>
                          )}
                          {usernameLoading == "done" ? (
                            <CheckCircleIcon
                              color="inherit"
                              sx={{ fontSize: 20, color: "#33e0a0" }}
                              className="signup-input-spinner"
                            />
                          ) : (
                            ""
                          )}
                          {usernameLoading == "spinner" ? (
                            <CircularProgress
                              color="inherit"
                              size={18}
                              className="signup-input-spinner"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.username && touched.username && (
                          <span className="input-error">{errors.username}</span>
                        )}

                        <div className="position-relative">
                          <input
                            dir="ltr"
                            type={showPassword ? "text" : "password"}
                            maxLength="25"
                            name="password"
                            value={inputData.password}
                            onChange={inputChangeHandler}
                            // onFocus={inputFocusHanlder}
                            className={
                              errors.password && touched.password
                                ? "input-login font-family-en input-login-pass border-error mt-2"
                                : "input-login font-family-en input-login-pass mt-2"
                            }
                            placeholder="رمز عبور"
                          />
                          <IconButton
                            className="show-pass-btn"
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="start"
                          >
                            {showPassword ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </div>
                        {errors.password && touched.password && (
                          <span className="input-error">{errors.password}</span>
                        )}

                        <div className="rightt position-relative">
                          <label className="container-s2 signup-checkbox">
                            <input
                              type="checkbox"
                              name="isAccepted"
                              value={inputData.isAccepted}
                              onChange={inputChangeHandler}
                            />
                            <span className="checkmark-s2 border-radius-50percent"></span>
                          </label>
                          <RulesModal />

                          <br />
                          {/* {errors.isAccepted && touched.isAccepted && <span  className="mt-5">{errors.isAccepted}</span>} */}
                          {errors.isAccepted && touched.isAccepted && (
                            <span className="input-error mt-md-n3 mt-n2 pt-1">
                              {errors.isAccepted}
                            </span>
                          )}
                        </div>
                        {existErr && (
                          <span className="input-error">ایمیل تکراری است.</span>
                        )}
                        {serverErr && (
                          <span className="input-error">
                            خطا در ارتباط با سرور، لطفا دقایقی دیگر مجددا تلاش
                            نمایید.
                          </span>
                        )}
                        {fillErr && (
                          <span className="input-error">
                            لطفا همه موارد را پر کنید.
                          </span>
                        )}
                        {apiError.map((item) => (
                          <span className="input-error mt-md-n2">{item}</span>
                        ))}

                        <MDBBtn
                          disabled={disable}
                          onClick={submitHandler}
                          type="submit"
                          className="shadow-off hov-shadow-btn submit-login-btn text-center"
                        >
                          ثبت‌نام در گیشنیز
                        </MDBBtn>
                        <div className="d-flex justify-content-center mb-4 fs-14 fs-xss-14">
                          <p className="color-242b2e">حساب کاربری دارید؟ </p>
                          <Link className="color-5ae6a2" to="/login">
                            <p className="text-end me-2">
                              ورود به حساب کاربری{" "}
                            </p>
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </MDBRow>
              </MDBContainer>
            </div>
          </div>
          <Footer data={footerData} dataCome={ApiDataCome.footer} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Signup;
