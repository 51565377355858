import React, { useState, useEffect, useContext } from "react";
import Skeleton from "@mui/material/Skeleton";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { DomainContext } from "../../App";
import axios from "axios";
// config
import configData from "../../config/config.json";
// css
import "./rules-modal.css";

const RulesModal = (props) => {
  const [centredModal, setCentredModal] = useState(false);

  //   const toggleShow = () => {
  //     setCentredModal(!centredModal);
  //   };
  const [basicModal, setBasicModal] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);

  // for domain of Apis
  const domain = useContext(DomainContext);

  const [pageContent, setPageContent] = React.useState("");

  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: "1",
    FooterId: "5",
  };
  useEffect(() => {
    //  page content
    axios
      .get(configData.API_DOMAIN + `/api/v1/Contents?FooterId=5`, {
        headers: header,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setPageContent(response.data.data.itemContent);
        } else {
          setPageContent("0");
        }
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  return (
    <>
      <p
        className="business-information-text"
        onClick={() => {
          setCentredModal(true);
        }}
      >
        <span className="signup-checkbox-text">
          <span>قوانین و مقررات</span> وبسایت را مطالعه کردم و قبول دارم.
        </span>
      </p>

      <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog
          centered
          className="justify-content-center rules-modal-dialog"
        >
          <MDBModalContent className=" rules-modal-div">
            <div className="reletive_profile">
              <p
                className="btn-close rules-modal-close"
                // color="none"
                onClick={() => {
                  setCentredModal(false);
                }}
              ></p>
            </div>
            <div className="rules-modal-text">
              <div
                dir="rtl"
                dangerouslySetInnerHTML={{ __html: pageContent }}
              />
            </div>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default RulesModal;
