import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import SquareCard from "../square-card/SquareCard";

const CardCarousel = (props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1063 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1062, min: 720 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet2: {
      breakpoint: { max: 719, min: 576 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 575.5, min: 0 },
      items: 1.775,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div>
      <Carousel
        responsive={responsive}
        rtl={true}
        removeArrowOnDeviceType={["mobile"]}
        deviceType={props.deviceType}
        swipeable={true}
        draggable={true}
        minimumTouchDrag={1}
        transitionDuration={20}
      >
        {props.data.map((item) => (
          <div key={item.bId}>
            <SquareCard
              title={item.title}
              desc1={item.catTitle}
              desc2={item.location}
              rate={item.rate}
              img={item.photoPath}
              url={item.url}
              withRate={props.withRate}
              flag={item.flg}
              datacome={props.datacome}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CardCarousel;
