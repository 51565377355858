import React, { useState, useEffect, useContext } from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
} from "mdb-react-ui-kit";
import axios from "axios";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
//
import { LangIdContext } from "../../../App";
// config
import configData from "../../../config/config.json";
//components
import MetaTagsEndUserPage from "../../../components/meta-tags-seo/MetaTagsEndUserPage";
import RateIcon from "../../../components/Dashbord/rate-icon/RateIcon";
import Sidbar from "../../../components/Dashbord/sidbar/Sidbar";
import Main from "../../../components/Dashbord/Main/Main";
import Header_mobile from "../../../components/Dashbord/Header_mobile/Header_mobile";
// style
import "../indexprofile.css";
// img
import pic from "../../../images/end user/fav.svg";
import Delete from "../../../images/register-business/delete.svg";

const Favorites = (props) => {
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [data, setdata] = useState([
    {
      bId: 1,
      title: "",
      catTitle: "",
      location: "",
      rate: "",
      photo: "",
      url: "",
    },
    {
      bId: 2,
      title: "",
      catTitle: "",
      location: "",
      rate: "",
      photo: "",
      url: "",
    },
  ]);
  const [dataCome, setDataCome] = useState(false);

  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Authorization: getCookie("token") ? "Bearer " + getCookie("token") : "",
  };

  const [centredModal, setCentredModal] = useState(false);
  const toggleShow = () => setCentredModal(!centredModal);
  const [idDelete, setIdDelete] = useState(null);
  const [showAlert, setshowAlert] = useState(false);

  const [btnDisable, setBtnDisable] = useState(false);
  const [Render, setRender] = useState(false);

  const setIDforDelete = (idDelete) => {
    setIdDelete(idDelete);
  };
  const deleteHandeler = () => {
    setBtnDisable(true);
    // setdata(data.filter((item) => item.id !== idDelete));
    // alertHandeler();

    axios
      // .post(domain + "/api/member/saved-business-delete", dataPost, {
      .delete(
        configData.API_DOMAIN + "/api/v1/Members/saved-business/" + idDelete,
        {
          headers: header,
        }
      )
      .then((response) => {
        setBtnDisable(false);
        if (response.data.isSuccess) {
          toggleShow();
          setdata(data.filter((item) => item.id !== idDelete));
          setRender(!Render);
          alertHandeler();
        }
      })
      .catch((error) => {
        // alert(error);
      });
  };

  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 3000);
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //api header
  const headerMeta = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Page: "saved-business",
  };

  useEffect(() => {
    if (getCookie("MemId")) {
      axios
        // .get(domain + "/api/member/meta-enduser", { headers: headerMeta })
        .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
          headers: headerMeta,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setMTagsCome(true);
            setMetaData(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
      setDataCome(false);
      axios
        // .get(domain + "/api/member/saved-business", {
        .get(configData.API_DOMAIN + "/api/v1/Members/saved-business", {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setdata(response.data.data);
            setDataCome(true);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      props.history.push({
        pathname: "/login",
      });
    }
  }, [Render]);

  return (
    <>
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} /> : ""}

      <Header_mobile />
      <div>
        <div className="margin-0 d-flex">
          <div className="border-left-dashbord d-none d-md-block">
            <Sidbar />
          </div>
          <div className="bg-main-dashbord">
            <Main lencomments={2}>
              <h5 className="header-main mb-3 mb-md-2">علاقه‌مندی‌ها</h5>
              <MDBRow className="favorites-row position-md-relative">
                {data && data.length > 0 ? (
                  data.map((item) => (
                    <MDBCol lg="6" className="mb35" key={item.bId}>
                      <MDBCard
                        className="color-101010 hov-trans px-auto mx-auto hov-shadow shadow-off card-border rec-card w352 "
                        style={{ maxWidth: "" }}
                      >
                        {/* <Link to={item.Url} className=""> */}
                        <MDBRow className="g-0  h-skeleton-inherti minh-fav-card">
                          <MDBCol className="col-5 col-sm-4 col-md-5">
                            {dataCome ? (
                              <Link to={item.url} className="">
                                <MDBCardImage
                                  className="img-r-card img-r-card-dash "
                                  src={item.photo}
                                  alt="...."
                                  fluid
                                />
                              </Link>
                            ) : (
                              <Skeleton
                                variant="rectangular"
                                className="img-r-card "
                                animation="wave"
                              />
                            )}
                          </MDBCol>
                          <MDBCol className="col-7 col-sm-8 col-md-7">
                            <MDBCardBody className="ms-2 me-2 mt-2 pt-1 p-0">
                              <MDBCardTitle className="fw-bold fs-15 mb-1 fs-xs-15 f14 color-101010">
                                {dataCome ? (
                                  <>
                                    <span className="width-80-fav">
                                      <Link to={item.url} className="">
                                        {" "}
                                        {item.title}
                                      </Link>
                                      <img
                                        className="card-tit-img"
                                        src={item.flag}
                                        alt={item.location}
                                      />
                                    </span>

                                    <span className="proofile_tooltip">
                                      <img
                                        src={Delete}
                                        alt="Delete"
                                        // className="img_delete1"
                                        className="img_delete-fav"
                                        onClick={() => {
                                          // console.log(item.BId)
                                          toggleShow();
                                          setIDforDelete(item.bId);
                                        }}
                                      />
                                      <span
                                        onClick={() => {
                                          toggleShow();
                                          setIDforDelete(item.bId);
                                        }}
                                        className="tooltiptext f12"
                                      >
                                        حذف
                                      </span>
                                    </span>
                                  </>
                                ) : (
                                  <div className="d-flex justify-content-between">
                                    <span className="width-80-fav">
                                      <Skeleton
                                        className="skeleton-text1-rec"
                                        variant="text"
                                        sx={{ fontSize: "1.4rem" }}
                                        width={100}
                                        animation="wave"
                                      />
                                    </span>
                                    <span className="proofile_tooltip ms-3">
                                      <Skeleton
                                        variant="circular"
                                        width={22}
                                        height={22}
                                        animation="wave"
                                      >
                                        <Avatar animation="wave" />
                                      </Skeleton>
                                    </span>
                                  </div>
                                )}
                              </MDBCardTitle>
                              {dataCome ? (
                                <>
                                  <MDBCardText className=" mb-sm-2 mb-0 fs-14 fs-xs-14 f12 color-101010">
                                    {item.catTitle}
                                  </MDBCardText>
                                  <MDBCardText className=" fs-13 fs-xs-13 color-101010ab f12 font-family-en">
                                    {item.location}
                                  </MDBCardText>
                                  <MDBCardText className="">
                                    <RateIcon
                                      cardrate={parseFloat(item.rate)}
                                      isFav={1}
                                      // isAdd={0}
                                      dir="ltr "
                                    />
                                  </MDBCardText>
                                </>
                              ) : (
                                <div className="ms-3">
                                  <Skeleton
                                    className="skeleton-text2-rec"
                                    variant="text"
                                    sx={{ fontSize: "0.6rem" }}
                                    animation="wave"
                                  />
                                  <Skeleton
                                    className="mt-0"
                                    variant="text"
                                    sx={{ fontSize: "0.6rem" }}
                                    animation="wave"
                                  />

                                  <Skeleton
                                    className="skeleton-gish-rec"
                                    width={95}
                                    height={30}
                                    animation="wave"
                                  />
                                </div>
                              )}
                            </MDBCardBody>
                          </MDBCol>
                        </MDBRow>
                        {/* </Link> */}
                      </MDBCard>
                    </MDBCol>
                  ))
                ) : (
                  <>
                    <div className="container_main_message_profile mt150">
                      <div>
                        <img
                          src={pic}
                          alt="pic"
                          className="img_message_profile"
                        />
                      </div>
                    </div>
                    <div className="container_main_message_profile mt25">
                      <p className="text_message_profile">
                        هنوز علاقه‌مندی‌ ثبت نشده.
                      </p>
                    </div>
                  </>
                )}
                {/* alert delete */}
                {showAlert ? (
                  <div className={"succsess_delete succsess_delete_resp1"}>
                    <Alert
                      severity="success"
                      icon={false}
                      className={"alert_succsess_delete"}
                    >
                      <p className="text_alert_delete fw-normal">
                        این کسب‌و‌کار با موفقیت از لیست علاقه‌مندی‌های شما حذف
                        شد.
                      </p>
                    </Alert>
                  </div>
                ) : (
                  ""
                )}
              </MDBRow>
              {/* modal delete */}
              <MDBModal
                tabIndex="-1"
                show={centredModal}
                setShow={setCentredModal}
                className={"bg_modal_fade"}
                id={idDelete}
              >
                <MDBModalDialog centered className="justify-content-center">
                  <MDBModalContent className="costum_modal">
                    <div className="reletive_profile">
                      <MDBBtn
                        className="btn-close close_modal"
                        color="none"
                        onClick={toggleShow}
                      ></MDBBtn>
                    </div>
                    <div>
                      <p className="text_modal">
                        آیا می‌خواهید این کسب‌و‌کار از لیست علاقه‌مندی‌هایتان
                        پاک شود؟
                      </p>
                    </div>
                    <div className="a_s_c">
                      <button
                        disabled={btnDisable}
                        onClick={() => {
                          // toggleShow();
                          deleteHandeler();
                          // alertHandeler();
                        }}
                        className="button_modal"
                      >
                        حذف شود
                      </button>
                    </div>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
            </Main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Favorites;
