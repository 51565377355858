import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import Pagination from "../../components/Pagination/Pagination";
import RecCard from "../result-card/RecCard";
import "./bottom-drawer.css";
import mapChangeIcon from "../../images/result/map.png";
import listChangeIcon from "../../images/result/list.png";
import search from "../../images/result/search.png"; /////////////

import next_icon from "../../images/next-blue.png";

const drawerBleeding = 44;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 35,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 17.5px)",
}));

function ResultBottomDrawer(props) {
  const { totalNumber, pageNumber, setPageNumber, windowWidth, windowHeigtht } =
    props;
  const { window } = props;
  const [open, setOpen] = React.useState(
    windowWidth == "00" ? false : windowWidth > 765 ? false : true
  );
  const [openWhole, setOpenWhole] = React.useState(false);

  const { mapIsShowing, setMapIsShowing, bizList } = props;

  const toggleDrawer = (newOpen) => () => {
    setOpen(!open);
    // setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const wholeHandler = () => {
    // console.log("clickkkkk");

    if (!openWhole) {
      // setOpen(true);
      setOpenWhole(true);
      // console.log("open");
    } else {
      // setOpen(false);
      setOpenWhole(false);
      // console.log("close");
    }
  };

  const openIconHandler = () => {
    // console.log("open open ;)");
    if (open) {
      setOpenWhole(true);
      setOpen(true);
    } else {
      setOpen(true);
    }
  };

  const closeIconHandler = () => {
    setOpen(false);
    setOpenWhole(false);
  };

  useEffect(() => {
    if (!open) {
      setOpenWhole(false);
    }
  }, [open]);

  useEffect(() => {
    // console.log("*******use");

    if (bizList && bizList.length < 1) {
      setOpenWhole(true);
      // console.log("*******setOpenWhole(true)");
    }
  }, []);

  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // console.log(window !== undefined ? windowWidth:"000");
  // console.log(open);
  // console.log(openWhole);

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  let heightt = windowHeigtht == "00" ? "80vh" : `${windowHeigtht}px`;

  // if (typeof window !== "undefined") {
  //   console.log("1111 undefined");
  //   console.log("height---------------------vh1");
  //   console.log(window.innerHeight);

  //   heightt = `${window.innerHeight}px`;
  //   console.log(heightt);
  // } else {
  //   console.log("22222 undefined");
  //   console.log("height---------------------vh2");
  //   // console.log(window.innerHeight);

  //   heightt = "100vh";
  // }7

  return (
    <Root className="d-md-none">
      <CssBaseline />
      <div className="first-open-icon-drawer">
        <img
          src={next_icon}
          className={
            !open && !openWhole
              ? "bottom-drawer-open-icon "
              : "bottom-drawer-open-icon d-none"
          }
          alt="bottom"
          onClick={openIconHandler}
        />
      </div>
      <Global
        styles={
          openWhole
            ? {
                ".MuiDrawer-root.result-map-drawer > .MuiPaper-root": {
                  height: `calc(${heightt} - 322px)`,
                  overflow: "visible",
                },
              }
            : {
                ".MuiDrawer-root.result-map-drawer > .MuiPaper-root": {
                  height: `calc(43% - 44px)`,
                  overflow: "visible",
                },
              }
        }
      />
      <div
        className="centerr result-map-change-div d-md-none "
        onClick={wholeHandler}
      >
        <button className="bottom-drawer-open result-map-change-btn ">
          <img src={openWhole ? mapChangeIcon : listChangeIcon} alt="..." />
        </button>
      </div>

      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open || openWhole}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={0}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        className=" result-map-drawer"
      >
        <StyledBox
          sx={{
            // backgroundColor: 'blue',
            boxShadow: "0 -3px 6px 0 rgba(36, 43, 46, 0.13)",
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 40,
            borderTopRightRadius: 40,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
          className="d-md-none"
        >
          {/* <Puller className="mt-2" /> */}
          <div className="d-flex justify-content-center mt-2">
            <img
              src={next_icon}
              className={
                !open && !openWhole
                  ? "bottom-drawer-close-icon d-none"
                  : "bottom-drawer-close-icon"
              }
              alt="bottom"
              onClick={closeIconHandler}
            />
            <img
              src={next_icon}
              className={
                openWhole || (!open && !openWhole)
                  ? "bottom-drawer-open-icon d-none"
                  : "bottom-drawer-open-icon"
              }
              alt="top"
              onClick={openIconHandler}
            />
          </div>
          <Typography
            sx={{ p: 2, color: "text.secondary", height: 56 }}
          ></Typography>
        </StyledBox>

        <StyledBox
          className="bottom-drawer-inside"
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          {bizList && bizList.length > 0 ? (
            <>
              {bizList.map((item) => (
                <>
                  <div key={item.bId} className="mb-3 mx-0">
                    <RecCard
                      title={item.title}
                      desc1={item.catTitle}
                      desc2={item.location}
                      rate={item.rate}
                      img={item.photoPath}
                      url={item.url}
                      flag={item.flagPath}
                      // Tags={item.Tags}
                      datacome={true}
                    />
                  </div>
                </>
              ))}
              <Pagination
                pageChanged={props.pageChanged}
                totalNumber={totalNumber}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                responsive={true}
              />
            </>
          ) : (
            <>
              <div className="result-notfound">
                <img src={search} alt="..." className="result-img" />
                <p>هیچ نتیجه‌ای یافت نشد!</p>
              </div>
              <div className="result-solution">
                <ul>
                  <li>مکان دیگری را امتحان کنید</li>
                  <li>املا را بررسی، یا کلیدواژه دیگری را جستجو کنید</li>
                  <li>
                    جستجوی کلی‌تری را امتحان کنید. به عنوان مثال «پیتزا» بجای
                    «پپرونی»
                  </li>
                </ul>
              </div>
              <div className="text-align-centerr d-block d-md-none moarefi-result-resp">
                <div className="moarefi d-flex justify-content-between  align-items-center  ">
                  <div className=" align-self-center text-end text-white alert-m-text">
                    کسب‌وکار فارسی‌زبان اطرافت می‌شناسی؟
                    <br />
                    به گیشنیز معرفی کن
                  </div>

                  <Link
                    className="align-self-center color-242424 alert-m-btn hov-shadow-btn shadow-off"
                    to="/introduce-business"
                  >
                    برای معرفی کلیک کن{" "}
                  </Link>
                </div>
              </div>
            </>
          )}
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

ResultBottomDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResultBottomDrawer;
