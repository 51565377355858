import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
// import "./Headermobile.css";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { Chip } from "@mui/material";

import { DomainContext, LangIdContext } from "../../App";
// config
import configData from "../../config/config.json";
// components
import AlertError from "../alertError/AlertError";
// images
import closeIcon from "../../images/header/close.svg";
import searchBlack from "../../images/header/search black.svg";
import close from "../../images/header/close.png";

const Modal = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [state, setState] = useState({
    top: false,
  });

  const [farsiError, setFarsiError] = useState(false);

  const [keyTime, setKeyTime] = useState("");
  const [keyTime2, setKeyTime2] = useState("");

  const [inputWhat, setInputWhat] = useState("");
  const [whereValue, setWhereValue] = useState("");

  const [chiValue, setChiValue] = useState(null);
  const [chiInputValue, setChiInputValue] = useState("");

  const [whatInput, setWhatInput] = useState("");
  const [whereInput, setWhereInput] = useState("");

  const [kojaValue, setKojaValue] = useState(null);
  const [kojaInputValue, setKojaInputValue] = useState("");

  // for loading
  const [nullList, setNullList] = useState([]);
  const [nullListWhere, setNullListWhere] = useState([]);

  //
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const find_desc = queryParams.get("find_desc");
  const find_loc = queryParams.get("find_loc");
  const cat_id = queryParams.get("cat_id");
  const find_cat = queryParams.get("find_cat");

  useEffect(() => {
    if (find_loc && find_loc !== "") {
      setKojaInputValue(find_loc.split("-").join(" "));
      setKojaValue(null);
    } else if (find_loc == "") {
      setKojaInputValue("");
      setKojaValue(null);
    }
    if (find_cat && find_cat !== "") {
      setChiInputValue(find_cat.split("-").join(" "));
    } else if (find_desc && find_desc !== "") {
      setChiInputValue(find_desc.split("-").join(" "));
    }
  }, [find_loc, find_cat, find_desc]);

  const chipDeleteHandleer = (key) => {
    let url = "";
    switch (key) {
      case 1:
        // find_cat
        setChiInputValue("");
        url =
          "/search?find_desc=" +
          find_desc +
          "&find_loc=" +
          find_loc +
          "&cat_id=&find_cat=";
        break;
      case 2:
        // find-desc
        setChiInputValue("");
        url =
          "/search?find_desc=&find_loc=" +
          find_loc +
          "&cat_id=" +
          cat_id +
          "&find_cat=" +
          find_cat;
        break;
      case 3:
        setKojaInputValue("");
        url =
          "/search?find_desc=" +
          find_desc +
          "&find_loc=&cat_id=" +
          cat_id +
          "&find_cat=" +
          find_cat;
        break;

      default:
        break;
    }
    history.push(url);
  };

  const [listComes, setListComes] = useState(false);
  const [listWhereComes, setListWhereComes] = useState(false);

  const [bizList, setBizList] = useState([]);
  const [catList, setCatList] = useState([]);
  const [locList, setLocList] = useState([]);

  // const [beHide , setBeHide]= useState("");
  const whatHandler = (event) => {
    setInputWhat(event.target.value);
    // setBeHide="d-none";
  };

  const toggleDrawer = (anchor, open) => (event) => {
    // if (
    //   event.type === "keydown" &&
    //   (event.key === "Tab" || event.key === "Shift")
    // ) {
    //   return;
    // }

    setState({ ...state, [anchor]: open });
  };
  // const options = props.data.map((option) => {
  const options = catList.map((option) => {
    if (option.bId || option.bId == "") {
      const op = option.title;
      return {
        op: "آخرین جستجوها",
        ...option,
      };
    } else {
      const op = option.title;
      return {
        op: "دسته‌بندی",
        ...option,
      };
    }
  });

  // for loading
  const optionss2 =
    nullList.length > 0
      ? nullList.map((option) => {
          if (option.bId || option.bId == "") {
            const op = option.title;
            return {
              op: "آخرین جستجوها",
              ...option,
            };
          } else {
            const op = option.title;
            return {
              op: "دسته‌بندی",
              ...option,
            };
          }
        })
      : [];

  var go =
    "/search?find_desc=" +
    chiInputValue.replace(/\s+/g, "-") +
    "&find_loc=" +
    kojaInputValue.replace(/\s+/g, "-") +
    "&cat_id=&find_cat=";

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };

  const apiWhat = () => {
    if (whatInput.length > 1) {
      setCatList([]);
      setBizList([]);
      setListComes(false);
      // برای عدم نمایش این لیست بین سرج ها کامنت بشه فعلا
      // if (props.data2) {
      //   if (JSON.parse(localStorage.getItem("LastVisitBusinesses"))) {
      //     var arrNull = [
      //       ...JSON.parse(localStorage.getItem("LastVisitBusinesses")),
      //       ...props.data2,
      //     ];
      //     setNullList(arrNull);
      //   } else {
      //     setNullList(props.data2);
      //   }
      // }

      axios
        // .post(
        //   domain + "/api/search/what",
        //   { txtWhat: whatInput },
        //   {
        //     headers: header,
        //   }
        // )
        .get(configData.API_DOMAIN + `/api/v1/Search/what/${whatInput}`, {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setNullList([]);
            const arr = [
              ...response.data.data.businesses,
              ...response.data.data.categories,
            ];
            setCatList(arr);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setCatList([]);
    }
  };

  useEffect(() => {
    if (whatInput.length > 1) {
      // setCatList([]);
      // setBizList([]);

      setListComes(false);

      clearTimeout(keyTime2);
      setKeyTime2(setTimeout(apiWhat, 1000));
    } else if (whatInput.length == 1 && props.data2) {
      if (JSON.parse(localStorage.getItem("LastVisitBusinesses"))) {
        var arrNull = [
          ...JSON.parse(localStorage.getItem("LastVisitBusinesses")),
          ...props.data2,
        ];
        setNullList(arrNull);
      } else {
        setNullList(props.data2);
      }
    }
  }, [whatInput]);

  // for disabling
  const [whereCome, setWhereCome] = useState(false);

  const apiWhere = () => {
    setWhereCome(false);

    setNullListWhere([]);
    if (whereInput.length > 1) {
      setListWhereComes(false);
      setLocList([]);
      setNullListWhere([{ id: "001", title: "" }]);

      axios
        // .post(
        //   domain + "/api/search/where",
        //   { txtWhere: whereInput },
        //   {
        //     headers: header,
        //   }
        // )
        .get(configData.API_DOMAIN + `/api/v1/Search/where/${whereInput}`, {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setNullListWhere([]);
            setLocList(response.data.data.locations);
            setWhereCome(true);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setLocList([]);
      setNullListWhere([]);
    }
  };

  useEffect(() => {
    if (whatInput.length <= 1 && props.data2) {
      if (JSON.parse(localStorage.getItem("LastVisitBusinesses"))) {
        var arrNull = [
          ...JSON.parse(localStorage.getItem("LastVisitBusinesses")),
          ...props.data2,
        ];
        setNullList(arrNull);
      } else {
        setNullList(props.data2);
      }
    }
    // }, [props.data2 , JSON.parse(localStorage.getItem("LastVisitBusinesses"))]);
  }, [props.data2]);

  useEffect(() => {
    if (whereInput.length > 1) {
      setListWhereComes(false);
      setNullListWhere([]);

      clearTimeout(keyTime);
      setKeyTime(setTimeout(apiWhere, 1000));
    } else if (whereInput.length == 1) {
      setLocList([]);
      setNullListWhere([{ id: "001", title: "" }]);
    }
  }, [whereInput]);

  const history = useHistory();

  const handleKeyDown = (event) => {
    var anchor = "top";
    if (event.key === "Enter") {
      if (
        !(
          (whereInput.length > 0 && !whereCome) ||
          chiInputValue == "" ||
          (kojaInputValue !== "" && kojaValue == null)
        )
      ) {
        toggleDrawer(anchor, false, event);
        history.push(go);
      }
    }
  };

  return (
    <>
      {["top"].map((anchor) => (
        <React.Fragment key={anchor}>
          <input
            type="text"
            className="input-search"
            onClick={toggleDrawer(anchor, true)}
            value={!state[anchor] ? "" : chiInputValue}
            onChange={(event, newValue) => {
              setChiInputValue(event.target.value);
            }}
            placeholder=""
          />
          {/* {!state[anchor] ? ( */}
          <div className="header-chip-div" onClick={toggleDrawer(anchor, true)}>
            {find_cat && find_cat !== "" ? (
              <Chip
                id="rb-chip"
                // dir="ltr"
                className="header-chip"
                label={find_cat.split("-").join(" ")}
                onDelete={() => {
                  // if (list3.length == 1) {
                  //   deleteHandler(item);
                  //   setShowBtns(false);
                  //   // setOther(false);
                  // } else {
                  //   deleteHandler(item);
                  // }
                  chipDeleteHandleer(1);
                }}
                deleteIcon={
                  <img src={close} alt="X" className="s2-chip-icon" />
                }
              />
            ) : (
              ""
            )}
            {find_desc && find_desc !== "" && !state[anchor] ? (
              <Chip
                id="rb-chip"
                className="header-chip"
                // dir="ltr"
                label={find_desc.split("-").join(" ")}
                onDelete={() => {
                  // if (list3.length == 1) {
                  //   deleteHandler(item);
                  //   setShowBtns(false);
                  //   // setOther(false);
                  // } else {
                  //   deleteHandler(item);
                  // }
                  chipDeleteHandleer(2);
                }}
                deleteIcon={
                  <img src={close} alt="X" className="s2-chip-icon" />
                }
              />
            ) : (
              ""
            )}
            {find_loc && find_loc !== "" ? (
              <Chip
                id="rb-chip"
                // dir="ltr"
                className="header-chip"
                label={find_loc.split("-").join(" ")}
                onDelete={() => {
                  // if (list3.length == 1) {
                  //   deleteHandler(item);
                  //   setShowBtns(false);
                  //   // setOther(false);
                  // } else {
                  //   deleteHandler(item);
                  // }
                  chipDeleteHandleer(3);
                }}
                deleteIcon={
                  <img src={close} alt="X" className="s2-chip-icon" />
                }
              />
            ) : (
              ""
            )}
          </div>
          {/* // ) : (
          //   ""
          // )} */}
          <a
            href={
              (whereInput.length > 0 && !whereCome) ||
              chiInputValue == "" ||
              (kojaInputValue !== "" && kojaValue == null)
                ? "javascript:void(0);"
                : go
            }
            className="search-a search-icon"
          >
            <img src={searchBlack} alt="search black" className="search-icon" />
          </a>
          <Drawer
            transitionDuration={0}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className="z1300"
          >
            <div className="Rectangle-where d-md-none">
              {/* <div className="req-header"></div> */}
              <MDBContainer fluid>
                <MDBRow className="text-end">
                  <IconButton
                    className="p-0 w-auto"
                    onClick={toggleDrawer(anchor, false)}
                  >
                    <img
                      className="header-close-img"
                      src={closeIcon}
                      alt="close icon"
                    />
                  </IconButton>
                </MDBRow>

                <MDBRow className="justify-content-center mt-3 mx-175">
                  <a
                    href={
                      (whereInput.length > 0 && !whereCome) ||
                      chiInputValue == "" ||
                      (kojaInputValue !== "" && kojaValue == null)
                        ? "javascript:void(0);"
                        : go
                    }
                    className="search-a search-icon2"
                  >
                    <img
                      src={searchBlack}
                      alt="search black"
                      className="search-icon2"
                    />
                  </a>
                  <div className="auto2 p-0 auto-where-paper auto-g auto-res-paper-space">
                    <Autocomplete
                      disabled={
                        ((find_cat && find_cat !== "") ||
                          (find_desc && find_desc !== "") ||
                          (find_loc && find_loc !== "")) &&
                        !state[anchor]
                      }
                      onKeyDown={handleKeyDown}
                      onClick={toggleDrawer(anchor, true)}
                      // open={true}
                      value={chiValue}
                      onChange={(event, newValue) => {
                        setChiValue(newValue);
                      }}
                      inputValue={!state[anchor] ? "" : chiInputValue}
                      onInputChange={(event, newInputValue) => {
                        var result;
                        if (newInputValue == "undefined") {
                          result = chiInputValue;
                        } else {
                          result = newInputValue;
                        }

                        setChiInputValue(result);
                        setWhatInput(result);
                      }}
                      filterOptions={(options, state) => options}
                      options={
                        // whatInput.length > 1 && nullList.length < 1
                        whatInput.length > 1
                          ? options.sort((a, b) => -b.op.localeCompare(a.op))
                          : optionss2.sort((a, b) => -b.op.localeCompare(a.op))
                      }
                      groupBy={(option) => option.op}
                      getOptionLabel={(option) => option.title}
                      getOptionDisabled={(option) =>
                        option.bId === "001" || option.cId === "001"
                      }
                      freeSolo={true}
                      openOnFocus={false}
                      popupIcon={""}
                      disableClearable
                      disablePortal
                      id="combo-box-demo0"
                      sx={{ width: 300 }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { ml: 2, flexShrink: 0 } }}
                          className="fs-4vw"
                          {...props}
                        >
                          {/* {(option.bId === "001" || option.cId === "001") && nullList.length > 0 ? ( */}
                          {options.length == 0 && nullList.length < 1 ? (
                            <div className="d-flex align-items-center px-3 my-1 w-100">
                              <Skeleton
                                variant="circular"
                                width={30}
                                height={26}
                                animation="wave"
                              >
                                <Avatar animation="wave" />
                              </Skeleton>
                              <Skeleton
                                className="mt-1 skeleton-currency"
                                animation="wave"
                              />
                            </div>
                          ) : option.bId || option.bId == "" ? (
                            <Link
                              className="width-100"
                              onClick={toggleDrawer(anchor, false)}
                              to={option.url}
                            >
                              <div className="position-relative d-flex div-auto-g1 color-101010">
                                <img
                                  src={option.photoPath}
                                  alt="search black"
                                  className=" autocomplete-search-lap-bizphoto"
                                />
                                <div className="last1-auto">{option.title}</div>
                                <div className="last2-auto">
                                  {option.location}
                                </div>
                              </div>
                            </Link>
                          ) : (
                            <div
                              // to={option.url.replace(/({loc})/g, whereInput)}
                              className="width-100"
                            >
                              <div className="position-relative d-flex div-auto-g2 color-101010">
                                <img
                                  src={searchBlack}
                                  alt="search black"
                                  className=" autocomplete-search-lap"
                                />
                                {option.title}
                              </div>
                            </div>
                          )}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <div className="position-relative d-flex">
                          <TextField
                            {...params}
                            placeholder={
                              state[anchor]
                                ? (find_cat && find_cat !== "") ||
                                  (find_desc && find_desc !== "")
                                  ? ""
                                  : "دنبال چی می‌گردی؟"
                                : (find_cat && find_cat !== "") ||
                                  (find_desc && find_desc !== "") ||
                                  (find_loc && find_loc !== "")
                                ? ""
                                : "دنبال چی می‌گردی؟"
                            }
                            className=""
                          />
                          {/* <div
                            className="header-chip-div mx-3 mt-0"
                            onClick={toggleDrawer(anchor, true)}
                          > */}
                          {find_cat && find_cat !== "" && !state[anchor] ? (
                            <div
                              className="header-chip-div mx-3 mt-0"
                              onClick={toggleDrawer(anchor, true)}
                            >
                              <Chip
                                id="rb-chip"
                                // dir="ltr"
                                className="header-chip"
                                label={find_cat.split("-").join(" ")}
                                onDelete={() => {
                                  // if (list3.length == 1) {
                                  //   deleteHandler(item);
                                  //   setShowBtns(false);
                                  //   // setOther(false);
                                  // } else {
                                  //   deleteHandler(item);
                                  // }
                                  chipDeleteHandleer(1);
                                }}
                                deleteIcon={
                                  <img
                                    src={close}
                                    alt="X"
                                    className="s2-chip-icon"
                                  />
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {find_desc && find_desc !== "" && !state[anchor] ? (
                            <div
                              className="header-chip-div mx-3 mt-0"
                              onClick={toggleDrawer(anchor, true)}
                            >
                              <Chip
                                id="rb-chip"
                                className="header-chip"
                                // dir="ltr"
                                label={find_desc.split("-").join(" ")}
                                onDelete={() => {
                                  // if (list3.length == 1) {
                                  //   deleteHandler(item);
                                  //   setShowBtns(false);
                                  //   // setOther(false);
                                  // } else {
                                  //   deleteHandler(item);
                                  // }
                                  chipDeleteHandleer(2);
                                }}
                                deleteIcon={
                                  <img
                                    src={close}
                                    alt="X"
                                    className="s2-chip-icon"
                                  />
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {/* </div> */}
                        </div>
                      )}
                    />
                  </div>
                  <a
                    href={
                      (whereInput.length > 0 && !whereCome) ||
                      chiInputValue == "" ||
                      (kojaInputValue !== "" && kojaValue == null)
                        ? "javascript:void(0);"
                        : go
                    }
                    className="search-a search-icon2"
                  >
                    <img
                      src={searchBlack}
                      alt="search black"
                      className="search-icon2"
                    />
                  </a>
                  <div className="auto2 p-0 auto-where-paper list-left">
                    <Autocomplete
                      // disabled={find_loc && find_loc !== ""}
                      // open={true}
                      onKeyDown={handleKeyDown}
                      value={kojaValue}
                      onChange={(event, newValue) => {
                        setKojaValue(newValue);

                        // to have city, province and country for search api of search page
                        localStorage.setItem(
                          "SearchLocation",
                          JSON.stringify(newValue)
                        );

                        if (
                          chiInputValue != "" ||
                          chiValue ||
                          find_desc ||
                          find_cat
                        ) {
                          let koja = newValue.title;
                          history.push(
                            "/search?find_desc=" +
                              chiInputValue.replace(/\s+/g, "-") +
                              "&find_loc=" +
                              koja.replace(/\s+/g, "-") +
                              "&cat_id=&find_cat="
                          );
                          setState({ ...state, [anchor]: false });
                        }
                      }}
                      inputValue={kojaInputValue}
                      onInputChange={(event, newInputValue) => {
                        var result;
                        if (newInputValue == "undefined") {
                          result = kojaInputValue.replace(/[^A-Za-z\s,]/gi, "");
                        } else {
                          // for fars i error
                          var charCode = newInputValue.charCodeAt(
                            newInputValue.length - 1
                          );
                          if (
                            !(
                              (charCode >= 97 && charCode <= 122) ||
                              (charCode >= 65 && charCode <= 90) ||
                              charCode == 32
                            ) &&
                            newInputValue !== ""
                          ) {
                            setFarsiError(true);
                            setTimeout(() => {
                              setFarsiError(false);
                            }, 4000);
                          } else {
                            setFarsiError(false);
                          }
                          // for farsi error
                          result = newInputValue.replace(/[^A-Za-z\s,]/gi, "");
                        }
                        setKojaInputValue(result);
                        setWhereInput(result);
                      }}
                      onBlur={() => setFarsiError(false)} // for farsi error
                      filterOptions={(options, state) => options}
                      freeSolo={true}
                      openOnFocus={false}
                      options={
                        whereInput.length > 1 && nullListWhere.length < 1
                          ? locList
                          : nullListWhere
                      }
                      getOptionLabel={(option) => option.title}
                      getOptionDisabled={(option) =>
                        option.id === "001" || option.id === ""
                      }
                      popupIcon={""}
                      disableClearable
                      disablePortal
                      id="combo-box-demo0"
                      sx={{ width: 300 }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { ml: 2, flexShrink: 0 } }}
                          className="fs-4vw"
                          {...props}
                        >
                          {nullListWhere.length > 0 && option.id === "001" ? (
                            <div className="d-flex align-items-center px-2 w-100">
                              <Skeleton
                                variant="circular"
                                width={30}
                                height={26}
                                animation="wave"
                              >
                                <Avatar animation="wave" />
                              </Skeleton>
                              <Skeleton
                                className="mt-1 skeleton-currency"
                                animation="wave"
                              />
                            </div>
                          ) : (
                            <>
                              {option.title}
                              {/* <img
                                src={searchBlack}
                                alt="search black"
                                className=" ms-2  autocomplete-search"
                              /> */}
                            </>
                          )}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <div className="position-relative d-flex">
                          <TextField
                            {...params}
                            // className={farsiError ? "farsi-error-input3" : ""}
                            placeholder={
                              find_loc && find_loc !== ""
                                ? ""
                                : //  farsiError? "به انگلیسی تایپ کنید":
                                  "کجا؟ (نام شهر به زبان انگلیسی)"
                            }
                          />
                          {/* <div
                            className="header-chip-div mx-3 mt-0"
                            onClick={toggleDrawer(anchor, true)}
                          > */}
                          {find_loc && find_loc !== "" && !state[anchor] ? (
                            <div
                              className="header-chip-div mx-3 mt-0"
                              onClick={toggleDrawer(anchor, true)}
                            >
                              <Chip
                                id="rb-chip"
                                // dir="ltr"
                                className="header-chip"
                                label={find_loc.split("-").join(" ")}
                                onDelete={() => {
                                  // if (list3.length == 1) {
                                  //   deleteHandler(item);
                                  //   setShowBtns(false);
                                  //   // setOther(false);
                                  // } else {
                                  //   deleteHandler(item);
                                  // }
                                  chipDeleteHandleer(3);
                                }}
                                deleteIcon={
                                  <img
                                    src={close}
                                    alt="X"
                                    className="s2-chip-icon"
                                  />
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {/* </div> */}
                        </div>
                      )}
                    />
                  </div>

                  {/* <div className="auto2 p-0">
                    <Autocomplete
                      popupIcon={""}
                      // open={true}
                      disableClearable
                      disablePortal
                      openOnFocus={false}
                      id="combo-box-demo0"
                      options={props.data.CategoriesForSearch}
                      sx={{ width: 300 }}
                      getOptionLabel={(option) => option.title}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { ml: 2, flexShrink: 0 } }}
                          className="fs-4vw"
                          {...props}
                        >
                          <img
                            src={searchBlack}
                            alt="search black"
                            className=" ms-2  autocomplete-search"
                          />
                        //   <img
                        //   loading="lazy"
                        //   width="20"
                        //   src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        //   srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        //   alt=""
                        // />
                          {option.title}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="دسته‌بندی"
                          className=""
                        />
                      )} 
                    />
                  </div>*/}
                </MDBRow>
              </MDBContainer>
            </div>
            {/* alert error */}
            <AlertError
              showAlert={farsiError}
              text={`زبان کیبورد را به انگلیسی تغییر دهید.`}
            />
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
};

export default Modal;
