import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import "./dropdown-filter.css";

import arrow from "../../images/next-left.png";

const DropdownFilter = (props) => {
  const [showmenu, setshowmenu] = useState(false);
  const [showmenub, setshowmenub] = useState(false);
  const [showmenuc, setshowmenuc] = useState(false);
  // a
  const handleShow = () => {
    setshowmenu((prev) => !prev);
    setshowmenuc(false);
    setshowmenub(false);
  };
  const handleShowaway = () => {
    setshowmenu(false);
  };
  // b
  const handleShowb = () => {
    setshowmenub((prev) => !prev);
    setshowmenu(false);
    setshowmenuc(false);
  };
  const handleShowbaway = () => {
    setshowmenub(false);
  };
  // c
  const handleShowc = () => {
    setshowmenuc((prev) => !prev);
    setshowmenu(false);
    setshowmenub(false);
  };
  const handleShowcaway = () => {
    setshowmenuc(false);
  };

  //
  const {
    paymentsFilter,
    setPaymentsFilter,
    amenitiesFilter,
    setAmenitiesFilter,
  } = props;

  const [category, setCategory] = useState([]);
  const [option, setOption] = useState([]);
  const [peyment, setpeyment] = useState([]);

  useEffect(() => {
    if (props.dataComeCategory) {
      setCategory(props.category);
    }
    if (props.dataComeOption) {
      setOption(props.option);
    }
    if (props.dataComePeyment) {
      setpeyment(props.peyment);
    }
  }, [props.dataComeCategory, props.dataComeOption, props.dataComePeyment]);

  const handlePaymentChange = (event, id) => {
    if (event.target.checked) {
      // console.log("piid" + id);
      if (!paymentsFilter.includes(id)) {
        setPaymentsFilter((paymentsFilter) => [...paymentsFilter, id]);
      }
    } else {
      setPaymentsFilter(paymentsFilter.filter((item) => item !== id));
    }
    handleShowcaway();
    // console.log("paymentsFilter111111111111111111");
    // console.log(paymentsFilter);
  };

  const handleAmenitiesChange = (event, id) => {
    // console.log("come innnnnnnn");

    if (event.target.checked) {
      if (!paymentsFilter.includes(id)) {
        setAmenitiesFilter((amenitiesFilter) => [...amenitiesFilter, id]);
      }
    } else {
      setAmenitiesFilter(amenitiesFilter.filter((item) => item !== id));
    }
    // console.log("amenitiesFilter" + amenitiesFilter);
    handleShowaway();
  };

  return (
    <div className="d-flex">
      <ClickAwayListener onClickAway={handleShowbaway}>
        <Box className="position-relative">
          <>
            <div
            //   className={showmenu ? "filter-dropdown" : "filter-dropdown"}
            //   onClick={handleShowAway2b}
            >
              <div onClick={handleShowb} className="filter-dropdown">
                دسته‌بندی
                <img
                  src={arrow}
                  alt="icon"
                  className={
                    showmenub
                      ? "nextFilter-biz nextFilter-biz-rotate"
                      : "nextFilter-biz nextFilter-biz-rotateback"
                  }
                />
              </div>
              {showmenub && (
                <List
                  //   onMouseOver={() => setshowmenub(true)}
                  //   onMouseOut={() => setshowmenub(false)}
                  //   onClick={handleShowAway2b}
                  className="box_show_filter-dropdown"
                  component="nav"
                  aria-label="mailbox folders"
                >
                  {category.length ? (
                    category.map((item) => (
                      <div
                        key={item.cId}
                        className="result-filter-item result-filter-item-res px-3 mt-0 my-0"
                      >
                        <Link
                          to={item.url}
                          className="color-242b2e"
                          onClick={() => {
                            if (props.setCloseModal) {
                              props.setCloseModal(false);
                            }
                            handleShowbaway();
                          }}
                        >
                          <span>{item.title}</span>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <p></p>
                  )}
                </List>
              )}
            </div>
          </>
        </Box>
      </ClickAwayListener>
      <ClickAwayListener onClickAway={handleShowaway}>
        <Box className="position-relative">
          <>
            <div
            //   className={showmenu ? "filter-dropdown" : "filter-dropdown"}
            //   onClick={handleShowAway2}
            >
              <div onClick={handleShow} className="filter-dropdown">
                امکانات
                <img
                  src={arrow}
                  alt="icon"
                  className={
                    showmenu
                      ? "nextFilter-biz nextFilter-biz-rotate"
                      : "nextFilter-biz nextFilter-biz-rotateback"
                  }
                />
              </div>
              {showmenu && (
                <List
                  //   onMouseOver={() => setshowmenu(true)}
                  //   onMouseOut={() => setshowmenu(false)}
                  //   onClick={handleShowAway2}
                  className="box_show_filter-dropdown"
                  component="nav"
                  aria-label="mailbox folders"
                >
                  {option.length ? (
                    option.map((item) => (
                      <div
                        key={item.cotId}
                        className="result-filter-item px-3  my-0"
                        // onClick={handleShowAway2}
                      >
                        <label className="container-s2 m-0 me-0 mb-0 d-inline-flex">
                          <input
                            type="checkbox"
                            onChange={(event) => {
                              handleAmenitiesChange(event, item.cotId);
                            }}
                            defaultChecked={
                              amenitiesFilter.includes(item.cotId)
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark-s2 back-white"></span>
                          <span>{item.title}</span>
                        </label>
                      </div>
                    ))
                  ) : (
                    <p></p>
                  )}
                </List>
              )}
            </div>
          </>
        </Box>
      </ClickAwayListener>
      {/* روش پرداخت فعلا حذف شود */}
      {/* <ClickAwayListener onClickAway={handleShowcaway}>
        <Box className="position-relative">
          <>
            <div
            //   className={showmenuc ? "filter-dropdown" : "filter-dropdown"}
            //   onClick={handleShowAway2c}
            >
              <div onClick={handleShowc} className="filter-dropdown">
                روش پرداخت
                <img
                  src={arrow}
                  alt="icon"
                  className={
                    showmenuc
                      ? "nextFilter-biz nextFilter-biz-rotate"
                      : "nextFilter-biz nextFilter-biz-rotateback"
                  }
                />
              </div>
              {showmenuc && (
                <List
                  //   onMouseOver={() => setshowmenuc(true)}
                  //   onMouseOut={() => setshowmenuc(false)}
                  //   onClick={handleShowAway2c}
                  className="box_show_filter-dropdown box_show_filter-dropdown-payment"
                  component="nav"
                  aria-label="mailbox folders"
                >
                  {peyment.length ? (
                    peyment.map((item) => (
                      <div
                        key={item.ptId}
                        className="result-filter-item px-3  my-0"
                      >
                        <label className="container-s2 m-0 me-0 mb-0 d-inline-flex">
                          <input
                            type="checkbox"
                            onChange={(event) => {
                              handlePaymentChange(event, item.ptId);
                            }}
                            defaultChecked={
                              paymentsFilter.includes(item.ptId) ? true : false
                            }
                          />
                          <span className="checkmark-s2 back-white"></span>
                          <span>{item.title}</span>
                        </label>
                      </div>
                    ))
                  ) : (
                    <p></p>
                  )}
                </List>
              )}
            </div>
          </>
        </Box>
      </ClickAwayListener> */}
    </div>
  );
};

export default DropdownFilter;
