import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { MDBBtn, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
// config
import configData from "../../config/config.json";
//
import { DomainContext, LangIdContext } from "../../App";
import { validate } from "./validate";
// components
import MetaTagsEndUserPage from "../meta-tags-seo/MetaTagsEndUserPage";
import Footer from "../footer/Footer";
// imgs
import logo from "../../images/home-body/logo gishniz.png";
//
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ChangePass = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);
  //submit btn disable
  const [btnDisable, setBtnDisable] = useState(false);
  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  const [ApiDataCome, setApiDataCome] = useState({
    // header: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});

  const [inputData, setInputData] = useState({
    password: "",
    repeatPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const [render, setRender] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    setErrors(validate(inputData, "change-pass"));
  }, [inputData, touched]);

  const inputChangeHandler = (event) => {
    setInputData({ ...inputData, [event.target.name]: event.target.value });
  };

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };
  //api header
  const headerMeta = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Page: "change-password",
  };

  useEffect(() => {
    if (queryParams.get("email") && queryParams.get("id")) {
      setRender(true);

      axios
        .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
          headers: headerMeta,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setMTagsCome(true);
            setMetaData(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
      // 2 footer
      axios
        .get(configData.API_DOMAIN + "/api/v1/Footers", {
          headers: header,
        })
        .then((response) => {
          setFooterData(response.data.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      history.push("/forget-password");
    }
  }, []);

  const [apiError, setApiError] = useState([]);

  const submitHandler = (event) => {
    event.preventDefault();
    setApiError([]);

    if (!Object.keys(errors).length) {
      setBtnDisable(true);

      // success
      const user_email = queryParams.get("email");
      const user_id = queryParams.get("id");
      const user_id2 = user_id.split(" ").join("+");

      var dataPost = {
        id: user_id2,
        email: user_email,
        newPassword: inputData.password,
      };

      axios
        .post(
          configData.API_DOMAIN + "/api/v1/Members/change-password",
          dataPost,
          { headers: header }
        )
        .then((response) => {
          setBtnDisable(false);

          if (response.data.isSuccess) {
            history.push("/login");
          } else {
            setApiError(response.data.message);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setTouched({
        password: true,
        repeatPassword: true,
      });
    }
  };

  // for password btn icon
  const [showPassword2, setShowPassword2] = React.useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  // for password btn icon
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {render ? (
        <>
          {MTagsCome ? <MetaTagsEndUserPage data={MetaData} /> : ""}

          <div className="centerr">
            <div className="centerr d-none d-md-block">
              <Link to="/" className=" logo-login-a ">
                <img
                  src={logo}
                  alt="logo gishniz"
                  className="logo-login-lap "
                />
              </Link>
            </div>

            <div className="login-container centerr login-container-forget">
              <MDBContainer className="">
                <MDBRow className="">
                  <div className="col-12 text-center">
                    <div className="centerr">
                      <Link to="/" className="logo-login-a d-block d-md-none">
                        <img
                          src={logo}
                          alt="logo gishniz"
                          className="logo-login logo-forget-pass"
                        />
                      </Link>
                    </div>

                    <p className=" login-matn1 fs-xss-21 color-515151 fw-bold mt-3 mt-md-0 mb-2">
                      تغییر رمز عبور
                    </p>
                    <p className="fs-xss-12 color-8d8c8c mb-4 pb-2 pb-md-0 login-matn2">
                      رمز عبور جدید برای کسب‌و‌کارخود وارد کنید.
                    </p>
                  </div>
                  <div className="col-12 mt-1 padding-xss-32">
                    <div className="">
                      <form>
                        <div className="position-relative">
                          <input
                            dir="ltr"
                            maxLength="25"
                            type={showPassword2 ? "text" : "password"}
                            name="password"
                            value={inputData.password}
                            onChange={inputChangeHandler}
                            // onFocus={inputFocusHanlder}
                            className={
                              errors.password && touched.password
                                ? "input-login input-login-pass font-family-en mb-md-1 border-error"
                                : "input-login input-login-pass font-family-en mb-md-1"
                            }
                            placeholder="رمز عبور جدید"
                          />
                          <IconButton
                            className="show-pass-btn-change-pass"
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword2}
                            edge="start"
                          >
                            {showPassword2 ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </div>
                        {errors.password && touched.password && (
                          <span className="input-error">{errors.password}</span>
                        )}
                        <div className="position-relative mt-2">
                          <input
                            dir="ltr"
                            maxLength="25"
                            type={showPassword ? "text" : "password"}
                            name="repeatPassword"
                            value={inputData.repeatPassword}
                            onChange={inputChangeHandler}
                            // onFocus={inputFocusHanlder}
                            className={
                              errors.repeatPassword && touched.repeatPassword
                                ? "input-login input-login-pass font-family-en mb-md-1 border-error mt-0"
                                : "input-login input-login-pass font-family-en mb-md-1 mt-0"
                            }
                            placeholder="تکرار رمز عبور جدید"
                          />
                          <IconButton
                            className="show-pass-btn-change-pass"
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="start"
                          >
                            {showPassword ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </div>
                        {errors.repeatPassword && touched.repeatPassword && (
                          <span className="input-error">
                            {errors.repeatPassword}
                          </span>
                        )}
                        {apiError.map((item) => (
                          <span className="input-error">{item}</span>
                        ))}

                        <MDBBtn
                          disabled={btnDisable}
                          onClick={submitHandler}
                          type="submit"
                          className="shadow-off hov-shadow-btn submit-login-btn text-center mb-forget-pass"
                        >
                          تغییر رمز عبور
                        </MDBBtn>
                      </form>
                    </div>
                  </div>
                </MDBRow>
              </MDBContainer>
            </div>
          </div>
          <Footer data={footerData} dataCome={ApiDataCome.footer} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ChangePass;
