import React from "react";
import { useState, createContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import Layout from "./layout/Layout";
import axios from "axios";
import StandardErrorBoundary from "./error/StandardErrorBoundary";
// config
import configData from "./config/config.json";

const App = () => {
  const [domain, setDomain] = useState("https://api-v1.gishniz.link");
  // const [domain, setDomain] = useState("https://api.gishniz.com");

  const [cookieState, setCookieState] = useState(getCookie("MemId"));
  const value = { cookieState, setCookieState };

  // const [UserIp, setUserIp] = useState({});

  // useEffect(() => {
  //   axios
  //     .get("https://api.ip2location.io/?key=B66F8C4D6ECD714952C17B678B9AF275", {
  //       // headers: header,
  //     })
  //     .then((response) => {
  //       console.log("response------------------------9--------------");
  //       console.log(response);
  //       console.log(response.data);
  //     })
  //     .catch((error) => {
  //       // alert(error);
  //     });
  // }, []);

  const [langIdState, setLangIdState] = useState("1");
  const langIdValue = { langIdState, setLangIdState };

  useEffect(() => {
    if (!getCookie("_gapp_Locale") && !getCookie("_gapp_LId")) {
      axios
        .get(configData.API_DOMAIN + "/api/v1/Languages", {
          headers: { ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9" },
        })
        .then((response) => {
          let lanList = response.data.data;

          for (let index = 0; index < lanList.length; index++) {
            const element = lanList[index];
            if (element.locale == "") {
              if (window.location.host == "gishniz.link") {
                // if (window.location.host == "gishniz.app") {
                // if (window.location.host == "localhost:3000") {
                setCookie("_gapp_Locale", element.locale, 365);
                setCookie("_gapp_LId", element.lId, 365);
                setLangIdState(element.lId);
              }
            } else if (
              window.location.host ==
              element.locale + ".gishniz.link"
              // element.locale + ".gishniz.app"
              // element.locale + ".localhost:3000"
            ) {
              setCookie("_gapp_Locale", element.locale, 365);
              setCookie("_gapp_LId", element.lId, 365);
              setLangIdState(element.lId);
            }
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setLangIdState(getCookie("_gapp_LId"));

      // axios
      //   .get(domain + "/api/langs-list", {
      //     headers: { ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9" },
      //   })
      //   .then((response) => {
      //     console.log("loc 9999");
      //     console.log(response.data);
      //     let lanList = response.data;

      //     for (let index = 0; index < lanList.length; index++) {
      //       const element = lanList[index];
      //       if (element.Locale == getCookie("_gapp_Locale")) {
      //         setLangIdState(element.LId);
      //         console.log("element.LId//////////////////-11");
      //         console.log(element.LId);
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     // alert(error);
      //   });
    }
  }, []);

  //setCookie
  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  //getCookie
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <StandardErrorBoundary>
      {/* // <UserIpContext.Provider value={UserIp}> */}
      <LangIdContext.Provider value={langIdValue}>
        <CookieContext.Provider value={value}>
          <DomainContext.Provider value={domain}>
            {/* <Helmet> */}
            <Layout />
            {/* </Helmet> */}
          </DomainContext.Provider>
        </CookieContext.Provider>
      </LangIdContext.Provider>
      {/* // </UserIpContext.Provider> */}
    </StandardErrorBoundary>
  );
};

// export const UserIpContext = createContext();

export const DomainContext = createContext();

export const CookieContext = React.createContext({
  cookieState: "",
  setCookieState: () => {},
});

export const LangIdContext = React.createContext({
  langIdState: "",
  setLangIdState: () => {},
});

export default App;
