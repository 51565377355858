import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
//
import { DomainContext, LangIdContext } from "../../App";
// components
import Header2 from "../../components/header/Header2";
import Headermobile from "../../components/header-mobile/Headermobile";
import Footer from "../../components/footer/Footer";
// style
import "./registery.css";
// img
import welImg from "../../images/registery/open mail.svg";
import { MDBBtn } from "mdb-react-ui-kit";
// config
import configData from "../../config/config.json";

const WelcomePage = () => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [ApiDataCome, setApiDataCome] = useState({
    headerbiz: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    HeaderLogo: {
      Title: "",
      Link: "",
    },
  });

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };
  useEffect(() => {
    // 1 header-biz
    axios
      .get(domain + "/api/header-other", { headers: header })
      .then((response) => {
        if ((response.data.msg = "done")) {
          setheaderData(response.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            headerbiz: true,
          }));
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 2 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  return (
    <>
      <div className="d-none d-md-block">
        <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
      </div>
      <div className="d-block d-md-none">
        <div className="fixed-header">
          <Headermobile data={headerData} />
        </div>
        <div className="box-under-header-res "></div>
      </div>

      <div className="registery-bg">
        <object
          data={welImg}
          alt={"welcome image"}
          className="registery-welcome-img"
        ></object>
        {/* <img
          src={welImg}
          alt="welcome image"
          className="registery-welcome-img"
        /> */}
        <div className="w-100">
          <div className="registery-cir-bg">
            <p className="registery-welcome-text">به گیشنیز خوش آمدی!</p>
          </div>
          <div className="registery-rec-bg">
            <div className="text-center d-md-flex justify-content-center pt-md-0 pt-5">
              <MDBBtn className="registery-welcome-btn1" href="/">
                صفحه اصلی
              </MDBBtn>
              <MDBBtn
                className="registery-welcome-btn2"
                href="/members/profile"
              >
                پروفایل
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <Footer data={footerData} dataCome={ApiDataCome.footer} />
    </>
  );
};

export default WelcomePage;
