import React, { useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "./Pagination.css";
import { parse } from "stylis";

const BasicPagination = (props) => {
  const { totalNumber, pageNumber, setPageNumber, pageChanged } = props;

  const [page, setPage] = React.useState(pageNumber);
  const [count, setCount] = React.useState(1);

  useEffect(() => {
    setPage(pageNumber);
  }, [pageChanged]);

  const handleChange = (event, value) => {
    setPage(value);
    setPageNumber(value);
  };

  useEffect(() => {
    if (parseInt(totalNumber / 10) == totalNumber / 10) {
      setCount(parseInt(totalNumber / 10));
    } else {
      var x = parseInt(totalNumber / 10) + 1;
      setCount(x);
    }
  });
  
  return (
    <div dir="ltr" id="pagination1" className="paginatio_set">
      <Stack spacing={2}>
        <Pagination
          count={count}
          page={page}
          onChange={handleChange}
          color="secondary"
          siblingCount={props.responsive ? 0 : 1}
        />
        {/* <span>page : {page}</span> */}
      </Stack>
    </div>
  );
};

export default BasicPagination;
