export const validate = (data, type) => {
  const errors = {};

  if (type === "login") {
    // .toString().trim()
    if (!data.username.toString().trim()) {
      errors.username = "لطفا این قسمت را خالی نگذارید.";
    } else {
      delete errors.username;
    }

    if (!data.password.toString().trim()) {
      errors.password = "لطفا این قسمت را خالی نگذارید.";
    } else if (data.password.toString().trim().length < 8) {
      errors.password = "رمز عبور باید حداقل ۸ کاراکتر یا بیشتر باشد.";
    } else {
      delete errors.password;
    }
  }
  // sign up
  if (type === "signup") {
    // if (!data.fname.toString().trim()) {
    //   errors.fname = "لطفا این قسمت را خالی نگذارید.";
    // } else {
    //   delete errors.fname;
    // }

    // if (!data.lname.toString().trim()) {
    //   errors.lname = "لطفا این قسمت را خالی نگذارید.";
    // } else {
    //   delete errors.lname;
    // }
    if (!data.email) {
      errors.email = "لطفا این قسمت را خالی نگذارید.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "آدرس ایمیل نامعتبر است.";
    } else {
      delete errors.email;
    }
    if (!data.username.toString().trim()) {
      errors.username = "لطفا این قسمت را خالی نگذارید.";
    } else {
      delete errors.username;
    }
    if (!data.password) {
      errors.password = "لطفا این قسمت را خالی نگذارید.";
    } else if (data.password.toString().trim().length < 8) {
      errors.password = "رمز عبور باید حداقل ۸ کاراکتر یا بیشتر باشد.";
    } else {
      delete errors.password;
    }
    if (!data.isAccepted) {
      errors.isAccepted = "لطفا قوانین را تایید کنید.";
    } else {
      delete errors.isAccepted;
    }
  }
  // forget pass
  if (type === "forget-pass") {
    // .toString().trim()
    if (!data.email) {
      errors.email = "لطفا این قسمت را خالی نگذارید.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "آدرس ایمیل نامعتبر است.";
    } else {
      delete errors.email;
    }
  }
  // change pass
  if (type === "change-pass") {
    if (!data.password.toString().trim()) {
      errors.password = "لطفا این قسمت را خالی نگذارید.";
    } else if (data.password.toString().trim().length < 8) {
      errors.password = "رمز عبور باید حداقل ۸ کاراکتر یا بیشتر باشد.";
    } else {
      delete errors.password;
    }

    if (!data.repeatPassword.toString().trim()) {
      errors.repeatPassword = "لطفا این قسمت را خالی نگذارید.";
    } else if (data.password !== data.repeatPassword) {
      errors.repeatPassword = "تکرار رمز عبور همخوانی ندارد.";
    } else if (data.repeatPassword.toString().trim().length < 8) {
      errors.repeatPassword = "رمز عبور باید حداقل ۸ کاراکتر یا بیشتر باشد.";
    } else {
      delete errors.repeatPassword;
    }
  }

  //
  if (type === "introduce-business") {
    if (!data.engName.toString().trim()) {
      errors.engName = "لطفا این قسمت را خالی نگذارید.";
    } else {
      delete errors.engName;
    }
    if (!data.countryy.toString().trim()) {
      errors.countryy = "لطفا این قسمت را خالی نگذارید.";
    } else {
      delete errors.countryy;
    }
    if (!data.provincee.toString().trim()) {
      errors.provincee = "لطفا این قسمت را خالی نگذارید.";
    } else {
      delete errors.provincee;
    }
    if (!data.cityy.toString().trim()) {
      errors.cityy = "لطفا این قسمت را خالی نگذارید.";
    } else {
      delete errors.cityy;
    }
  }

  if (type === "register-business-s1") {
    if (!data.engName.toString().trim()) {
      errors.engName = "لطفا این قسمت را خالی نگذارید.";
    } else {
      delete errors.engName;
    }
    // if (!rbdata2.toString().trim()) {
    //   errors.countryy = "لطفا این قسمت را خالی نگذارید.";
    // } else {
    //   delete errors.countryy;
    // }
    // if (!data.provincee.toString().trim()) {
    //   errors.provincee = "لطفا این قسمت را خالی نگذارید.";
    // } else {
    //   delete errors.provincee;
    // }
    // if (!data.cityy.toString().trim()) {
    //   errors.cityy = "لطفا این قسمت را خالی نگذارید.";
    // } else {
    //   delete errors.cityy;
    // }
  }
  if (type === "register-business-s4") {
    if (!data.countryy.toString().trim()) {
      errors.countryy = "لطفا این قسمت را خالی نگذارید.";
    } else {
      delete errors.countryy;
    }
  }
  // contact us
  if (type === "contact") {
    if (!data.name.toString().trim()) {
      errors.name = "لطفا این قسمت را خالی نگذارید.";
    } else {
      delete errors.name;
    }
    if (!data.email) {
      errors.email = "لطفا این قسمت را خالی نگذارید.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "آدرس ایمیل نامعتبر است.";
    } else {
      delete errors.email;
    }
  }
  // advertise
  if (type === "advertise") {
    if (!data.Business.toString().trim()) {
      errors.Business = "لطفا این قسمت را خالی نگذارید.";
    } else {
      delete errors.Business;
    }
    if (!data.Countryy.toString().trim()) {
      errors.Countryy = "لطفا این قسمت را خالی نگذارید.";
    } else {
      delete errors.Countryy;
    }
    if (!data.Cityy.toString().trim()) {
      errors.Cityy = "لطفا این قسمت را خالی نگذارید.";
    } else {
      delete errors.Cityy;
    }
    if (!data.Emaill) {
      errors.Emaill = "لطفا این قسمت را خالی نگذارید.";
    } else if (!/\S+@\S+\.\S+/.test(data.Emaill)) {
      errors.Emaill = "آدرس ایمیل نامعتبر است.";
    } else {
      delete errors.Emaill;
    }
  }
  // write comment
  if (type === "writeComment") {
    // if (!data.onvan.toString().trim()) {
    //   errors.onvan = "لطفا این قسمت را خالی نگذارید.";
    // } else {
    //   delete errors.onvan;
    // }
    // if (!data.nazar.toString().trim()) {
    //   errors.nazar = "لطفا این قسمت را خالی نگذارید.";
    // } else {
    //   delete errors.nazar;
    // }
  }

  if (type === "application") {
    if (!data.email) {
      errors.email = "لطفا این قسمت را خالی نگذارید.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "آدرس ایمیل نامعتبر است.";
    } else {
      delete errors.email;
    }
  }

  // change pass dashboard
  if (type === "change-pass-dashboard") {
    if (!data.OldPassword.toString().trim()) {
      errors.OldPassword = "لطفا این قسمت را خالی نگذارید.";
    } else if (data.OldPassword.toString().trim().length < 8) {
      errors.OldPassword = "رمز عبور باید حداقل ۸ کاراکتر یا بیشتر باشد.";
    } else {
      delete errors.OldPassword;
    }

    if (!data.NewPassword.toString().trim()) {
      errors.NewPassword = "لطفا این قسمت را خالی نگذارید.";
    } else if (data.NewPassword.toString().trim().length < 8) {
      errors.NewPassword = "رمز عبور باید حداقل ۸ کاراکتر یا بیشتر باشد.";
    } else {
      delete errors.NewPassword;
    }

    if (!data.RepeatNewPassword.toString().trim()) {
      errors.RepeatNewPassword = "لطفا این قسمت را خالی نگذارید.";
    } else if (data.NewPassword !== data.RepeatNewPassword) {
      errors.RepeatNewPassword = "تکرار رمز عبور همخوانی ندارد.";
    } else if (data.RepeatNewPassword.toString().trim().length < 8) {
      errors.RepeatNewPassword = "رمز عبور باید حداقل ۸ کاراکتر یا بیشتر باشد.";
    } else {
      delete errors.RepeatNewPassword;
    }
  }

  return errors;
};
