import React, { useState, useContext, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
import Skeleton from "@mui/material/Skeleton";
import "./more-modal.css";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { Link } from "react-router-dom";
import { DomainContext, LangIdContext } from "../../App";
// config
import configData from "../../config/config.json";

const MoreModal = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [basicModal, setBasicModal] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);

  // accordion
  const theme = createTheme({
    direction: "rtl", // Both here and <body dir="rtl">
  });
  // Create rtl cache
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  // styles
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    borderRadius: `10px`,
    borderBottom: `1px solid white`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    minHeight: "44px",
    height: "44px",
    padding: "0 23px 0 16px",
    borderTop: "1px solid #8ef0c0",
    backgroundColor: "#fff",
    flexDirection: "row",
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid #8ef0c0",
    margin: "0 29px",
    padding: "10px 0",
  }));

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel, open) => (event, newExpanded) => {
    if (open) {
      setExpanded(newExpanded ? panel : false);
    }
  };

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };
  const [dataCome, setDataCome] = useState(false);
  const [catList, setCatList] = useState(false);

  useEffect(() => {
    if (basicModal) {
      axios
        // .get(domain + "/api/categories-all", { headers: header })
        .get(configData.API_DOMAIN + "/api/v1/Categories", {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setDataCome(true);
            setCatList(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    }
  }, [basicModal]);
  return (
    <>
      <div className="listmobile-center list2 col-3" onClick={toggleShow}>
        <Link className="a-listmobile " to="">
          <img
            className="img-listmobile mb-1"
            src={props.icon}
            alt={props.title}
          />
          <br />
          {/* بیشتر */}
          {props.title}
        </Link>
      </div>

      <MDBModal
        show={basicModal}
        setShow={setBasicModal}
        tabIndex="-1"
        dir="rtl"
        id="f44444"
        className="p-0"
      >
        <MDBModalDialog size="fullscreen">
          <MDBModalContent>
            <MDBModalHeader className="result-modal-header">
              {/* <div className="d-flex justify-content-between"> */}
              <MDBModalTitle>دسته بندی</MDBModalTitle>
              <MDBBtn
                className="btn-close mx-0"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
              {/* </div> */}
            </MDBModalHeader>
            <MDBModalBody className="p-0">
              <div className="result-filter">
                <CacheProvider value={cacheRtl}>
                  <ThemeProvider theme={theme}>
                    <div>
                      {dataCome ? (
                        catList.map((item) => (
                          <Accordion
                            key={item.cId}
                            expanded={expanded === `panel${item.cId}`}
                            onChange={handleChange(
                              `panel${item.cId}`,
                              item.children && item.children.length > 0
                            )}
                            className="moremodal-border"
                          >
                            <AccordionSummary
                              aria-controls="panel1d-rtl-content"
                              id="panel1d-rtl-header"
                            >
                              <Typography className="moremodal-header">
                                <Link
                                  to={item.url}
                                  className={"moremodal-header-link"}
                                >
                                  <img
                                    src={item.icon}
                                    alt={item.title}
                                    className="img-header-subcat"
                                  />
                                  {item.title}
                                </Link>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                {item.children && item.children.length ? (
                                  item.children.map((item2) => (
                                    <div
                                      key={item2.cId}
                                      className="result-filter-item result-filter-item-res"
                                    >
                                      <Link
                                        to={item2.url}
                                        className="color-242b2e"
                                        onClick={() => {
                                          if (props.setCloseModal) {
                                            props.setCloseModal(false);
                                          }
                                        }}
                                      >
                                        <span className="moremodal-span">
                                          {item2.title}
                                        </span>
                                      </Link>
                                    </div>
                                  ))
                                ) : (
                                  <p></p>
                                )}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        ))
                      ) : (
                        <div className="px-4">
                          <Skeleton
                            className="mb-2 mt-3"
                            variant="text"
                            sx={{ fontSize: "0.6rem" }}
                            animation="wave"
                          />
                          <Skeleton
                            className="mb-2"
                            variant="text"
                            sx={{ fontSize: "0.6rem" }}
                            animation="wave"
                          />
                          <Skeleton
                            className="mb-2"
                            variant="text"
                            sx={{ fontSize: "0.6rem" }}
                            animation="wave"
                          />
                          <Skeleton
                            className="mb-2"
                            variant="text"
                            sx={{ fontSize: "0.6rem" }}
                            animation="wave"
                          />
                          <Skeleton
                            className="mb-2"
                            variant="text"
                            sx={{ fontSize: "0.6rem" }}
                            animation="wave"
                          />
                        </div>
                      )}
                    </div>
                  </ThemeProvider>
                </CacheProvider>
                
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default MoreModal;
