import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
//
import HomePage from "../pages/HomePage";
import BusinessPage from "../pages/BusinessPage";
import BusinessWriteComment from "../pages/BusinessWriteComment";
// login
import Login from "../components/login/Login";
import Signup from "../components/signup/Signup";
import ForgetPass from "../components/login/ForgetPass";
import ChangePass from "../components/login/ChangePass";
// moarefi
import IntroduceBusiness from "../pages/IntroduceBusiness";
import IntroduceBusinessConfirm from "../pages/IntroduceBusinessConfirm";
import IntroduceBusinessThanks from "../pages/IntroduceBusinessThanks";
//
import Result2 from "../pages/Result2";
import NotFound from "../pages/NotFound/NotFound";
// Dashbord Page components
import profilefirst from "../pages/Dashbord/profile-first/profilefirst";
import Changepass from "../pages/Dashbord/change-pass/Changepass";
import Favorites from "../pages/Dashbord/favorites/Favorites";
import Options from "../pages/Dashbord/options/Options";
import Messages from "../pages/Dashbord/messages/Messages";
import Exit from "../pages/Dashbord/Exit/Exit";
// Gish pages
import AboutUs from "../pages/Gishniz/AboutUs";
import Rules from "../pages/Gishniz/Rules";
import Contact from "../pages/Gishniz/Contact";
import Privacy from "../pages/Gishniz/Privacy";
import Advertise from "../pages/Gishniz/Advertise";

// registery page
import WelcomePage from "../pages/Registery/WelcomePage";
import EmailRegisteryPage from "../pages/Registery/EmailRegisteryPage";

// import ClusterMap from "../components/google-map/ClusterMap";
import { useContext } from "react";
import { DomainContext, LangIdContext } from "../App";

const Layout = () => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        {/* Gish pages */}
        <Route path="/:id/about" component={AboutUs} />
        <Route path="/:id/terms-conditions" component={Rules} />
        <Route path="/:id/contact" component={Contact} />
        <Route path="/:id/privacy-policy" component={Privacy} />

        <Route path="/:id/ads-tariff" component={Advertise} />

        {/* Dashbord Pages */}
        <Route path={"/members/profile"} component={profilefirst} />
        <Route path={"/members/change-password"} component={Changepass} />
        <Route path={"/members/saved-business"} component={Favorites} />
        <Route path={"/members/reviews"} component={Options} />
        <Route path={"/members/messages"} component={Messages} />
        <Route path={"/members/exit"} component={Exit} />

        {/* moarefi */}
        <Route
          path="/introduce-business/introduce-business-thanks"
          component={IntroduceBusinessThanks}
        />
        <Route
          path="/introduce-business/introduce-business-confirm"
          component={IntroduceBusinessConfirm}
        />
        <Route path="/introduce-business" component={IntroduceBusiness} />
        {/* /business-write-comment */}
        <Route path="/write-review/:id" component={BusinessWriteComment} />
        <Route path="/biz/:id" component={BusinessPage} />

        <Route path={"/search/"} component={Result2} />

        {/* login */}
        <Route path="/login" component={Login} />
        <Route path="/register" component={Signup} />
        <Route path="/forget-password" component={ForgetPass} />
        <Route path="/change-password" component={ChangePass} />

        {/* Registery */}
        <Route path="/welcome" component={WelcomePage} />
        <Route path="/email-registery" component={EmailRegisteryPage} />

        {/* <Route exact path="/map" component={ClusterMap} /> */}

        <Route exact path="/" component={HomePage} />
        {/* <Route path="/en/" /> */}
        {/* <Route  path="/en" /> */}

        {/* <Route component={NotFound} /> */}
        <Route path="/404" component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  );
};

export default Layout;
