import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

import RateIcon from "../rate-icon/RateIcon";

const RecCard = (props) => {
  let imageSrc = "";

  if (props.img) {
    imageSrc = props.img.includes(".png")
      ? props.img.replace(".png", "_162x162.png")
      : props.img.replace(".jpg", "_162x162.jpg");
  }

  return (
    <>
      {!props.datacome ? (
        <MDBCard
          className="color-101010 hov-trans px-auto hov-shadow shadow-off card-border rec-card "
          style={{ maxWidth: "" }}
        >
          <MDBRow className="g-0  h-skeleton-inherti">
            <MDBCol className="col-5 col-sm-4 col-md-5">
              <Skeleton
                variant="rectangular"
                className="img-r-card "
                animation="wave"
              />
            </MDBCol>
            <MDBCol className="col-7 col-sm-8 col-md-7">
              <MDBCardBody className="me-2 mt-2 pt-1 p-0 text-end">
                <MDBCardText className="ps-2">
                  <Skeleton
                    className="skeleton-text1-rec"
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                    width={120}
                    animation="wave"
                  />
                  <Skeleton
                    className="skeleton-text2-rec"
                    variant="text"
                    sx={{ fontSize: "0.6rem" }}
                    animation="wave"
                  />
                  <Skeleton
                    className="mt-0"
                    variant="text"
                    sx={{ fontSize: "0.6rem" }}
                    animation="wave"
                  />

                  <Skeleton
                    className="skeleton-gish-rec"
                    width={95}
                    height={30}
                    animation="wave"
                  />
                </MDBCardText>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      ) : (
        <a href={props.url} target={props.blog ? "_blank" : ""} className="">
          <MDBCard
            className="color-101010 hov-trans px-auto hov-shadow shadow-off card-border rec-card result-res-card"
            style={{ maxWidth: "" }}
          >
            <MDBRow className="g-0  ">
              <MDBCol className="col-5 col-sm-4 col-md-5">
                <LazyLoadImage
                  // PlaceholderSrc={
                  //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
                  // }
                  className="img-r-card img-fluid"
                  src={imageSrc}
                  alt={props.title}
                />
              </MDBCol>
              <MDBCol className="col-7 col-sm-4 col-md-7">
                <MDBCardBody
                  className="mx-2 mt-2 pt-1 p-0 text-end"
                  style={{ minHeight: "119px" }}
                >
                  <MDBCardTitle className="fw-bold fs-15 mb-1 fs-xs-12 d-flex justify-content-between">
                    {props.title}
                    <LazyLoadImage
                      // PlaceholderSrc={
                      //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
                      // }
                      className="card-tit-img me-1 "
                      src={props.flag}
                      alt="country"
                    />
                  </MDBCardTitle>
                  <MDBCardText className=" mb-sm-2 mb-0 fs-14 fs-xs-11">
                    {props.desc1}مشاور املاک
                  </MDBCardText>
                  <MDBCardText className=" fs-13 fs-xs-10 mb-1 color-101010ab font-family-en">
                    {props.desc2}
                  </MDBCardText>
                  {/* Comment The tags for now */}
                  {/* <div className="row biz-tags-container pt-2 mb-4">
                    {props.Tags &&
                      props.Tags.map((item) => (
                        <span key={item} className="biz-tags">
                          {item}
                        </span>
                      ))}
                  </div> */}
                  <MDBCardText className="">
                    <RateIcon
                      cardrate={parseFloat(props.rate)}
                      isRec={1}
                      isAdd={0}
                      dir="ltr "
                    />
                  </MDBCardText>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBCard>
        </a>
      )}
    </>
  );
};

export default RecCard;
