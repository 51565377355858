import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
//
import { DomainContext, LangIdContext } from "../../../App";
// config
import configData from "../../../config/config.json";
// rtl
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
//components
import MetaTagsEndUserPage from "../../../components/meta-tags-seo/MetaTagsEndUserPage";
import Sidbar from "../../../components/Dashbord/sidbar/Sidbar";
import Main from "../../../components/Dashbord/Main/Main";
import Header_mobile from "../../../components/Dashbord/Header_mobile/Header_mobile";
import AlertSuccess from "../../../components/Dashbord/AlertSuccess/AlertSuccess";
import { validate } from "../../../components/login/validate";
// style
import "../indexprofile.css";
//
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const theme = createTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});
// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const Changepass = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [disable, setDisable] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [apiError, setApiError] = useState([]);

  const [data, setData] = useState({
    MId: "",
    OldPassword: "",
    NewPassword: "",
    RepeatNewPassword: "",
  });

  const inputChangeHandler = (event) => {
    setPassErr(false);
    setApiError([]);
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const [showAlert, setshowAlert] = useState(false);
  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 4000);
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    setErrors(validate(data, "change-pass-dashboard"));
  }, [data, touched]);

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //api header
  const headerMeta = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Page: "change-password",
  };

  useEffect(() => {
    if (getCookie("MemId")) {
      axios
        // .get(domain + "/api/member/meta-enduser", { headers: headerMeta })
        .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
          headers: headerMeta,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setMTagsCome(true);
            setMetaData(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      props.history.push({
        pathname: "/login",
      });
    }
  }, []);

  const headerPost = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Authorization: getCookie("token") ? "Bearer " + getCookie("token") : "",
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setPassErr(false);
    setApiError([]);

    if (JSON.parse(localStorage.getItem("MemberDetail")).setPassword) {
      if (!Object.keys(errors).length) {
        if (data.NewPassword != data.RepeatNewPassword) {
          setPassErr(true);
        } else {
          setDisable(true);

          const dataPost = {
            password: data.OldPassword,
            newPassword: data.NewPassword,
          };

          axios
            // .post(domain + "/api/member/change-password", dataPost, {
            .post(
              configData.API_DOMAIN + "/api/v1/Members/update-password",
              dataPost,
              {
                headers: headerPost,
              }
            )
            .then((response) => {
              setDisable(false);

              if (response.data.isSuccess) {
                alertHandeler();
              } else {
                setApiError(response.data.message);
              }
            })
            .catch((error) => {
              // alert(error);
            });
        }
      } else {
        setTouched({
          OldPassword: true,
          NewPassword: true,
          RepeatNewPassword: true,
        });
      }
    } else {
      if (
        (errors.NewPassword == undefined &&
          errors.RepeatNewPassword == undefined) ||
        (errors.NewPassword == "" && errors.RepeatNewPassword == "")
      ) {
        if (data.NewPassword != data.RepeatNewPassword) {
          setPassErr(true);
        } else {
          setDisable(true);

          const dataPost = {
            password: data.OldPassword,
            newPassword: data.NewPassword,
          };

          axios
            // .post(domain + "/api/member/change-password", dataPost, {
            .post(
              configData.API_DOMAIN + "/api/v1/Members/update-password",
              dataPost,
              {
                headers: headerPost,
              }
            )
            .then((response) => {
              setDisable(false);

              if (response.data.isSuccess) {
                alertHandeler();
              } else {
                setApiError(response.data.message);
              }
            })
            .catch((error) => {
              // alert(error);
            });
        }
      } else {
        setTouched({
          NewPassword: true,
          RepeatNewPassword: true,
        });
      }
    }
  };

  // for password btn icon
  const [showPassword3, setShowPassword3] = React.useState(false);
  const handleClickShowPassword3 = () => setShowPassword3((show) => !show);
  const handleMouseDownPassword3 = (event) => {
    event.preventDefault();
  };
  const [showPassword2, setShowPassword2] = React.useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} /> : ""}

      <Header_mobile />
      <div>
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <div className="margin-0 d-flex">
              <div className="border-left-dashbord d-none d-md-block">
                <Sidbar />
              </div>
              <div className="bg-main-dashbord">
                <Main lencomments={2}>
                  <h5 className="header-main">تغییر رمز عبور</h5>
                  <div className="centerr mt-3 mt-md-0">
                    {JSON.parse(localStorage.getItem("MemberDetail"))
                      .setPassword ? (
                      <>
                        <div className="custom-text-field-dash position-relative">
                          <TextField
                            type={showPassword3 ? "text" : "password"}
                            inputProps={{ maxLength: 25 }}
                            name="OldPassword"
                            value={data.OldPassword}
                            onChange={inputChangeHandler}
                            id="outlined-basic"
                            label="رمز عبور فعلی"
                            variant="outlined"
                            className={
                              errors.OldPassword && touched.OldPassword
                                ? "rtl-password-input label-field-less7 custom-text-field custom-text-field-dash font-family-en mb-0 dash-text-field custom-text-field-border-error"
                                : "rtl-password-input label-field-less7 custom-text-field custom-text-field-dash font-family-en mb-0 dash-text-field"
                            }
                          />
                          <IconButton
                            className="show-pass-btn-change-pass-dash "
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword3}
                            onMouseDown={handleMouseDownPassword3}
                            edge="start"
                          >
                            {showPassword3 ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </div>
                        {errors.OldPassword && touched.OldPassword && (
                          <span className="input-error change-pash-dahs-error">
                            {errors.OldPassword}
                          </span>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <div className=" custom-text-field-dash mt15 position-relative ">
                      <TextField
                        type={showPassword2 ? "text" : "password"}
                        inputProps={{ maxLength: 25 }}
                        name="NewPassword"
                        value={data.NewPassword}
                        onChange={inputChangeHandler}
                        id="outlined-basic"
                        label="رمز عبور جدید"
                        variant="outlined"
                        className={
                          errors.NewPassword && touched.NewPassword
                            ? "rtl-password-input custom-text-field-dash label-field-less7 custom-text-field font-family-en mb-0 dash-text-field custom-text-field-border-error"
                            : "rtl-password-input custom-text-field-dash label-field-less7 custom-text-field font-family-en mb-0 dash-text-field "
                        }
                      />
                      <IconButton
                        className="show-pass-btn-change-pass-dash "
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}
                        edge="start"
                      >
                        {showPassword2 ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </div>
                    {errors.NewPassword && touched.NewPassword && (
                      <span className="input-error change-pash-dahs-error">
                        {errors.NewPassword}
                      </span>
                    )}
                    <div className=" custom-text-field-dash mt15 position-relative ">
                      <TextField
                        type={showPassword ? "text" : "password"}
                        inputProps={{ maxLength: 25 }}
                        name="RepeatNewPassword"
                        value={data.RepeatNewPassword}
                        onChange={inputChangeHandler}
                        id="outlined-basic"
                        label="تکرار رمز عبور جدید"
                        variant="outlined"
                        className={
                          errors.RepeatNewPassword && touched.RepeatNewPassword
                            ? "rtl-password-input label-field-less7 custom-text-field custom-text-field-dash font-family-en mb-0 dash-text-field custom-text-field-border-error"
                            : "rtl-password-input label-field-less7 custom-text-field custom-text-field-dash font-family-en mb-0 dash-text-field"
                        }
                      />
                      <IconButton
                        className="show-pass-btn-change-pass-dash "
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="start"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </div>
                    {errors.RepeatNewPassword && touched.RepeatNewPassword && (
                      <span className="input-error change-pash-dahs-error">
                        {errors.RepeatNewPassword}
                      </span>
                    )}
                    {passErr && (
                      <div className="centerr mt15 input-error change-pash-dahs-error-dash text-center">
                        تکرار رمز عبور مطابقت ندارد.
                      </div>
                    )}
                    <div className="centerr mt15 input-error change-pash-dahs-error-dash text-center change-pash-dahs-error">
                      {apiError.map((item) => item)}
                    </div>

                    <div className="row_saveButton">
                      <button
                        disabled={disable}
                        onClick={submitHandler}
                        className="saveButton"
                      >
                        تغییر رمز
                      </button>
                    </div>
                  </div>
                </Main>
              </div>
            </div>
          </ThemeProvider>
        </CacheProvider>
      </div>
      {/* alert delete */}
      <AlertSuccess
        showAlert={showAlert}
        text={" رمز عبور جدید با موفقیت ثبت شد."}
      />
    </>
  );
};

export default Changepass;
