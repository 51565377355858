import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import "./loading-modal.css";
import searchIcon from "../../images/result/search.png";
import logo_icon from "../../images/logo 50.50.svg";

const LoadingModal = (props) => {
  const [staticModal, setStaticModal] = useState(true);

  const toggleShow = () => setStaticModal(!staticModal);

  return (
    <>
      {/* <MDBBtn onClick={toggleShow}>Launch static backdrop modal</MDBBtn> */}

      <MDBModal
        staticBackdrop
        tabIndex="-1"
        show={staticModal}
        setShow={setStaticModal}
        id="loading-modal"
      >
        <MDBModalDialog centered>
          <MDBModalContent className="position-relative">
            {/* <MDBModalHeader>
              <MDBModalTitle>Modal title</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader> */}
            <MDBModalBody className="loading-modal-body">
              {/* <span class="loader"></span> */}
              <div className="loader-bg"></div>

              <svg
                class="spinner"
                width="40px"
                height="40px"
                viewBox="0 0 66 66"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  class="path"
                  fill="none"
                  strokeWidth="6"
                  strokeLinecap="round"
                  cx="33"
                  cy="33"
                  r="30"
                ></circle>
              </svg>

              {/* <div className="loading-modal-body-div">
                {props.search ? (
                  <>
                    // {/* <div className="spin-loader2-back"></div>

                    // {/* <div className="spin-loader2"></div> 
                    // {/* <div class="spin-loader" aria-hidden="true"></div> 
                    // {/* <img
                    //   src={searchIcon}
                    //   alt="searching"
                    //   className="loading-modal-img"
                    // /> 
                  </>
                ) : (
                  <img src={logo_icon} alt="..." className="logo-loader" />
                )}
              </div> */}
            </MDBModalBody>
            <MDBModalFooter className="loading-modal-footer">
              درحال انجام عملیات لطفا صبور باشید...
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};
export default LoadingModal;
