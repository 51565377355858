import * as React from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";

import emptyGish from "../../../images/home-body/40 line px.svg";
import fullGish from "../../../images/home-body/40 px.svg";

const RateIcon = (props) => {
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconEmpty": {
      color: "#33E0A0",
    },
    "& .MuiRating-iconFilled": {
      color: "#33E0A0",
    },
    "& .MuiRating-iconHover": {
      color: "#33E0A0",
    },
  });

  return (
    <Stack
      className={
        props.isFav ? "span_emptyGish_profile1" : "span_emptyGish_profile"
      }
      component={"span"}
      spacing={2}
    >
      <StyledRating
        name="customized-color"
        defaultValue={props.cardrate}
        getLabelText={(value) => `${value} Heart${value !== 1 ? "s" : ""}`}
        precision={0.1}
        icon={
          <img src={fullGish} alt="twitter" className="emptyGish_profile" />
        }
        emptyIcon={
          <img src={emptyGish} alt="twitter" className="emptyGish_profile" />
        }
        readOnly={true}
        size="small"
      />
    </Stack>
  );
};

export default RateIcon;
