import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

import RateIcon from "../rate-icon/RateIcon";

const SquareCard = (props) => {
  let imageSrc = "";

  if (props.img) {
    imageSrc = props.img.includes(".png")
      ? props.img.replace(".png", "_260x246.png")
      : props.img.replace(".jpg", "_260x246.jpg");
  }

  return (
    <MDBCard
      className="hov-shadow shadow-off hov-trans mt-3 squ-card color-101010C4 
         mb-2 pb-sm-0 ms-2 ms-xl-0"
    >
      <Link to={props.url} className="">
        {!props.datacome ? (
          <Skeleton
            variant="rectangular"
            className="img-s-card "
            animation="wave"
          />
        ) : (
          // <MDBCardImage
          <LazyLoadImage
            // PlaceholderSrc={
            //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
            // }
            src={imageSrc}
            alt={props.title}
            position="top"
            className="card-img-top img-s-card col-12"
          />
        )}

        <MDBCardBody className="sq-rad px-2 pb-sm-2 pb-4 pb-xs-29  pt-2 text-end  ">
          {!props.datacome ? (
            <>
              <Skeleton
                className="skeleton-text1-rec"
                variant="text"
                sx={{ fontSize: "1rem" }}
                width={120}
                animation="wave"
              />
              <Skeleton
                className=""
                variant="text"
                sx={{ fontSize: "0.6rem" }}
                animation="wave"
              />
              <Skeleton
                className="mb-2 mb-md-1"
                variant="text"
                sx={{ fontSize: "0.6rem" }}
                animation="wave"
              />
              <Skeleton
                className="skeleton-gish-seq mt-sm-n1 mt-md-1 mb-md-1"
                width={95}
                height={30}
                animation="wave"
              />
            </>
          ) : (
            <>
              <MDBCardTitle className="fw-bold mb-0 pb-1 fs-16 fs-xs-15 color-101010 d-flex justify-content-between">
                {props.title}
                <LazyLoadImage
                  // PlaceholderSrc={
                  //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
                  // }
                  src={props.flag}
                  alt="country"
                  className="card-tit-img"
                />
              </MDBCardTitle>
              <MDBCardText className="mb-sm-0 mb-0 fs-14 fs-xs-14 color-101010">
                {props.desc1}
              </MDBCardText>
              <MDBCardText className=" mb-0 fs-13 fs-xs-13 color-101010ab font-family-en">
                {props.desc2}
              </MDBCardText>
              <MDBCardText className="">
                <RateIcon
                  cardrate={parseFloat(props.rate)}
                  isRec={0}
                  isAdd={0}
                  dir="ltr"
                />
              </MDBCardText>
            </>
          )}
        </MDBCardBody>
      </Link>
    </MDBCard>
  );
};

export default SquareCard;
