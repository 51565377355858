import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import RateIcon from "../rate-icon/RateIcon";
import "./map-popup-card.css";

const MapPopupCard = (props) => {
  let imageSrc = "";

  if (props.img) {
    imageSrc = props.img.includes(".png")
      ? props.img.replace(".png", "_87x90.png")
      : props.img.replace(".jpg", "_87x90.jpg");
  }

  return (
    <>
      {!props.datacome ? (
        <MDBCard
          className="color-101010 hov-trans px-auto hov-shadow shadow-off card-border popup-card"
          style={{ maxWidth: "" }}
        >
          <MDBRow className="g-0  h-skeleton-inherti">
            <MDBCol className="col-5 col-sm-4 col-md-5">
              <Skeleton
                variant="rectangular"
                className="img-r-card radius-2"
                animation="wave"
                // width={85}
                height={95}
              />
            </MDBCol>
            <MDBCol className="col-7 col-sm-8 col-md-7">
              <MDBCardBody className="me-2 p-0 text-end">
                <MDBCardText className="ps-2">
                  <Skeleton
                    className="skeleton-text1-rec"
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                    width={120}
                    animation="wave"
                  />
                  <Skeleton
                    className="skeleton-text2-rec"
                    variant="text"
                    sx={{ fontSize: "0.6rem" }}
                    animation="wave"
                  />
                  <Skeleton
                    className="mt-0"
                    variant="text"
                    sx={{ fontSize: "0.6rem" }}
                    animation="wave"
                  />
                </MDBCardText>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      ) : (
        <a href={props.url} target={props.blog ? "_blank" : ""} className="">
          <MDBCard
            className="color-101010  px-auto  shadow-off card-border popup-card"
            style={{ maxWidth: "" }}
          >
            <MDBRow className="g-0  ">
              <MDBCol className="col-4 col-sm-4 col-md-4">
                <MDBCardImage
                  className="popup-card-img"
                  src={imageSrc}
                  alt={props.title}
                  fluid
                />
              </MDBCol>

              <MDBCol className="col-8 col-sm-8 col-md-8">
                <MDBCardBody className="mx-2 mt-0 pt-1 p-0 text-end">
                  <MDBCardTitle className="popup-card-tit">
                    {props.title}
                  </MDBCardTitle>
                  <MDBCardText className="popup-card-p font-family-en">
                    {props.desc2}
                  </MDBCardText>
                  <MDBCardText className="">
                    <RateIcon
                      cardrate={parseFloat(props.rate)}
                      isRec={1}
                      isAdd={0}
                      dir="ltr "
                    />
                  </MDBCardText>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBCard>
        </a>
      )}
    </>
  );
};

export default MapPopupCard;
