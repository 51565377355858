import React, { useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBContainer,
} from "mdb-react-ui-kit";
import "./login-modal.css";
import { Link } from "react-router-dom";
import reportgray from "../../images/business/report gray.svg";
import save0 from "../../images/business/save-line.svg";
import save1 from "../../images/business/save- on.svg";
import pic from "../../images/business/add pic.svg";
import commentsIcon from "../../images/business/comments.svg";

const LoginModal = (props) => {
  const [isSave, setIsSave] = useState(false);
  const saveHandler = () => {
    toggleShow();

    // if (!isSave) {
    //   setIsSave(true);
    // } else {
    //   setIsSave(false);
    // }
  };

  const toggleShow = () => {
    setCentredModal(!centredModal);
    props.history.push({
      pathname: "/",
    });
  };
  const [centredModal, setCentredModal] = useState(false);

  return (
    <>
      {props.id == 1 && (
        <div
          className="ms-md-1 ms-2 business-card-icons-hov business-dropdown mb-0"
          onClick={() => {
            toggleShow();
          }}
        >
          <img
            className="business-card-icon3  business-dropbtn"
            src={reportgray}
            alt="..."
          />
          <div className="business-dropdown-content business-dropdown-content3">
            <a className=" bdi-lastt" href="/business">
              گزارش
            </a>
          </div>
        </div>
      )}
      {props.id == 2 && (
        <div
          className="ms-1 me-n2  me-md-0 business-card-icons-hov business-dropdown "
          onClick={saveHandler}
        >
          {isSave ? (
            <img
              className="business-card-icon2  business-dropbtn"
              src={save1}
              alt="..."
            />
          ) : (
            <img
              className="business-card-icon2  business-dropbtn"
              src={save0}
              alt="..."
            />
          )}
          <div className="business-dropdown-content business-dropdown-content2">
            <a className=" bdi-lastt" href="#" onClick={saveHandler}>
              ذخیره
            </a>
          </div>
        </div>
      )}

      {props.id == 3 && (
        <div className="centerr">
          <Link
            className="btn-nazar-comment mb-3 hov-shadow"
            // to="/business-write-comment"
            onClick={() => {
              toggleShow();
            }}
          >
            ثبت دیدگاه
          </Link>
        </div>
      )}
      {props.id == 32 && (
        <Link
          onClick={() => {
            toggleShow();
          }}
        >
          <div className="com-empty-biz mt-4 mt-md-0">
            <div className="d-flex align-items-center justify-contet-center">
              <div className="text-align-center mx-auto">
                <img
                  src={commentsIcon}
                  alt="comments"
                  className="img-com-empty-biz"
                />
                <p>شما اولین دیدگاه را ثبت کنید.</p>
              </div>
            </div>
          </div>
        </Link>
      )}
      {props.id == 4 && (
        <Link
          className="business-upload-btn hov-shadow"
          // to="/login"
          onClick={() => {
            toggleShow();
          }}
        >
          آپلود عکس
          <img className="business-upload-btn-icon" src={pic} alt="pic" />
        </Link>
      )}
      {props.id == 42 && (
        <Link
          //  to="/login"
          className="business-card-icons-hov"
          onClick={() => {
            toggleShow();
          }}
        >
          <img className="business-card-icon3" src={pic} alt="..." />
        </Link>
      )}

      {props.id == 5 && (
        <MDBBtn
          onClick={() => {
            toggleShow();
          }}
          className="business-ownership-btn2 "
        >
          ویرایش اطلاعات
        </MDBBtn>
      )}

      {/* {props.id == 61 && (
        <button
          className="business-edit-btn d-md-flex d-none"
          onClick={toggleShow}
        >
          پیشنهاد ویرایش کسب‌وکار
        </button>
      )}
      {props.id == 62 && (
        <button
          onClick={toggleShow}
          className="business-edit-responsive hov-shadow d-md-none d-flex"
        >
          پیشنهاد ویرایش کسب‌وکار
        </button>
      )} */}

      <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered className="justify-content-center">
          <MDBModalContent className="costum_modal_login">
            <div className="reletive_profile">
              <MDBBtn
                className="btn-close close_modal_login"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </div>
            <div>
              <p className="text_modal_login px-3">
                برای {props.text} باید وارد حساب کاربری خود شوید.
              </p>
            </div>
            <div className="a_s_c">
              <Link
                onClick={() => {
                  // toggleShow();
                  // props.history.push({
                  //   pathname: "/login",
                  // });
                }}
                to={`/login?target=${props.url}`}
                className="button_modal_login"
              >
                ورود
              </Link>
            </div>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default LoginModal;
