import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
//
import { LangIdContext } from "../../../App";
// config
import configData from "../../../config/config.json";
// style
import "../indexprofile.css";
//components
import MetaTagsEndUserPage from "../../../components/meta-tags-seo/MetaTagsEndUserPage";
import Sidbar from "../../../components/Dashbord/sidbar/Sidbar";
import Main from "../../../components/Dashbord/Main/Main";
import Comments from "../../../components/Dashbord/comments/Comments";
import Header_mobile from "../../../components/Dashbord/Header_mobile/Header_mobile";

const Options = (props) => {
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [reviewList, setReviewList] = useState([{}, {}]);
  const [dataCome, setDataCome] = useState(false);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    MemId: getCookie("MemId"),
    Authorization: getCookie("token") ? "Bearer " + getCookie("token") : "",
  };

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //api header
  const headerMeta = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Page: "reviews",
  };

  useEffect(() => {
    if (getCookie("MemId")) {
      axios
        // .get(domain + "/api/member/meta-enduser", { headers: headerMeta })
        .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
          headers: headerMeta,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setMTagsCome(true);
            setMetaData(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
      setDataCome(false);
      axios
        // .get(domain + "/api/member/all-review", {
        .get(configData.API_DOMAIN + "/api/v1/Members/reviews", {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setReviewList(response.data.data);
            setDataCome(true);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      props.history.push({
        pathname: "/login",
      });
    }
  }, []);

  return (
    <>
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} /> : ""}

      <Header_mobile />
      <div>
        <div className="margin-0 d-flex">
          <div className="border-left-dashbord d-none d-md-block">
            <Sidbar />
          </div>
          <div
            className={
              reviewList && reviewList.length < 3
                ? "bg-main-dashbord"
                : "bg-main-dashbord-more"
            }
          >
            <Main lencomments={reviewList && reviewList.length}>
              <h5 className="header-main">دیدگاه‌ها</h5>
              {/* <div className="costum_option_center"> */}
              {/* <Comments dataCom={reviewList} /> */}
              <Comments dataCom={reviewList} comes={dataCome} />
              {/* </div> */}

              {/* modal delete */}
              {/* <MDBModal
            tabIndex="-1"
            show={centredModal}
            setShow={setCentredModal}
            id={idDelete}
          >
            <MDBModalDialog centered className="justify-content-center">
              <MDBModalContent className="costum_modal">
                <div className="reletive_profile">
                  <MDBBtn
                    className="btn-close close_modal"
                    color="none"
                    onClick={toggleShow}
                  ></MDBBtn>
                </div>
                <div>
                  <p className="text_modal px-3">
                    آیا می‌خواهید این دیدگاه از لیست دیدگاه‌‌هایتان پاک شود؟
                  </p>
                </div>
                <div className="a_s_c">
                  <button
                    onClick={() => {
                      toggleShow();
                      deleteHandeler();
                      alertHandeler();
                    }}
                    className="button_modal"
                  >
                    حذف شود
                  </button>
                </div>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal> */}
            </Main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Options;
