import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { MDBContainer } from "mdb-react-ui-kit";
//
import { DomainContext, LangIdContext } from "../../App";
// config
import configData from "../../config/config.json";
// components
import Header2 from "../../components/header/Header2";
import Headermobile from "../../components/header-mobile/Headermobile";
import Footer from "../../components/footer/Footer";
import MetaTagsInfoPage from "../../components/meta-tags-seo/MetaTagsInfoPage";
import NotFound from "../NotFound/NotFound";

const Rules = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [ApiDataCome, setApiDataCome] = useState({
    headerbiz: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    headerLogo: {
      title: "",
      link: "",
    },
  });

  const [pageContent, setPageContent] = React.useState("");
  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: getCookie("_gapp_LId"),
    FooterId: props.match.params.id,
  };
  useEffect(() => {
    // 1 page content
    axios
      // .get(domain + "/api/footer/menu-content", { headers: header })
      .get(
        configData.API_DOMAIN +
          `/api/v1/Contents?FooterId=${props.match.params.id}`,
        {
          headers: header,
        }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setMTagsCome(true);
          setMetaData(response.data.data.metaTag);
          setPageContent(response.data.data.itemContent);
        } else {
          setPageContent("0");
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 2 header-other
    axios
      .get(configData.API_DOMAIN + "/api/v1/Headers/other", {
        headers: header,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setheaderData(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            headerbiz: true,
          }));
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 3 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  //getCookie
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <>
      {pageContent === "0" ? (
        <>
          <NotFound />
        </>
      ) : (
        <>
          {MTagsCome ? <MetaTagsInfoPage data={MetaData} /> : ""}

          <div className="d-none d-md-block">
            <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
          </div>
          <div className="d-block d-md-none">
            <div className="fixed-header">
              <Headermobile data={headerData} />
            </div>
            <div className="box-under-header-res"></div>
          </div>
          <div className="gish-page-back">
            {/* <div className="gish-rul-tit">
          قوانین استفاده از خدمات{" "}
          <span className="color-5ae6a2">سامانه گیشنیز</span>
        </div> */}
            <MDBContainer>
              <div dangerouslySetInnerHTML={{ __html: pageContent }} />
            </MDBContainer>
          </div>
          <Footer data={footerData} dataCome={ApiDataCome.footer} />
        </>
      )}
    </>
  );
};

export default Rules;
