import React, { useState, useEffect, useContext } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import "./result-modal2.css";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import { Chip } from "@mui/material";
import { DomainContext, LangIdContext } from "../../App";
// config
import configData from "../../config/config.json";
// img
import searchBlack from "../../images/header/search black.svg";
import close from "../../images/header/close.png";
import address from "../../images/result/address red.svg";
import next from "../../images/next-left.png"; //////////////////

const ResultModal = (props) => {
  const [basicModal, setBasicModal] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);
  const toggleClose = () => setBasicModal(false);

  //

  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [state, setState] = useState({
    top: false,
  });

  const [keyTime, setKeyTime] = useState("");
  const [keyTime2, setKeyTime2] = useState("");

  const [inputWhat, setInputWhat] = useState("");
  const [whereValue, setWhereValue] = useState("");

  const [chiValue, setChiValue] = useState(null);
  const [chiInputValue, setChiInputValue] = useState("");

  const [whatInput, setWhatInput] = useState("");
  const [whereInput, setWhereInput] = useState("");

  const [kojaValue, setKojaValue] = useState(null);
  const [kojaInputValue, setKojaInputValue] = useState("");

  // for loading
  const [nullList, setNullList] = useState([]);
  const [nullListWhere, setNullListWhere] = useState([]);

  //
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const find_desc = queryParams.get("find_desc");
  const find_loc = queryParams.get("find_loc");
  const cat_id = queryParams.get("cat_id");
  const find_cat = queryParams.get("find_cat");

  useEffect(() => {
    if (find_loc == "") {
      setKojaInputValue("");
      setKojaValue(null);
    }
    if (find_loc && find_loc !== "") {
      setKojaInputValue(find_loc.split("-").join(" "));
      setKojaValue(null);
    }
    if (find_cat && find_cat !== "") {
      setChiInputValue(find_cat.split("-").join(" "));
    } else if (find_desc && find_desc !== "") {
      setChiInputValue(find_desc.split("-").join(" "));
    }
  }, [find_loc]);

  const chipDeleteHandleer = (key) => {
    let url = "";
    switch (key) {
      case 1:
        // find_cat
        setChiInputValue("");
        url =
          "/search?find_desc=" +
          find_desc +
          "&find_loc=" +
          find_loc +
          "&cat_id=&find_cat=";
        break;
      case 2:
        // find-desc
        setChiInputValue("");
        url =
          "/search?find_desc=&find_loc=" +
          find_loc +
          "&cat_id=" +
          find_cat +
          "&find_cat=" +
          cat_id;
        break;
      case 3:
        setKojaInputValue("");
        url =
          "/search?find_desc=" +
          find_desc +
          "&find_loc=&cat_id=" +
          find_cat +
          "&find_cat=" +
          cat_id;
        break;

      default:
        break;
    }
    history.push(url);
  };
  const [listWhereComes, setListWhereComes] = useState(false);

  const [locList, setLocList] = useState([]);

  var go =
    "/search?find_desc=" +
    chiInputValue.replace(/\s+/g, "-") +
    "&find_loc=" +
    kojaInputValue.replace(/\s+/g, "-") +
    "&cat_id=&find_cat=";

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };
  // for disabling
  const [whereCome, setWhereCome] = useState(false);

  const apiWhere = () => {
    setWhereCome(false);

    setNullListWhere([]);
    // console.log("null111");
    // console.log(nullListWhere);
    if (whereInput.length > 1) {
      setListWhereComes(false);
      setLocList([]);
      setNullListWhere([{ Id: "001", Title: "" }]);

      axios

        .get(configData.API_DOMAIN + `/api/v1/Search/where/${whereInput}`, {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setNullListWhere([]);
            setLocList(response.data.data.locations);
            setWhereCome(true);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setLocList([]);
      setNullListWhere([]);
    }
  };

  useEffect(() => {
    if (whatInput.length <= 1 && props.data2) {
      if (JSON.parse(localStorage.getItem("LastVisitBusinesses"))) {
        var arrNull = [
          ...JSON.parse(localStorage.getItem("LastVisitBusinesses")),
          ...props.data2,
        ];
        setNullList(arrNull);
      } else {
        setNullList(props.data2);
      }
    }
    // }, [props.data2 , JSON.parse(localStorage.getItem("LastVisitBusinesses"))]);
  }, [props.data2]);

  useEffect(() => {
    if (whereInput.length > 1) {
      setListWhereComes(false);
      setNullListWhere([]);

      clearTimeout(keyTime);
      setKeyTime(setTimeout(apiWhere, 1000));
    } else if (whereInput.length == 1) {
      setLocList([]);
      setNullListWhere([{ Id: "001", Title: "" }]);
    }
  }, [whereInput]);

  const history = useHistory();

  const FilterHandler = () => {
    let koja = kojaValue.title;

    // history.go("/search");
    // console.log(koja);
    // console.log(
    //   "/search?find_desc=" +
    //     find_desc.replace(/\s+/g, "-") +
    //     find_cat.replace(/\s+/g, "-") +
    //     "&find_loc=" +
    //     koja.replace(/\s+/g, "-") +
    //     "&cat_id=&find_cat="
    // );

    history.replace(
      "/search?find_desc=" +
        find_desc.replace(/\s+/g, "-") +
        find_cat.replace(/\s+/g, "-") +
        "&find_loc=" +
        koja.replace(/\s+/g, "-") +
        "&cat_id=&find_cat="
    );
    toggleClose();
  };

  return (
    <>
      <button onClick={toggleShow} className="result-modal-launch-btn">
        <div>
          <img src={address} alt="" className="result-city-img1" />
          لطفا شهر خود را انتخاب کنید
        </div>
        <img src={next} alt="" className="result-city-img2" />
      </button>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog size="fullscreen" className="z1300">
          <MDBModalContent>
            <MDBModalHeader className="result-modal-header">
              <MDBModalTitle>لطفا شهر خود را انتخاب کنید</MDBModalTitle>
              <MDBBtn
                className="btn-close mx-0"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="p-0">
              <div className="result-filter">
                <div className="auto2 p-0 mx-4 mt-4  city-auto list-left">
                  <Autocomplete
                    open={true}
                    // disableClearable
                    // disablePortal
                    // id="combo-box-demo0"
                    // options={cities}
                    // sx={{ width: 300 }}
                    // getOptionLabel={(option) => option.label}
                    // renderOption={(props, option) => (
                    //   <Box
                    //     component="li"
                    //     sx={{ "& > img": { ml: 2, flexShrink: 0 } }}
                    //     className="fs-4vw"
                    //     {...props}
                    //   >
                    //     {option.label}
                    //   </Box>
                    // )}
                    // renderInput={(params) => (
                    //   <TextField {...params} placeholder="شهر" className="" />
                    // )}
                    value={kojaValue}
                    onChange={(event, newValue) => {
                      setKojaValue(newValue);

                      // newValue.Title,
                    }}
                    inputValue={kojaInputValue}
                    onInputChange={(event, newInputValue) => {
                      // console.log("newInputValue");
                      // console.log(newInputValue);
                      var result;
                      if (newInputValue == "undefined") {
                        result = kojaInputValue.replace(/[^A-Za-z\s,]/gi, "");
                      } else {
                        result = newInputValue.replace(/[^A-Za-z\s,]/gi, "");
                      }
                      // console.log("result");
                      // console.log(result);

                      setKojaInputValue(result);
                      setWhereInput(result);
                    }}
                    filterOptions={(options, state) => options}
                    freeSolo={true}
                    openOnFocus={false}
                    options={
                      whereInput.length > 1 && nullListWhere.length < 1
                        ? locList
                        : nullListWhere
                    }
                    getOptionLabel={(option) => option.title}
                    getOptionDisabled={(option) =>
                      option.Id === "001" || option.Id === ""
                    }
                    popupIcon={""}
                    disableClearable
                    disablePortal
                    id="combo-box-demo0"
                    sx={{ width: 300 }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { ml: 2, flexShrink: 0 } }}
                        className="fs-4vw"
                        {...props}
                      >
                        {nullListWhere.length > 0 && option.Id === "001" ? (
                          <div className="d-flex align-items-center px-2 w-100">
                            <Skeleton
                              variant="circular"
                              width={30}
                              height={26}
                              animation="wave"
                            >
                              <Avatar animation="wave" />
                            </Skeleton>
                            <Skeleton
                              className="mt-1 skeleton-currency"
                              animation="wave"
                            />
                          </div>
                        ) : (
                          <>
                            {option.title}
                            {/* <img
                              src={searchBlack}
                              alt="search black"
                              className=" me-2  autocomplete-search"
                            /> */}
                          </>
                        )}
                      </Box>
                    )}
                    // className="mt-n1"
                    renderInput={(params) => (
                      <div className="position-relative d-flex">
                        <TextField
                          {...params}
                          placeholder={find_loc && find_loc !== "" ? "" : "شهر"}
                          className=""
                        />
                        {/* <div className="header-chip-div mx-3 mt-0">
                          {find_loc && find_loc !== "" ? (
                            <Chip
                              id="rb-chip"
                              // dir="ltr"
                              className="header-chip"
                              label={find_loc.split("-").join(" ")}
                              onDelete={() => {
                                chipDeleteHandleer(3);
                              }}
                              deleteIcon={
                                <img
                                  src={close}
                                  alt="X"
                                  className="s2-chip-icon"
                                />
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div> */}
                      </div>
                    )}
                  />
                </div>
              </div>
            </MDBModalBody>

            <MDBModalFooter className="result-modal-footer">
              <MDBBtn
                onClick={FilterHandler}
                className="result-modal-close-btn"
              >
                اعمال فیلتر
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};
const cities = [
  { label: " تورنتو", id: 1 },
  { label: "تورنتو", id: 2 },
  { label: "تورنتو", id: 3 },
  { label: "تورنتو", id: 4 },
  { label: "تورنتو", id: 5 },
  // { label: "خودرو", id: 1980 },
  // { label: "ورزشی", id: 6 },
  // { label: "زیبایی", id: 7 },
  // { label: "دامپزشکی", id: 8 },
  // { label: "طراحی لباس", id: 9 },
  // { label: "انجمن", id: 10 },
];
export default ResultModal;
