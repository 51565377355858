import React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import emptyGish from "../../images/home-body/40 line px.svg";
import fullGish from "../../images/home-body/40 px.svg";
import done from "../../images/business/accept.svg";

const labels = {
  1: "خیلی بد",
  2: "بد",
  3: "متوسط",
  4: "خوب",
  5: "عالی",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

const RateIcon2 = (props) => {
  const { data, setData } = props;

  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);

  const [rateDone, setRateDone] = React.useState(false);

  return (
    <div className="position-relative  rate2-responsive-pad">
      {rateDone ? <img className="rate2-done" src={done} alt="" /> : ""}
      <Box
        sx={{
          //   width: 200,
          display: "flex",
          alignItems: "center",
          marginRight: "30px",
        }}
      >
        <Rating
          //    readOnly
          id="rate2"
          dir="ltr"
          name="hover-feedback"
          value={data.Rate}
          // value={value}
          precision={1}
          getLabelText={getLabelText}
          onChange={(event, newValue) => {
            // setValue(newValue);
            setValue(parseFloat(event.target.value));
            setData({ ...data, Rate: parseInt(event.target.value) });
            setRateDone(true);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          icon={
            <img
              //   className={props.isBusiness ? "rate-business" : (props.isComment2? "rate-comment2": (props.isComment1? "rate-comment1": "rate-img"))}
              className="rate2-icon"
              src={fullGish}
              alt="twitter"
            />
          }
          emptyIcon={
            <img
              className="rate2-icon"
              // className={props.isBusiness ? "rate-business" : (props.isComment2? "rate-comment2": (props.isComment1? "rate-comment1": "rate-img"))}
              src={emptyGish}
              alt="twitter"
            />
          }
        />
        {value !== null && (
          <Box className="rate2-text">
            {labels[hover !== -1 ? hover : data.Rate]}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default RateIcon2;
