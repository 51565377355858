import React from "react";
import { MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import "./Listmobile.css";
import MoreModal from "../more-modal/MoreModal";

const Listmobile = (props) => {
  return (
    <div className="list-rec">
      <MDBContainer fluid className="px-0 w-285">
        <div className="row ">
          {props.data.map((item) =>
            props.dataCome ? (
              item.title !== "بیشتر" ? (
                <div className="listmobile-center list2 col-3">
                  <Link className="a-listmobile " to={item.url}>
                    <img
                      className="img-listmobile mb-1"
                      src={item.icon}
                      alt={item.shortTitle}
                    />
                    <br />
                    {item.shortTitle}
                  </Link>
                </div>
              ) : (
                <MoreModal  icon={item.icon} title={item.shortTitle} />
              )
            ) : (
              <div className="listmobile-center list2 col-3">
                <div className="a-listmobile ">
                  <Skeleton
                    variant="circular"
                    className="mx-auto"
                    width={40}
                    height={40}
                    animation="wave"
                  />
                  <Skeleton
                    className="mx-0 mt-3"
                    height={12}
                    animation="wave"
                  />
                </div>
              </div>
            )
          )}

          {/* <MoreModal data={props.data} dataCome={props.dataCome} /> */}
          {/* <div className="listmobile-center list2 col-3">
            <Link className="a-listmobile " to="/result">
              <img className="img-listmobile mb-1" src={bishtar} alt="بیشتر" />
              <br />
              بیشتر
            </Link>
          </div> */}
        </div>
      </MDBContainer>
    </div>
  );
};

export default Listmobile;
