import React, { useState, useRef, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "@mui/material/Skeleton";
import { DomainContext, LangIdContext, CookieContext } from "../App";
// config
import configData from "../config/config.json";
// com
import MetaTagsBusinessPage from "../components/meta-tags-seo/MetaTagsBusinessPage";
import Header2 from "../components/header/Header2";
import Headermobile from "../components/header-mobile/Headermobile";
import CardCarousel from "../components/card-carousel/CardCarousel";
import ScrollCategory from "../components/scroll-category/ScrollCategory";
import RateIcon from "../components/rate-icon/RateIcon";
import BusinessAccordion from "../components/business-accordion/BusinessAccordion";
import Comments from "../components/comments/Comments";
import Footer from "../components/footer/Footer";
import EBModal from "../components/suggest-edit-business/EBModals";
import EBModalM from "../components/suggest-edit-business-mobile/EBModals";
import Rec2Card from "../components/rec2-card/Rec2Card";
import AlertSuccess from "../components/Dashbord/AlertSuccess/AlertSuccess";
import BizMapModal from "../components/biz-map-modal/BizMapModal";
import BizMapModalMobile from "../components/biz-map-modal/BizMapModalMobile";
import LoginModal from "../components/login-modal/LoginModal";
import ImageCarousel from "../components/image-carousel/ImageCarousel";
// img
import next_right from "../images/next right white.svg";
import blogicon from "../images/home-body/blog.svg";
import presentation from "../images/home-body/presentation.svg";
import address from "../images/business/address.svg";
import address_red from "../images/business/address red.svg";
import share from "../images/business/share (2).svg";
import save0 from "../images/business/save-line.svg";
import save1 from "../images/business/save- on.svg";
import reportgray from "../images/business/report gray.svg";
import pic from "../images/business/add pic.svg";
import comment from "../images/business/comment on.svg";
import key from "../images/business/key (1).svg";

const BusinessPage = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const { cookieState, setCookieState } = useContext(CookieContext);

  useEffect(() => {}, [cookieState]);

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //this 2lines are for scrolling to an element in page
  const myRef = useRef(null);
  const myRef2 = useRef(null);
  const executeScroll = () => {
    myRef.current.scrollIntoView();
    // console.log("com rref");
  };
  const executeScroll2 = () => {
    myRef2.current.scrollIntoView();
    // console.log("com rref2");
  };

  // check if user have comment for biz to disable comment button
  const [userHaveComment, setUserHaveComment] = useState(null); // فعلا کامنت شود تا امکان ادیت وجود داشته باشد

  const [isSave, setIsSave] = useState(false);
  const [saveDisable, setSaveDisable] = useState(false);

  const saveHandler = () => {
    setSaveDisable(true);
    const dataPost = {
      bId: props.match.params.id,
    };

    axios
      .post(
        //   .post(domain + "/api/favorite-biz", dataPost, {
        configData.API_DOMAIN + "/api/v1/Members/saved-business",
        dataPost,
        {
          headers: {
            ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
            LangId: langIdState,
            Authorization: getCookie("token")
              ? "Bearer " + getCookie("token")
              : "",
          },
        }
      )
      .then((response) => {
        setSaveDisable(false);
        if (response.data.isSuccess) {
          if (response.data.data == "saved") {
            setIsSave(true);
          } else if (response.data.data == "removed") {
            setIsSave(false);
          }
        }
      })
      .catch((error) => {
        // alert(error);
      });
  };

  // moarefi more
  const [showLess, setShowLess] = React.useState(true);
  const [text, setText] = React.useState("");
  const [details, setDetails] = useState({
    details: [{}],
  });
  const [emkanat, setEmkanat] = useState([]);
  const [payments, setPayments] = useState([]);

  const [s, sets] = useState({
    details: [{}],
  });
  const [blog, setBlog] = useState([
    {
      _embedded: {
        "wp:featuredmedia": [
          {
            media_details: {
              sizes: {
                medium_large: {
                  source_url: "",
                },
              },
            },
          },
        ],
      },
      title: {
        rendered: "",
      },
      excerpt: {
        rendered: "",
      },
      id: "",
      link: "",
    },
    {
      _embedded: {
        "wp:featuredmedia": [
          {
            media_details: {
              sizes: {
                medium_large: {
                  source_url: "",
                },
              },
            },
          },
        ],
      },
      title: {
        rendered: "",
      },
      excerpt: {
        rendered: "",
      },
      id: "",
      link: "",
    },
    {
      _embedded: {
        "wp:featuredmedia": [
          {
            media_details: {
              sizes: {
                medium_large: {
                  source_url: "",
                },
              },
            },
          },
        ],
      },
      title: {
        rendered: "",
      },
      excerpt: {
        rendered: "",
      },
      id: "",
      link: "",
    },
    {
      _embedded: {
        "wp:featuredmedia": [
          {
            media_details: {
              sizes: {
                medium_large: {
                  source_url: "",
                },
              },
            },
          },
        ],
      },
      title: {
        rendered: "",
      },
      excerpt: {
        rendered: "",
      },
      id: "",
      link: "",
    },
  ]);
  const [adds, setAdds] = useState([]);
  const [latestbiz, setLatestbiz] = useState({
    businesses: [
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
    ],
    sectionTitle: {
      icon: "",
      title: "",
      url: "",
    },
  });

  const [ApiDataCome, setApiDataCome] = useState({
    header: false,
    footer: false,
    headerbiz: false,
    bizrate: false,
    reviewforbiz: false,
    blogforbiz: false,
    similarforbiz: false,
  });

  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    headerLogo: {
      title: "",
      link: "",
    },
  });
  const [bizrateData, setBizrateData] = useState({});
  const [reviewforbiz, setReviewforbiz] = useState({});
  const [shareList, setShareList] = useState([]);

  const [showAlert, setshowAlert] = useState(false);

  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 2000);
  };
  // copy to clipboard fuction
  const copyToClipboard = () => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(window.location.href);
    // Alert the copied text
    alertHandeler();
  };

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(() => {
    setMTagsCome(false);

    if (getCookie(`_gbiz_id_${props.match.params.id}`)) {
      var count = 1;
    } else {
      count = 0;
      setCookie(`_gbiz_id_${props.match.params.id}`, props.match.params.id, 1);
    }

    //api header
    const header = {
      ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
      LangId: langIdState,
      BizId: props.match.params.id,
      MemberId: cookieState !== "" ? cookieState : 0,
      Count: count,
      LocId: "7",
      Authorization: getCookie("token") ? "Bearer " + getCookie("token") : "",
    };

    setMTagsCome(false);
    setDetails({ details: [{}] });
    setText("");
    setApiDataCome({
      header: false,
      footer: false,
      headerbiz: false,
      bizrate: false,
      reviewforbiz: false,
      blogforbiz: false,
      similarforbiz: false,
    });

    // 1 bizdetails
    axios
      // .get(domain + "/api/bizdetails", { headers: header })
      .get(
        configData.API_DOMAIN +
          `/api/v1/Businesses/${props.match.params.id}/details?count=${count}`,
        {
          headers: header,
        }
      )
      .then((response) => {
        setDetails(response.data.data);
        setText(response.data.data.details[0].description);
        setIsSave(response.data.data.details[0].isSaved);
        setShareList(response.data.data.shareDetails);

        setMTagsCome(true);
        setMetaData(response.data.data.metaTag);

        if (
          response.data.data.metaTag.canonical !==
          `${configData.SITE_URL}${props.history.location.pathname}`
        ) {
          var x = response.data.data.metaTag.canonical;
          var x2 = x.replace(`${configData.SITE_URL}/biz/`, "");

          props.history.push({
            pathname: `/biz/${x2}`,
          });
        }

        // بازدیدهای اخیر
        let bizObject = {
          bId: response.data.data.details[0].bId,
          url: response.data.data.metaTag.canonical.replace(
            "https://gishniz.link",
            ""
          ),
          location: `${response.data.data.details[0].cityTitle},${response.data.data.details[0].countryTitle}`,
          title: response.data.data.details[0].title,
          photoPath: response.data.data.details[0].photoPath.includes(".png")
            ? response.data.data.details[0].photoPath.replace(
                ".png",
                "_302x302.png"
              )
            : response.data.data.details[0].photoPath.replace(
                ".jpg",
                "_302x302.jpg"
              ),
        };
        let bizArray = [];
        let bizArray_update = [];

        // to remove unValid data from local storage -> delete it for 3 version
        if (localStorage.getItem("test bizzz")) {
          localStorage.removeItem("test bizzz");
        }

        const ee = localStorage.getItem("LastVisitBusinesses")
          ? JSON.parse(localStorage.getItem("LastVisitBusinesses")).reverse()
          : [];
        if (
          localStorage.getItem("LastVisitBusinesses") &&
          JSON.parse(localStorage.getItem("LastVisitBusinesses")).length > 0
        ) {
          for (let index = 0; index < ee.length; index++) {
            // console.log("22");
            // const element = JSON.parse(localStorage.getItem("LastVisitBusinesses"));
            const element = ee;
            const element2 = element[index];
            if (element[index].title !== bizObject.title) {
              bizArray.push(element2);
            }
          }
          bizArray.push(bizObject);
        } else {
          bizArray.push(bizObject);
        }
        if (bizArray.length > 5) {
          bizArray_update = bizArray.slice(1);
        } else {
          bizArray_update = bizArray;
        }

        localStorage.setItem(
          "LastVisitBusinesses",
          JSON.stringify(bizArray_update.reverse())
        );
        // بازدیدهای اخیر
      })

      .catch((error) => {
        // alert(error);
        // props.history.push({
        //   pathname: "/404",
        // });
      });

    // 2 header-biz
    axios
      .get(configData.API_DOMAIN + "/api/v1/Headers/business", {
        headers: header,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setheaderData(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            headerbiz: true,
          }));
        }
      })
      .catch((error) => {
        // alert(error);
      });

    // 3 footer
    axios
      // .get(domain + "/api/footer", { headers: header })
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });

    // 4 bizrate
    axios
      // .get(domain + "/api/bizrate", { headers: header })
      .get(
        configData.API_DOMAIN +
          `/api/v1/Businesses/${props.match.params.id}/rates`,
        {
          headers: header,
        }
      )
      .then((response) => {
        setBizrateData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, bizrate: true }));
      })
      .catch((error) => {
        // alert(error);
      });

    // 5 reviewforbiz
    axios
      // .get(domain + "/api/reviewforbiz", { headers: header })
      .get(
        configData.API_DOMAIN +
          `/api/v1/Businesses/${props.match.params.id}/reviews`,
        {
          headers: header,
        }
      )
      .then((response) => {
        setReviewforbiz(response.data.data);
        setApiDataCome((ApiDataCome) => ({
          ...ApiDataCome,
          reviewforbiz: true,
        }));
      })
      .catch((error) => {
        // alert(error);
      });

    // فعلا کامنت شود تا امکان ادیت وجود داشته باشد
    if (getCookie("MemId")) {
      // check if user have comment for biz to disable comment button
      axios
        .get(
          configData.API_DOMAIN +
            `/api/v1/Businesses/${parseInt(
              props.match.params.id
            )}/member-review`,
          {
            headers: header,
          }
        )
        .then((response) => {
          if (response.data.isSuccess) {
            if (
              response.data.data.ReviewId != 0 &&
              response.data.data.ReviewId != null
            ) {
              setUserHaveComment(true);
            } else {
              setUserHaveComment(false);
            }
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setUserHaveComment(false);
    }

    // 6 catoptitle
    // axios
    //   .get(domain + "/api/catoptitle", { headers: header })
    //   .then((response) => {
    //     if (response.data.msg == "done") {
    //       setEmkanat(response.data.OptionsList);
    //     }
    //   })
    //   .catch((error) => {
    //     // alert(error);
    //   });

    // 7 payments-type
    // axios
    //   .get(domain + "/api/payments-type", { headers: header })
    //   .then((response) => {
    //     if (response.data.msg == "done") {
    //       setPayments(response.data.PayList);
    //     }
    //   })
    //   .catch((error) => {
    //     // alert(error);
    //   });

    // 8 similarforbiz
    axios
      // .get(domain + "/api/similarforbiz", { headers: header })
      .get(
        configData.API_DOMAIN +
          `/api/v1/Businesses/${props.match.params.id}/similar`,
        {
          headers: header,
        }
      )
      .then((response) => {
        setLatestbiz(response.data.data);
        setApiDataCome((ApiDataCome) => ({
          ...ApiDataCome,
          similarforbiz: true,
        }));
      })
      .catch((error) => {
        // alert(error);
      });

    // 9 blogforbiz
    axios
      .get(
        configData.BLOG_API_DOMAIN +
          "/wp-json/wp/v2/posts?_embed&per_page=4&lang=fa"
      )
      .then((response) => {
        setBlog(response.data);
        setApiDataCome((ApiDataCome) => ({
          ...ApiDataCome,
          blogforbiz: true,
        }));
      })
      .catch((error) => {
        // alert(error);
      });

    // 10 advertisement
    axios
      // .get(domain + "/api/advertisement", { headers: header })
      .get(configData.API_DOMAIN + "/api/v1/Advertisement", {
        headers: header,
      })
      .then((response) => {
        setAdds(response.data.data);
      })
      .catch((error) => {
        // alert(error);
      });

    // 11 Visits بازدید برای داشبورد
    axios
      .post(
        configData.API_DOMAIN + "/api/v1/Businesses/visit",
        { link: props.history.location.pathname, bId: props.match.params.id },
        {
          headers: {
            ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
            LangId: langIdState,
            Authorization: getCookie("token")
              ? "Bearer " + getCookie("token")
              : "",
          },
        }
      )
      .then((response) => {})
      .catch((error) => {
        // alert(error);
      });
  }, [props.match.params.id]);

  return (
    <>
      {MTagsCome ? <MetaTagsBusinessPage data={MetaData} /> : ""}
      <div className="d-md-block d-none">
        <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
      </div>
      <div className="d-md-none d-block">
        <div className="fixed-header">
          <Headermobile data={headerData} />
        </div>
        <div className="box-under-header-res"></div>
      </div>
      <div className="container">
        <Link className="business-text1">
          {/* <img className="business-right-img" src={next_right} alt="next" />
          دیدن بقیه وکلای ونکوور */}
        </Link>
        <div className="row">
          <div className="ps-md-2 col-12 col-md-8 business-padding">
            <div className="business-card-right row">
              <div className="col-12 col-md-5 centerr business-padding">
                {MTagsCome ? (
                  <>
                    <ImageCarousel
                      photos={details.photos}
                      bizName={details.details[0].title}
                    />
                    {/* <MDBCardImage
                      className="business-card-img"
                      src={
                        details.details[0].photoPath.includes(".png")
                          ? details.details[0].photoPath.replace(
                              ".png",
                              "_302x302.png"
                            )
                          : details.details[0].photoPath.replace(
                              ".jpg",
                              "_302x302.jpg"
                            )
                      }
                      alt={details.details[0].title}
                      fluid
                    /> */}
                    {/* responsive size */}
                    <div className="business-card-text3 d-md-none d-block">
                      {/* گزارش تا فاز 2 کامنت میشه  */}
                      {/* {cookieState !== "" ? (
                    <div className="ms-md-1 ms-2 business-card-icons-hov business-dropdown mb-0">
                      <img
                        className="business-card-icon3  business-dropbtn"
                        src={reportgray}
                        alt="..."
                      />
                      <div className="business-dropdown-content business-dropdown-content3">
                        <a className=" bdi-lastt">گزارش</a>
                      </div>
                    </div>
                  ) : (
                    <LoginModal
                      url={props.location.pathname}
                      id={1}
                      text={"ثبت گزارش"}
                    />
                  )} */}

                      <a
                        onClick={executeScroll2}
                        className="business-card-icons-hov"
                      >
                        <img
                          className="business-card-icon4"
                          src={comment}
                          alt="..."
                        />
                      </a>
                      {/* <Link to="/login" className="business-card-icons-hov">
                    <img className="business-card-icon3" src={pic} alt="..." />
                  </Link> */}

                      {/* فعلا کامنت تا فاز دوم */}
                      {/* {cookieState !== "" ? (
                        <Link className="business-card-icons-hov">
                          <img
                            className="business-card-icon3"
                            src={pic}
                            alt="..."
                          />
                        </Link>
                      ) : (
                        <LoginModal
                          url={props.location.pathname}
                          id={42}
                          text={"آپلود عکس"}
                        />
                      )} */}

                      {/* {getCookie("MemId") ? ( */}
                      {cookieState !== "" ? (
                        <div
                          className="ms-1 business-card-icons-hov business-dropdown mb-0"
                          // onClick={saveHandler}
                        >
                          {!saveDisable ? (
                            isSave ? (
                              <img
                                className="business-card-icon2  business-dropbtn"
                                src={save1}
                                alt="..."
                                onClick={saveHandler}
                              />
                            ) : (
                              <img
                                className="business-card-icon2  business-dropbtn"
                                src={save0}
                                alt="..."
                                onClick={saveHandler}
                              />
                            )
                          ) : (
                            <Skeleton
                              variant="circular"
                              width={32}
                              height={30}
                              animation="wave"
                              className="biz-icon-skeleton2 d-inline-block"
                            ></Skeleton>
                          )}
                          <div className="business-dropdown-content business-dropdown-content2">
                            <a
                              className=" bdi-lastt"
                              // href="#"
                              onClick={!saveDisable ? saveHandler : ""}
                            >
                              ذخیره
                            </a>
                          </div>
                        </div>
                      ) : (
                        <LoginModal
                          url={props.location.pathname}
                          id={2}
                          text={"ذخیره"}
                        />
                      )}

                      <div className="ms-1  business-card-icons-hov business-dropdown mb-0">
                        <img
                          className="business-card-icon1 business-dropbtn"
                          src={share}
                          alt="..."
                        />
                        <div className="business-dropdown-content">
                          {shareList.map((item) =>
                            item.title === "Copy Link" ? (
                              <a
                                onClick={copyToClipboard}
                                className=""
                                key={item.icon}
                              >
                                {item.title}
                                <img
                                  className="business-drop-img"
                                  // src={link}
                                  src={item.icon}
                                  alt=""
                                />
                              </a>
                            ) : (
                              <a
                                className=""
                                href={item.shareLink}
                                key={item.icon}
                              >
                                {item.title}
                                <img
                                  className="business-drop-img"
                                  // src={link}
                                  src={item.icon}
                                  alt=""
                                />
                              </a>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    {/* responsive size */}
                    {/* <div className="d-md-none d-flex justify-content-center">
                </div> */}
                    {/* <a
                  className="business-edit-responsive hov-shadow d-md-none d-flex"
                  href=""
                  >
                  پیشنهاد ویرایش کسب‌وکار
                </a> */}
                    {/* <LoginModal url={props.location.pathname} id={62} text={"62"} /> */}
                    {/* <EBModalM id={props.match.params.id} /> */}
                  </>
                ) : (
                  <>
                    <Skeleton
                      variant="rectangular"
                      className="height-100 h-res-biz-skeleton mt-2 mt-md-0 radius-2"
                      animation="wave"
                    />
                  </>
                )}
              </div>
              <div className="col-12 col-md-7 p-0 order-md-last order-first ">
                {MTagsCome ? (
                  <MDBCardBody className="p-0 bh-100">
                    <div className="d-flex justify-content-between">
                      <div>
                        <MDBCardTitle className="business-card-title d-flex justify-content-between mb-md-4 mb-3">
                          {/* نادر پناهی */}
                          {details.details[0].title}
                        </MDBCardTitle>
                        {/* کککک */}
                        <MDBCardText className="business-card-text1">
                          {details.details[0].jobTitle}
                        </MDBCardText>
                        <MDBCardText className="business-card-text2 font-family-en">
                          {/* ریچموند هیل، ونکوور، کانادا */}
                          {details.details[0].cityTitle},{" "}
                          {details.details[0].provinceTitle},{" "}
                          {details.details[0].countryTitle}
                        </MDBCardText>
                      </div>
                      <div className="minwidth-90px ">
                        <img
                          className="biz-flag d-md-none"
                          src={details.details[0].flag}
                          alt="falg"
                        />
                        <div className="leftt d-contents d-md-block">
                          <img
                            className="biz-flag d-none d-md-block"
                            src={details.details[0].flag}
                            alt="falg"
                          />
                          <div className="d-md-none">
                            <RateIcon
                              cardrate={parseFloat(details.details[0].rate)}
                              isRec={0}
                              isBusiness={1}
                              dir="ltr "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" biz-tags-div d-md-block">
                      {/* Comment The tags for now */}
                      {/* <div className="row biz-tags-container mb-4 mb-md-0">
                        {details.Tags.map((item) =>
                          item.TagTitle ? (
                            <span key={item.TId} className="biz-tags">
                              {item.TagTitle}
                            </span>
                          ) : (
                            ""
                          )
                        )}
                      </div> */}
                    </div>
                    {/* laptop size */}
                    <div className="business-card-text3 d-md-flex d-none">
                      <RateIcon
                        cardrate={parseFloat(details.details[0].rate)}
                        isRec={0}
                        isBusiness={1}
                        dir="ltr "
                      />
                      <div>
                        {/* گزارش تا فاز 2 کامنت میشه  */}
                        {/* {cookieState !== "" ? (
                      <div className="ms-md-1 ms-2 business-card-icons-hov business-dropdown mb-0">
                        <img
                          className="business-card-icon3  business-dropbtn"
                          src={reportgray}
                          alt="..."
                        />
                        <div className="business-dropdown-content business-dropdown-content3">
                          <a className=" bdi-lastt">گزارش</a>
                        </div>
                      </div>
                    ) : (
                      <LoginModal
                        url={props.location.pathname}
                        id={1}
                        text={"ثبت گزارش"}
                      />
                    )} */}

                        {cookieState !== "" ? (
                          <div
                            className="ms-1 business-card-icons-hov business-dropdown mb-0"
                            // onClick={saveHandler}
                          >
                            {!saveDisable ? (
                              isSave ? (
                                <img
                                  className="business-card-icon2  business-dropbtn"
                                  src={save1}
                                  alt="..."
                                  onClick={saveHandler}
                                />
                              ) : (
                                <img
                                  className="business-card-icon2  business-dropbtn"
                                  src={save0}
                                  alt="..."
                                  onClick={saveHandler}
                                />
                              )
                            ) : (
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={30}
                                animation="wave"
                                className="biz-icon-skeleton2 d-none  d-md-inline-block"
                              ></Skeleton>
                            )}
                            <div className="business-dropdown-content business-dropdown-content2">
                              <a
                                className=" bdi-lastt"
                                href="#"
                                onClick={!saveDisable ? saveHandler : ""}
                              >
                                ذخیره
                              </a>
                            </div>
                          </div>
                        ) : (
                          <LoginModal
                            url={props.location.pathname}
                            id={2}
                            text={"ذخیره"}
                          />
                        )}

                        <div className=" business-card-icons-hov business-dropdown mb-0">
                          <img
                            className="business-card-icon1 business-dropbtn"
                            src={share}
                            alt="..."
                          />
                          <div className="business-dropdown-content">
                            {shareList.map((item) =>
                              item.title === "Copy Link" ? (
                                <a
                                  onClick={copyToClipboard}
                                  className=""
                                  key={item.icon}
                                >
                                  {item.title}
                                  <img
                                    className="business-drop-img"
                                    // src={link}
                                    src={item.icon}
                                    alt=""
                                  />
                                </a>
                              ) : (
                                <a
                                  className=""
                                  target="_blank"
                                  href={item.shareLink}
                                  key={item.icon}
                                >
                                  {item.title}
                                  <img
                                    className="business-drop-img"
                                    // src={link}
                                    src={item.icon}
                                    alt=""
                                  />
                                </a>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </MDBCardBody>
                ) : (
                  <div className="position-relative height-100">
                    <Skeleton
                      className="skeleton-text1-rec"
                      variant="text"
                      sx={{ fontSize: "1.5rem" }}
                      width={120}
                      animation="wave"
                    />
                    <Skeleton
                      className="skeleton-text2-rec"
                      variant="text"
                      sx={{ fontSize: "0.75rem" }}
                      animation="wave"
                    />
                    <Skeleton
                      className="mt-0"
                      variant="text"
                      sx={{ fontSize: "0.75rem" }}
                      animation="wave"
                    />
                    <Skeleton
                      variant="circular"
                      width={32}
                      height={30}
                      animation="wave"
                      className="biz-icon-skeleton d-none  d-md-inline-block"
                    ></Skeleton>
                    <Skeleton
                      variant="circular"
                      width={32}
                      height={30}
                      animation="wave"
                      className="biz-icon-skeleton2 d-none  d-md-inline-block"
                    ></Skeleton>
                  </div>
                )}
              </div>
            </div>

            {/* for laptop */}
            <div className="d-flex justify-content-between d-none d-md-flex">
              <div className="d-flex">
                {/* فعلا کامنت تا فاز دوم */}
                {/* {cookieState !== "" ? (
                  <Link className="business-upload-btn hov-shadow">
                    آپلود عکس
                    <img
                      className="business-upload-btn-icon"
                      src={pic}
                      alt="pic"
                    />
                  </Link>
                ) : (
                  <LoginModal
                    url={props.location.pathname}
                    id={4}
                    text={"آپلود عکس"}
                  />
                )} */}

                <a
                  className="business-write-btn hov-shadow"
                  onClick={executeScroll}
                >
                  نوشتن دیدگاه
                  <img
                    className="business-write-btn-icon"
                    src={comment}
                    alt="comment"
                  />
                </a>
              </div>
              {/* <div className="d-md-block d-none"> */}
              {/* <EBModal res={false} id={props.match.params.id} /> */}
              {/* <LoginModal url={props.location.pathname} id={61} text={"61"} /> */}

              {/* </div> */}
            </div>
            {/* for laptop */}

            {MTagsCome ? (
              text && (
                <div
                  className={
                    details.details[0].direction == "rtl"
                      ? "business-intro d-md-block d-none"
                      : "business-intro d-md-block d-none font-family-en"
                  }
                  dir={details.details[0].direction}
                >
                  {text.length < 251 ? (
                    // without more or less
                    <p className="">{text}</p>
                  ) : (
                    <div>
                      {
                        <>
                          <p
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html: showLess
                                ? `${text.slice(0, 250)} ...`
                                : `${text.slice(0, 700)}`,
                            }}
                          ></p>
                          <a
                            style={{ color: "#67808f", cursor: "pointer" }}
                            onClick={() => setShowLess(!showLess)}
                          >
                            {showLess ? "بیشتر" : ""}
                          </a>
                        </>
                      }
                    </div>
                  )}
                </div>
              )
            ) : (
              <div
                className="business-intro d-md-block d-none"
                dir={details.details[0].direction}
              >
                {" "}
                <Skeleton
                  className="skeleton-text2-rec"
                  variant="text"
                  sx={{ fontSize: "0.75rem" }}
                  animation="wave"
                />
                <Skeleton
                  className="mt-0"
                  variant="text"
                  sx={{ fontSize: "0.75rem" }}
                  animation="wave"
                />
              </div>
            )}

            {/* for laptop */}
            <div className="row m-0 d-md-flex d-none">
              <div className=" col-md-6 p-0 ps-md-2">
                {MTagsCome ? (
                  <div className="business-map">
                    <div className="business-map-div">
                      <img
                        className="business-map-img"
                        src={address_red}
                        alt=""
                      />
                      {/* <p>{details.details[0].Title} را در نقشه ببینید</p> */}
                      <p>
                        {details.details[0].lat !== "" &&
                        details.details[0].lat !== 0 &&
                        details.details[0].lng !== "" &&
                        details.details[0].lng !== 0 ? (
                          <>
                            این کسب‌و‌کار را
                            <br />
                            در نقشه ببینید
                          </>
                        ) : (
                          <>
                            این کسب‌و‌کار روی نقشه
                            <br />
                            ثبت نشده است
                          </>
                        )}
                      </p>
                    </div>{" "}
                    {/* <iframe
                      className="ifram-bus"
                      src={details.details[0].mapURL}
                      frameborder="0"
                    ></iframe> */}
                    <BizMapModal
                      Add_map={false}
                      dataCome={MTagsCome}
                      lat={details.details[0].lat}
                      lng={details.details[0].lng}
                      Address={details.details[0].address}
                    />
                  </div>
                ) : (
                  <>
                    <Skeleton
                      variant="rectangular"
                      className="business-map radius-2 border-0"
                      animation="wave"
                      height={169}
                    />
                  </>
                )}
              </div>

              <div className=" col-md-6 p-0 pe-md-2">
                {MTagsCome ? (
                  <div className="business-ownership centerr">
                    {/* ادعای مالکیت، فعلا زیرسازشو نداریم بعدا شاید عوض بشه */}
                    {/* <img
                    className="business-ownership-icon"
                    src={key}
                    alt="..."
                  />
                  <a
                    className="business-ownership-btn hov-shadow"
                    href="https://new2.gishniz.link/register-business-step1"
                  >
                    ادعای مالکیت
                  </a>
                  <p className="business-ownership-text ">
                    آیا شما صاحب این کسب‌وکار هستید ؟
                  </p> */}
                    <div className="w-100">
                      <p className="business-ownership-text2">
                        این کسب‌وکار توسط کاربران ثبت شده است
                        <br /> و گیشنیز مسئولیتی نسبت به اطلاعات منتشرشده ندارد.
                      </p>
                      <p className="business-ownership-text3">
                        اطلاعات اشتباه است؟
                      </p>

                      {/* {getCookie("MemId") ? ( */}
                      {cookieState !== "" ? (
                        <EBModal
                          res={false}
                          id={props.match.params.id}
                          BizId={details.details[0].bId}
                        />
                      ) : (
                        <LoginModal
                          url={props.location.pathname}
                          id={5}
                          text={"ویرایش اطلاعات"}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <Skeleton
                      variant="rectangular"
                      className="business-map radius-2 border-0"
                      animation="wave"
                      height={169}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="d-none d-md-block mt-3">
              {/*معرفی به گیشنیز*/}
              <div className="text-align-centerr d-none d-md-block">
                <div className="moarefi d-flex justify-content-around  align-items-center my-0 ">
                  <div className=" fs-17 fw-bold align-self-center text-center ">
                    کسب‌وکار فارسی‌زبان اطرافت می‌شناسی؟
                    <br />
                    به گیشنیز معرفی کن
                  </div>
                  {/* <div className=" align-self-center d-flex justify-content-between"> */}
                  <img
                    className="moarefi-icon"
                    src={presentation}
                    alt="moarefi icon"
                  />

                  <Link
                    className="moarefi-btn align-self-center shadow-off fs-18 color-242424"
                    to="/introduce-business"
                  >
                    برای معرفی کلیک کن{" "}
                  </Link>

                  {/* </div> */}
                </div>
              </div>
              {/* معرفی به گیشنیز سایز موبایل*/}
              <div className="text-align-centerr d-block d-md-none">
                <div className="moarefi d-flex justify-content-between my-0 align-items-center">
                  <div className=" align-self-center text-end text-white alert-m-text">
                    کسب‌وکار فارسی‌زبان اطرافت می‌شناسی؟
                    <br />
                    به گیشنیز معرفی کن
                  </div>

                  <Link
                    className="align-self-center color-242424 alert-m-btn hov-shadow-btn shadow-off"
                    to="/introduce-business"
                  >
                    برای معرفی کلیک کن{" "}
                  </Link>
                </div>
              </div>

              {/* comments  */}
              <div ref={myRef} id="comments">
                {ApiDataCome.bizrate &&
                ApiDataCome.reviewforbiz &&
                userHaveComment !== null ? (
                  <Comments
                    id={props.match.params.id}
                    url={props.location.pathname}
                    data={bizrateData}
                    dataCome={ApiDataCome.bizrate}
                    com={reviewforbiz}
                    comCome={ApiDataCome.reviewforbiz}
                    userHaveComment={userHaveComment}
                  />
                ) : (
                  <>
                    <Skeleton
                      variant="rectangular"
                      className=" mt-3 radius-2"
                      animation="wave"
                      height={92}
                    />
                  </>
                )}
              </div>
              {/* کارت های  */}
              <div className="business-blog">
                <div className="d-flex align-items-end justify-content-between titbox-index-res mb-3">
                  <div className="d-flex align-items-end ">
                    <img className="tit-img" src={blogicon} alt="..." />
                    <a
                      className="color-tit"
                      href={configData.BLOG_URL}
                      target="_blank"
                    >
                      <p className="my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                        مجله گیشنیز
                      </p>
                    </a>
                  </div>
                  <a
                    className="hover-5ae6a2"
                    href={configData.BLOG_URL}
                    target="_blank"
                  >
                    <p className="my-0 fs-14 fs-xs-14 color-7e7e7e hover-5ae6a2">
                      بیشتر
                    </p>
                  </a>
                </div>
                <div className="row justify-content-center">
                  <div className="rec-center col-12 col-md-6 rec-md pe-auto ps-md-2">
                    {/* <RecCard
                    blog={true}
                    title={blog[0].Titr}
                    desc1={blog[0].Lead}
                    rate={"0"}
                    url={blog[0].pageUrl}
                    img={blog[0].FilePath}
                    datacome=
                  /> */}
                    <Rec2Card
                      bus={true}
                      id={blog[0].id}
                      img={
                        blog[0]._embedded["wp:featuredmedia"]["0"].media_details
                          .sizes.medium_large.source_url
                      }
                      title={blog[0].title.rendered}
                      desc={blog[0].excerpt.rendered}
                      url={blog[0].link}
                      datacome={ApiDataCome.blogforbiz}
                    />
                  </div>
                  <div className="rec-center col-12 col-md-6 rec-md mt-xs-13 mt-sm-0 ps-auto pe-md-2">
                    <Rec2Card
                      bus={true}
                      id={blog[1].id}
                      img={
                        blog[1]._embedded["wp:featuredmedia"]["0"].media_details
                          .sizes.medium_large.source_url
                      }
                      title={blog[1].title.rendered}
                      desc={blog[1].excerpt.rendered}
                      url={blog[1].link}
                      datacome={ApiDataCome.blogforbiz}
                    />
                  </div>
                  <div className="rec-center col-12 col-md-6 rec-md mt-xs-13 mt-md-3 ps-auto ps-md-2">
                    <Rec2Card
                      bus={true}
                      id={blog[2].id}
                      img={
                        blog[2]._embedded["wp:featuredmedia"]["0"].media_details
                          .sizes.medium_large.source_url
                      }
                      title={blog[2].title.rendered}
                      desc={blog[2].excerpt.rendered}
                      url={blog[2].link}
                      datacome={ApiDataCome.blogforbiz}
                    />
                  </div>
                  <div className="rec-center col-12 col-md-6 rec-md mt-xs-13 mt-md-3 ps-auto pe-md-2 ">
                    <Rec2Card
                      bus={true}
                      id={blog[3].id}
                      img={
                        blog[3]._embedded["wp:featuredmedia"]["0"].media_details
                          .sizes.medium_large.source_url
                      }
                      title={blog[3].title.rendered}
                      desc={blog[3].excerpt.rendered}
                      url={blog[3].link}
                      datacome={ApiDataCome.blogforbiz}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* left side */}

          <div className="pe-md-2 col-12 col-md-4 business-padding ">
            {/* <div className="business-facility-fix"> */}
            <div className="">
              {MTagsCome ? (
                <div className="business-information ">
                  <div className="business-information-1" dir="ltr">
                    <div className="mb-0 d-flex">
                      <img
                        className="business-information-icon"
                        src={address}
                        alt="address"
                      />

                      {details.details[0].address == null ||
                      details.details[0].address == "" ? (
                        <div className="business-information-no-address">
                          اگر آدرسی از این کسب‌وکار می‌دانید پیشنهاد دهید
                        </div>
                      ) : (
                        <>
                          <div className="d-none d-md-block">
                            <BizMapModal
                              Add_map={true}
                              dataCome={MTagsCome}
                              lat={details.details[0].lat}
                              lng={details.details[0].lng}
                              Address={details.details[0].address}
                            />
                          </div>

                          <div className="d-md-none">
                            <BizMapModalMobile
                              Add_map={true}
                              dataCome={MTagsCome}
                              lat={details.details[0].lat}
                              lng={details.details[0].lng}
                              Address={details.details[0].address}
                              Title={details.details[0].title}
                            />
                          </div>
                          {/* // <a href="" className="business-information-text">
                        //   {details.details[0].address}
                        //   <img
                        //     className="business-information-img"
                        //     src={arrow}
                        //     alt=""
                        //   />
                        // </a> */}
                        </>
                      )}
                    </div>
                  </div>

                  <BusinessAccordion data={details} />
                </div>
              ) : (
                <div className="bg-f7f7f7">
                  <Skeleton
                    variant="rectangular"
                    className=" mt-2 mt-md-0 radius-2 d-none d-md-block"
                    animation="wave"
                    height={205}
                  />

                  <Skeleton
                    className="skeleton-text2-rec d-md-none biz-detail-res-skeleton"
                    variant="text"
                    sx={{ fontSize: "0.75rem" }}
                    animation="wave"
                  />
                  <Skeleton
                    className="skeleton-text2-rec d-md-none biz-detail-res-skeleton"
                    variant="text"
                    sx={{ fontSize: "0.75rem" }}
                    animation="wave"
                  />
                </div>
              )}

              {/*  */}
              {/* {emkanat.length ? (
                <div className="business-facility ">
                  <p className="business-facility-title">امکانات</p>
                  //  comment 
                  // {emkanat &&
                  // emkanat.map((item) => (
                  //   <p className="business-facility-text" key={item.COTId}>
                  //     <img
                  //       className="business-facility-icon"
                  //       src={item.Icon}
                  //       alt={item.Title}
                  //     />
                  //     {console.log(item)}
                  //     {item.Title}
                  //   </p>
                  // ))}

                  {emkanat.map((item) => (
                    <div key={item.COTId} className="result-filter-item">
                      <label className="container-s2 m-0 me-0 mb-0 d-inline-flex cursor-auto">
                        // <input type="checkbox" checked={item.IsSelected} />
                        // <span className="checkmark-s2 back-white"></span>
                        <Checkbox
                          checked={item.IsSelected}
                          disableRipple
                          size="small"
                          sx={{
                            color: "#33E0A0",
                            "&.Mui-checked": {
                              color: "#33E0A0",
                            },
                          }}
                        />
                        <span>{item.Title}</span>
                      </label>
                    </div>
                  ))}
                                    
                // <p className="business-facility-text">
                //   <img
                //     className="business-facility-icon"
                //     src={family}
                //     alt="family"
                //   />{" "}
                //   مناسب برای کودکان و خانواده{" "}
                // </p>
               
                </div>
              ) : (
                <></>
              )} */}
              {/*  shive pardakht*/}
              {/* {payments.length ? (
                <div className="business-facility ">
                  <p className="business-facility-title">روش پرداخت</p>
                  {payments.length ? (
                    payments.map((item) => (
                      <div key={item.COTId} className="result-filter-item">
                        <label className="container-s2 m-0 me-0 mb-0 d-inline-flex cursor-auto">
                          
                        // <input type="checkbox" checked={item.IsSelected} />
                          // <span className="checkmark-s2 back-white"></span>
                          <Checkbox
                            checked={item.IsSelected}
                            disableRipple
                            size="small"
                            sx={{
                              color: "#33E0A0",
                              "&.Mui-checked": {
                                color: "#33E0A0",
                              },
                            }}
                          />
                          <span>{item.Title}</span>
                        </label>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )} */}
            </div>
            <div className="d-md-block d-none mt-3">
              {adds.length > 0 ? (
                adds.map((item) => (
                  <div className="tabliqat-box mb-3" key={item.title}>
                    <a href={item.urlTarget} target="_blank" rel={item.relType}>
                      <LazyLoadImage
                        // PlaceholderSrc={
                        //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
                        // }
                        src={item.fileAddress}
                        alt={item.title}
                      />
                    </a>
                  </div>
                ))
              ) : (
                <>
                  <Skeleton
                    variant="rectangular"
                    className=" mt-2 mt-md-0 radius-2"
                    animation="wave"
                    height={89}
                  />
                </>
              )}
            </div>
          </div>

          {/* for responsive */}
          <div className="col-12">
            <div
              dir={details.details[0].direction}
              className={
                MTagsCome
                  ? "business-intro  d-md-none"
                  : "business-intro d-block  d-md-none"
              }
            >
              {MTagsCome ? (
                <>
                  {text && text.length < 251 ? (
                    // without more or less
                    <p className="">{text}</p>
                  ) : (
                    <div>
                      {text && (
                        <>
                          <p
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html: showLess
                                ? `${text.slice(0, 250)} ...`
                                : `${text.slice(0, 700)}`,
                            }}
                          ></p>
                          <a
                            style={{ color: "#67808f", cursor: "pointer" }}
                            onClick={() => setShowLess(!showLess)}
                          >
                            {showLess ? "بیشتر" : ""}
                          </a>
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Skeleton
                    className="skeleton-text2-rec"
                    variant="text"
                    sx={{ fontSize: "0.75rem" }}
                    animation="wave"
                  />
                  <Skeleton
                    className="mt-0"
                    variant="text"
                    sx={{ fontSize: "0.75rem" }}
                    animation="wave"
                  />
                </>
              )}
            </div>
          </div>
          {/* for responsive */}
          <div className="row m-0 d-md-none">
            <div className=" col-md-6 p-0 ps-md-2">
              {MTagsCome ? (
                <div className="business-map">
                  <div className="business-map-div">
                    <img
                      className="business-map-img"
                      src={address_red}
                      alt=""
                    />
                    <p>
                      {details.details[0].lat !== "" &&
                      details.details[0].lat !== 0 &&
                      details.details[0].lng !== "" &&
                      details.details[0].lng !== 0 ? (
                        <>
                          این کسب‌و‌کار را
                          <br />
                          در نقشه ببینید
                        </>
                      ) : (
                        <>
                          این کسب‌و‌کار روی نقشه
                          <br />
                          ثبت نشده است
                        </>
                      )}
                    </p>
                  </div>{" "}
                  {/* <iframe
                    className="ifram-bus"
                    src={details.details[0].mapURL}
                    frameborder="0"
                  ></iframe> */}
                  <BizMapModalMobile
                    Add_map={false}
                    dataCome={MTagsCome}
                    lat={details.details[0].lat}
                    lng={details.details[0].lng}
                    Address={details.details[0].address}
                    Title={details.details[0].title}
                  />
                </div>
              ) : (
                <>
                  <Skeleton
                    variant="rectangular"
                    className="business-map radius-2 border-0"
                    animation="wave"
                    height={100}
                  />
                </>
              )}
            </div>
            <div className=" col-md-6 p-0 pe-md-2">
              {MTagsCome ? (
                <div className="business-ownership centerr">
                  <div className="w-100">
                    <p className="business-ownership-text2">
                      این کسب‌وکار توسط کاربران ثبت شده است
                      <br /> و گیشنیز مسئولیتی نسبت به اطلاعات منتشرشده ندارد.
                    </p>
                    <p className="business-ownership-text3">
                      اطلاعات اشتباه است؟
                    </p>
                    {/* {getCookie("MemId") ? ( */}
                    {cookieState !== "" ? (
                      <EBModalM
                        id={props.match.params.id}
                        BizId={details.details[0].bId}
                      />
                    ) : (
                      <LoginModal
                        url={props.location.pathname}
                        id={5}
                        text={"ویرایش اطلاعات"}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <Skeleton
                    variant="rectangular"
                    className="business-map radius-2 border-0"
                    animation="wave"
                    height={100}
                  />
                </>
              )}
            </div>
          </div>

          {/* 444444444444444 */}

          {/* <div className="ps-md-2 col-12 col-md-8 business-padding">


            <div className="row m-0">
              <div className=" col-md-6 p-0 ps-md-2">
                <div className="business-ownership">معرفی</div>
              </div>
              <div className=" col-md-6 p-0 pe-md-2">
                <div className="business-ownership centerr">
                  <img
                    className="business-ownership-icon"
                    src={key}
                    alt="..."
                  />
                  <a className="business-ownership-btn hov-shadow" href="">
                    ادعای مالکیت
                  </a>
                  <p className="business-ownership-text ">
                    آیا شما صاحب این کسب‌وکار هستید ؟
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          <div className="ps-md-2 col-12 col-md-8 mt-3 d-md-none">
            {/*معرفی به گیشنیز*/}
            <div className="text-align-centerr d-none d-md-block">
              <div className="moarefi d-flex justify-content-around  align-items-center my-0 ">
                <div className=" fs-17 fw-bold align-self-center text-center ">
                  کسب‌وکار فارسی‌زبان اطرافت می‌شناسی؟
                  <br />
                  به گیشنیز معرفی کن
                </div>
                {/* <div className=" align-self-center d-flex justify-content-between"> */}
                <img
                  className="moarefi-icon"
                  src={presentation}
                  alt="moarefi icon"
                />

                <Link
                  className="moarefi-btn align-self-center shadow-off fs-18 color-242424"
                  to="/introduce-business"
                >
                  برای معرفی کلیک کن{" "}
                </Link>

                {/* </div> */}
              </div>
            </div>
            {/* معرفی به گیشنیز سایز موبایل*/}
            <div className="text-align-centerr d-block d-md-none">
              <div className="moarefi d-flex justify-content-between my-0 align-items-center">
                <div className=" align-self-center text-end text-white alert-m-text">
                  کسب‌وکار فارسی‌زبان اطرافت می‌شناسی؟
                  <br />
                  به گیشنیز معرفی کن
                </div>

                <Link
                  className="align-self-center color-242424 alert-m-btn hov-shadow-btn shadow-off"
                  to="/introduce-business"
                >
                  برای معرفی کلیک کن{" "}
                </Link>
              </div>
            </div>

            {/* comments  */}
            <div ref={myRef2} id="comments">
              {ApiDataCome.bizrate &&
              ApiDataCome.reviewforbiz &&
              userHaveComment !== null ? (
                <Comments
                  id={props.match.params.id}
                  url={props.location.pathname}
                  data={bizrateData}
                  dataCome={ApiDataCome.bizrate}
                  com={reviewforbiz}
                  comCome={ApiDataCome.reviewforbiz}
                  userHaveComment={userHaveComment}
                />
              ) : (
                <>
                  <Skeleton
                    variant="rectangular"
                    className=" mt-3 radius-2"
                    animation="wave"
                    height={92}
                  />
                </>
              )}
            </div>
            {/* tablig responsive vasati */}
            {/* <div className="centerr">
              <div className="tabliqat-box mb-0 d-block d-md-none">
              <Link to="/">
                <LazyLoadImage
            PlaceholderSrc={
              "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
            } src={tabliq1} alt="..." />
                </Link>
              </div>
            </div> */}

            {/* کارت های مجله گیشنیز */}
            <div className="business-blog">
              <div className="d-flex align-items-end justify-content-between titbox-index-res mb-3">
                <div className="d-flex align-items-end ">
                  <img className="tit-img" src={blogicon} alt="..." />
                  <a
                    className="color-tit"
                    href={configData.BLOG_URL}
                    target="_blank"
                  >
                    <p className="my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                      مجله گیشنیز
                    </p>
                  </a>
                </div>
                <a
                  className="hover-5ae6a2"
                  href={configData.BLOG_URL}
                  target="_blank"
                >
                  <p className="my-0 fs-14 fs-xs-14 color-7e7e7e hover-5ae6a2">
                    بیشتر
                  </p>
                </a>
              </div>
              <div className="row justify-content-center">
                <div className="rec-center col-12 col-md-6 rec-md pe-auto ps-md-2">
                  {/* <RecCard
                    blog={true}
                    title={blog[0].Titr}
                    desc1={blog[0].Lead}
                    rate={"0"}
                    url={blog[0].pageUrl}
                    img={blog[0].FilePath}
                  /> */}
                  <Rec2Card
                    bus={true}
                    id={blog[0].id}
                    img={
                      blog[0]._embedded["wp:featuredmedia"]["0"].media_details
                        .sizes.medium_large.source_url
                    }
                    title={blog[0].title.rendered}
                    desc={blog[0].excerpt.rendered}
                    url={blog[0].link}
                    datacome={ApiDataCome.blogforbiz}
                  />
                </div>
                <div className="rec-center col-12 col-md-6 rec-md mt-xs-13 mt-sm-0 ps-auto pe-md-2">
                  <Rec2Card
                    bus={true}
                    id={blog[1].id}
                    img={
                      blog[1]._embedded["wp:featuredmedia"]["0"].media_details
                        .sizes.medium_large.source_url
                    }
                    title={blog[1].title.rendered}
                    desc={blog[1].excerpt.rendered}
                    url={blog[1].link}
                    datacome={ApiDataCome.blogforbiz}
                  />
                </div>
                <div className="rec-center col-12 col-md-6 rec-md mt-xs-13 mt-md-3 ps-auto ps-md-2">
                  <Rec2Card
                    bus={true}
                    id={blog[2].id}
                    img={
                      blog[2]._embedded["wp:featuredmedia"]["0"].media_details
                        .sizes.medium_large.source_url
                    }
                    title={blog[2].title.rendered}
                    desc={blog[2].excerpt.rendered}
                    url={blog[2].link}
                    datacome={ApiDataCome.blogforbiz}
                  />
                </div>
                <div className="rec-center col-12 col-md-6 rec-md mt-xs-13 mt-md-3 ps-auto pe-md-2 ">
                  <Rec2Card
                    bus={true}
                    id={blog[3].id}
                    img={
                      blog[3]._embedded["wp:featuredmedia"]["0"].media_details
                        .sizes.medium_large.source_url
                    }
                    title={blog[3].title.rendered}
                    desc={blog[3].excerpt.rendered}
                    url={blog[3].link}
                    datacome={ApiDataCome.blogforbiz}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* changed tarh */}
          <div className="pe-md-2 col-12 col-md-4 mt-md-3 centerr d-md-none">
            {adds.length > 0 ? (
              adds.map((item) => (
                <div className="tabliqat-box mb-3" key={item.title}>
                  <a href={item.urlTarget} target="_blank" rel={item.relType}>
                    <LazyLoadImage
                      // PlaceholderSrc={
                      //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
                      // }
                      src={item.fileAddress}
                      alt={item.title}
                    />
                  </a>
                </div>
              ))
            ) : (
              <>
                <Skeleton
                  variant="rectangular"
                  className=" mt-0 radius-2"
                  animation="wave"
                  height={89}
                />
              </>
            )}
          </div>
          <div className="col-12 col-md-8 "></div>
          <div className="pe-md-2 col-12 col-md-4 centerr"></div>
        </div>
      </div>
      {/* اسلایدر مکان‌های مشابه */}
      <div className="container ps-sm-0 p-xs-0">
        <div className="mb-40 mb-xs-12">
          <div className="d-flex align-items-end justify-content-between position-relative titbox-index-res px-1 mb-0">
            <div className="d-flex align-items-end business-makan-position">
              <object
                data={latestbiz.sectionTitle.icon}
                alt={latestbiz.sectionTitle.title}
                className="tit-img"
                type={latestbiz.sectionTitle.type}
              ></object>
              <a className="color-tit" href={latestbiz.sectionTitle.url}>
                <p className="color-tit2 my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                  {/* کسب‌وکارهای مشابه */}
                  {latestbiz.sectionTitle.title}
                </p>
              </a>
            </div>
          </div>
          {/* <CardCarousel withRate={true} /> */}
          <div className="d-none d-md-block">
            <CardCarousel
              data={latestbiz.businesses}
              withRate={true}
              datacome={ApiDataCome.similarforbiz}
            />
          </div>
          <div className="d-block d-md-none">
            <ScrollCategory
              data={latestbiz.businesses}
              isCarousel={true}
              withRate={true}
              datacome={ApiDataCome.similarforbiz}
            />
          </div>
        </div>
      </div>
      <Footer data={footerData} dataCome={ApiDataCome.footer} />
      {/* alert delete */}
      <AlertSuccess
        showAlert={showAlert}
        text={"The link has been copied to your clipboard."}
      />
    </>
  );
};

export default BusinessPage;
