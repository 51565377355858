import { MDBCol } from "mdb-react-ui-kit";
import React from "react";
import "./main.css";
const Main = (props) => {

  return (
    <>
      <div  className={ ( props.lenmessage < 4 || props.lencomments < 3) ? "bg-main" : "more2"}>
          {props.children}
      </div>
    </>
  );
};

export default Main;
