import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { MDBContainer } from "mdb-react-ui-kit";
//
import { DomainContext, LangIdContext } from "../../App";
// config
import configData from "../../config/config.json";
// components
import Header2 from "../../components/header/Header2";
import Headermobile from "../../components/header-mobile/Headermobile";
import Footer from "../../components/footer/Footer";
import MetaTagsInfoPage from "../../components/meta-tags-seo/MetaTagsInfoPage";
import NotFound from "../NotFound/NotFound";

const Privacy = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [ApiDataCome, setApiDataCome] = useState({
    headerbiz: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    headerLogo: {
      title: "",
      link: "",
    },
  });

  const [pageContent, setPageContent] = React.useState("");

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: getCookie("_gapp_LId"),
    FooterId: props.match.params.id,
  };
  useEffect(() => {
    // 1 page content
    axios
      // .get(domain + "/api/footer/menu-content", { headers: header })
      .get(
        configData.API_DOMAIN +
          `/api/v1/Contents?FooterId=${props.match.params.id}`,
        {
          headers: header,
        }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setMTagsCome(true);
          setMetaData(response.data.data.metaTag);
          setPageContent(response.data.data.itemContent);
        } else {
          setPageContent("0");
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 2 header-other
    axios
      .get(configData.API_DOMAIN + "/api/v1/Headers/other", {
        headers: header,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setheaderData(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            headerbiz: true,
          }));
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 3 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  //getCookie
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <>
      {pageContent === "0" ? (
        <>
          <NotFound />
        </>
      ) : (
        <>
          {MTagsCome ? <MetaTagsInfoPage data={MetaData} /> : ""}

          <div className="d-none d-md-block">
            <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
          </div>
          <div className="d-block d-md-none">
            <div className="fixed-header">
              <Headermobile data={headerData} />
            </div>
            <div className="box-under-header-res"></div>
          </div>
          <div className="gish-page-back">
            <MDBContainer>
              <div dangerouslySetInnerHTML={{ __html: pageContent }} />
            </MDBContainer>
            {/* <div className="gish-pri-tit">
          سیاست حفظ اطلاعات و <span className="color-5ae6a2">حریم خصوصی</span>
        </div>
        <div className="gish-pri-body">
          گیشنیز با تاکید بر احترامی که برای حریم شخصی کاربران قائل است، برای
          سفارش، ثبت دیدگاه یا استفاده از برخی امکانات وبسایت اطلاعاتی را از
          کاربران درخواست می‌کند تا بتواند خدماتی امن و مطمئن ارائه دهد. برای
          نمونه می توان به نام، نشانی، نشانی الکترونیک (ایمیل)، شماره تلفن و
          سایر اطلاعاتی که به صورت عادی در دسترس عموم مردم نیست اشاره نمود. لازم
          به ذکر است آدرس ایمیل و تلفن‌هایی که مشتری در پروفایل خود ثبت می‌کند،
          تنها آدرس ایمیل و تلفن‌های رسمی و مورد تایید مشتری است و تمام مکاتبات
          و پاسخ‌های شرکت از طریق آنها صورت می‌گیرد.
          <br />
          <br />
          شما با ثبت نام در اسنپ فود مشخصات خود از قبیل نام و نام خانوادگی، تلفن
          تماس و … را در اختیار اسنپ فود قرار می دهید. در ادامه برای انجام
          تراکنش و ثبت سفارش نیاز به اضافه کردن مشخصات آدرس خود خواهید داشت. شما
          پس از ثبت نام در وبسایت، به عنوان کاربر اسنپ فود شناخته خواهید شد و کلیه
          اطلاعات مذکور و اطلاعاتی مانند تراکنش ها، پرداخت ها و... نیز در اختیار
          اسنپ فود خواهد بود.
          <br />
          <br />
          باید تاکید شود که حفظ و نگهداری رمز عبور و نام کاربری بر عهده کاربران
          است و لذا برای جلوگیری از هرگونه سوء استفاده احتمالی، کاربران نباید آن
          اطلاعات را برای شخص دیگری فاش کنند. در صورتی که کاربر شماره همراه خود
          را به فردی دیگر واگذار کرد، جهت جلوگیری از سواستفاده یا مشکلات احتمالی
          کاربران باید شماره موبایل خود را در پروفایل تغییر داده و شماره جدیدی
          ثبت نمایند. مسئولیت هرگونه خسارت احتمالی که ناشی از عدم رعایت موارد
          فوق الذکر توسط کاربر باشد، با خود کاربر بوده وکاربر حق هر گونه ادعا
          علیه اسنپ فود را از خود سلب میکند. اسنپ فود هویت شخصی کاربران را
          محرمانه می‌داند و اطلاعات شخصی آنان را به هیچ شخص یا سازمان دیگری
          منتقل نمی‌کند، مگر اینکه با حکم مقام قضائی یا طبق قوانین و مقررات رایج
          کشور موظف باشد در اختیار مراجع ذی‌صلاح قرار دهد.در این موارد هیچ گونه
          مسئولیت قانونی مبنی بر جبران خسارت برای اسنپ فود وجود ندارد و کاربران
          با اعلام رضایت خود در استفاده از خدمات وبسایت ضمنا حق هرگونه اعتراض
          را از خود سلب نموده اند.
          <br />
          سنپ فود برای حفاظت و نگهداری اطلاعات و حریم شخصی کاربران همه توان خود
          را به کار می‌گیرد و امیدوار است که تجربه‌ی سفارشی امن، راحت و خوشایند
          را برای همه کاربران فراهم آورد.
          <br />
          در صورت وجود هرگونه سوال٬ لطفا با اطلاعات تماس زیر ارتباط برقرار کنید.
          <br />
          <br />
          تلفن تماس: ۴۹۲۷۸۰۰۰ (۰۲۱) - ۹۶۶۱۲ (۰۲۱)
          <br />
          ایمیل : info@snappfood.ir
          <br />
          درس : تهران، جردن، بالاتر از اسفندیار، خ سعیدی،نبش تقاطع خ مهرداد،پلاک
          ۱، طبقه ۵، واحد ۱۰
        </div> */}
          </div>
          <Footer data={footerData} dataCome={ApiDataCome.footer} />
        </>
      )}
    </>
  );
};

export default Privacy;
