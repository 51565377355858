import React from "react";
import { Helmet } from "react-helmet";
// config
import configData from "../../config/config.json";

const MetaTagsSearchPage = (props) => {
  const { data } = props;
  return (
    <div className="wrapper">
      <Helmet defer={false}>
        <title>{data.title}</title>

        {/* search */}
        <meta name="robots" content="index,follow" />
        <meta name="description" content={data.description} />
        <meta name="keywords" content={data.keywords} />
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.description} />
        <meta property="og:url" content={configData.SITE_URL} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={data.image} />
        <meta property="og:image:alt" content={data.title} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />
        <meta property="og:site_name" content={data.title} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@GishnizOfficial" />
        <meta property="twitter:title" content={data.title} />
        <meta property="twitter:description" content={data.description} />
        <link rel="canonical" href={data.canonical} />
        {/* search */}

        {/* All pages */}
        <link
          rel="apple-touch-icon"
          href={`${configData.SITE_URL}/images/favicon/apple-icon-57x57.png`}
          size="57x57"
        />
        <link
          rel="apple-touch-icon"
          href={`${configData.SITE_URL}/images/favicon/apple-icon-60x60.png`}
          size="60x60"
        />
        <link
          rel="apple-touch-icon"
          href={`${configData.SITE_URL}/images/favicon/apple-icon-72x72.png`}
          size="72x72"
        />
        <link
          rel="apple-touch-icon"
          href={`${configData.SITE_URL}/images/favicon/apple-icon-76x76.png`}
          size="76x76"
        />
        <link
          rel="apple-touch-icon"
          href={`${configData.SITE_URL}/images/favicon/apple-icon-114x114.png`}
          size="114x114"
        />
        <link
          rel="apple-touch-icon"
          href={`${configData.SITE_URL}/images/favicon/apple-icon-120x120.png`}
          size="120x120"
        />
        <link
          rel="apple-touch-icon"
          href={`${configData.SITE_URL}/images/favicon/apple-icon-144x144.png`}
          size="144x144"
        />
        <link
          rel="apple-touch-icon"
          href={`${configData.SITE_URL}/images/favicon/apple-icon-152x152.png`}
          size="152x152"
        />
        <link
          rel="apple-touch-icon"
          href={`${configData.SITE_URL}/images/favicon/apple-icon-180x180.png`}
          size="180x180"
        />
        <link
          rel="icon"
          href={`${configData.SITE_URL}/images/favicon/android-icon-192x192.png`}
          size="192x192"
        />
        <link
          rel="icon"
          href={`${configData.SITE_URL}/images/favicon/favicon-32x32.png`}
          size="32x32"
        />
        <link
          rel="icon"
          href={`${configData.SITE_URL}/images/favicon/favicon-96x96.png`}
          size="96x96"
        />
        <link
          rel="icon"
          href={`${configData.SITE_URL}/images/favicon/favicon-16x16.png`}
          size="16x16"
        />
        <link
          rel="manifest"
          href={`${configData.SITE_URL}/images/favicon/manifest.json`}
          size=""
        />
        <meta name="msapplication-TileColor" content="#48e398" />
        <meta
          name="msapplication-TileImage"
          content={`${configData.SITE_URL}/images/favicon/ms-icon-144x144.png`}
        />
        <meta name="theme-color" content="#48e398" />
        {/* All pages */}
      </Helmet>
    </div>
  );
};

export default MetaTagsSearchPage;
