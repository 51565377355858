import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

import Header from "../components/header/Header";
import Headermobile from "../components/header-mobile/Headermobile";
import HomeBody from "../components/home-body/HomeBody";
import Footer from "../components/footer/Footer";
import Listmobile from "../components/list-mobile/Listmobile";
import MoarefiAlert from "../components/moarefi-alert/MoarefiAlert";
import MetaTagsIndexPgae from "../components/meta-tags-seo/MetaTagsIndexPage";

import { DomainContext, LangIdContext } from "../App";
// config
import configData from "../config/config.json";

const HomePage = () => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [dataCome, setDataCome] = useState(false);

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  const [data, setData] = useState({
    headerLogo: {},
    categoriesForSearch: [
      {
        cId: "",
        title: "",
        shortTitle: "",
        icon: "",
      },
    ],
    coverPhoto: "",
    categoriesOnHeader: [
      {
        cId: 1,
      },
      {
        cId: 2,
      },
      {
        cId: 3,
      },
      {
        cId: 4,
      },
      {
        cId: 5,
      },
    ],
    categoriesOnHeaderShort: [{}, {}, {}, {}, {}, {}, {}, {}],
  });

  const [ApiDataCome, setApiDataCome] = useState({
    header: false,
    footer: false,
    nearbybiz: false,
    latestbiz: false,
    paidbiz: false,
    bizrandomcat: false,
    categories: false,
    blogposts: false,
    currencies: false,
    cryptos: false,
  });

  const [footerData, setFooterData] = useState({});
  const [nearbybiz, setNearbybiz] = useState([]);
  const [latestbiz, setLatestbiz] = useState([]);
  const [paidbiz, setPaidbiz] = useState([]);
  const [bizrandomTit, setBizrandomTit] = useState({});
  const [bizrandomcat, setBizrandomcat] = useState({});
  const [categories, setCategories] = useState([]);
  const [blogposts, setBlogposts] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [cryptos, setCryptos] = useState([]);

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    // LangId: ApiDataCome.footer ? footerData.LanguageList[0].LId : "1",
    LangId: langIdState,
  };

  useEffect(() => {
    setDataCome(false);
    // 3 footer
    axios
      // .get(domain + "/api/footer", { headers: header })
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  useEffect(() => {
    setDataCome(false);

    if (ApiDataCome.footer) {
      //1 Meta Tags Api
      axios
        // .get(domain + "/api/meta-index", { headers: header })
        .get(configData.API_DOMAIN + "/api/v1/MetaTags/index", {
          headers: header,
        })
        .then((response) => {
          setMTagsCome(true);
          setMetaData(response.data.data);
        })
        .catch((error) => {
          // alert(error);
        });

      //2 header
      axios
        // .get(domain + "/api/header", { headers: header })
        .get(configData.API_DOMAIN + "/api/v1/Headers", {
          headers: header,
        })
        .then((response) => {
          setData(response.data.data);
          // setDataCome(true);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, header: true }));
        })
        .catch((error) => {
          // alert(error);
        });

      //4 nearbybiz
      axios
        // .get(domain + "/api/nearbybiz", { headers: header })
        // .get(domain + "/api/most-viewest", { headers: header })
        .get(configData.API_DOMAIN + "/api/v1/Businesses/most-viewed", {
          headers: header,
        })
        .then((response) => {
          if ((response.data.msg = "done")) {
            setNearbybiz(response.data.data);
            setApiDataCome((ApiDataCome) => ({
              ...ApiDataCome,
              nearbybiz: true,
            }));
          }
        })
        .catch((error) => {
          // alert(error);
        });

      // 5 latestbiz
      axios
        // .get(domain + "/api/latestbiz", { headers: header })
        .get(configData.API_DOMAIN + "/api/v1/Businesses/latest", {
          headers: header,
        })
        .then((response) => {
          setLatestbiz(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            latestbiz: true,
          }));
        })
        .catch((error) => {
          // alert(error);
        });

      // 6 paidbiz
      axios
        // .get(domain + "/api/most-rated", { headers: header })
        .get(configData.API_DOMAIN + "/api/v1/Businesses/most-rated", {
          headers: header,
        })
        .then((response) => {
          setPaidbiz(response.data.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, paidbiz: true }));
        })
        .catch((error) => {
          // alert(error);
        });

      // 7 bizrandomcat
      axios
        // .get(domain + "/api/bizrandomcat", { headers: header })
        .get(configData.API_DOMAIN + "/api/v1/Businesses/random", {
          headers: header,
        })
        .then((response) => {
          // if ((response.msg = "done")) {
          setBizrandomcat(response.data.data);
          // setBizrandomTit(response.data.SectionTitle.T            );
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            bizrandomcat: true,
          }));
          // }
        })
        .catch((error) => {
          // alert(error);
        });

      // 8 categories
      axios
        // .get(domain + "/api/categories", { headers: header })
        .get(configData.API_DOMAIN + "/api/v1/Categories/parent", {
          headers: header,
        })
        .then((response) => {
          setCategories(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            categories: true,
          }));
        })
        .catch((error) => {
          // alert(error);
        });

      // 9 blogposts
      axios
        .get(
          configData.BLOG_API_DOMAIN +
            "/wp-json/wp/v2/posts?_embed&per_page=4&lang=fa"
        )
        // .get(configData.API_DOMAIN + "/api/v1/BlogPosts/latest", {
        //   headers: header,
        // })
        .then((response) => {
          setBlogposts(response.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            blogposts: true,
          }));
        })
        .catch((error) => {
          // alert(error);
        });

      // 10 currencies
      axios
        // .get(domain + "/api/currencies", { headers: header })
        .get(configData.API_DOMAIN + "/api/v1/Currencies", {
          headers: header,
        })
        .then((response) => {
          setCurrencies(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            currencies: true,
          }));
        })
        .catch((error) => {
          // alert(error);
        });

      // 11 cryptos
      axios
        // .get(domain + "/api/cryptos", { headers: header })
        .get(configData.API_DOMAIN + "/api/v1/Currencies/cryptos", {
          headers: header,
        })
        .then((response) => {
          setCryptos(response.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, cryptos: true }));
        })
        .catch((error) => {
          // alert(error);
        });
    }
  }, [ApiDataCome.footer]);

  return (
    <>
      {/* {MTagsCome ? <MetaTagsSeo data={MetaData} /> : ""} */}
      {MTagsCome ? <MetaTagsIndexPgae data={MetaData} /> : ""}

      <div className="d-none d-md-block">
        <Header data={data} dataCome={ApiDataCome.header} />
      </div>
      <div className="d-block d-md-none">
        <div className="fixed-header">
          <Headermobile data={data} />
        </div>
        <div className="box-under-header-res"></div>

        <Listmobile
          data={data.categoriesOnHeaderShort}
          dataCome={ApiDataCome.header}
        />
        <div className="centerr">
          <a
            // href={`${configData.BIZ_URL}/choose-owner`}
            href={`${configData.BIZ_URL}/signup`}
            target="_blank"
            className="sabt-btn-a  mb-sabt-mobile sabt-mobile-btn hov-shadow-btn-dark shadow-off"
          >
            ثبت کسب‌وکار
          </a>
        </div>
        <MoarefiAlert />
      </div>
      <HomeBody
        nearbybiz={nearbybiz}
        nearbybizCome={ApiDataCome.nearbybiz}
        latestbiz={latestbiz}
        latestbizCome={ApiDataCome.latestbiz}
        paidbiz={paidbiz}
        paidbizCome={ApiDataCome.paidbiz}
        bizrandomTit={bizrandomTit}
        bizrandomcat={bizrandomcat}
        bizrandomcatCome={ApiDataCome.bizrandomcat}
        categories={categories}
        categoriesCome={ApiDataCome.categories}
        blogposts={blogposts}
        blogpostsCome={ApiDataCome.blogposts}
        currencies={currencies}
        currenciesCome={ApiDataCome.currencies}
        cryptos={cryptos}
        cryptosCome={ApiDataCome.cryptos}
      />
      <Footer data={footerData} dataCome={ApiDataCome.footer} />
    </>
  );
};

export default HomePage;
