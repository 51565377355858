import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
//
import { DomainContext, LangIdContext } from "../../../App";
// config
import configData from "../../../config/config.json";
//components
import MetaTagsEndUserPage from "../../../components/meta-tags-seo/MetaTagsEndUserPage";
import Sidbar from "../../../components/Dashbord/sidbar/Sidbar";
import Main from "../../../components/Dashbord/Main/Main";
import close from "../../../images/end user/close.svg";
import Header_mobile from "../../../components/Dashbord/Header_mobile/Header_mobile";
// style
import "../indexprofile.css";

const Exit = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [basicModal, setBasicModal] = useState(true);
  const toggleShow = () => setBasicModal(!basicModal);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const exitHandler = (event) => {
    event.preventDefault();
    document.cookie = "MemId=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    props.history.push({
      pathname: "/login",
    });
  };

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //api header
  const headerMeta = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Page: "exit",
  };

  useEffect(() => {
    if (getCookie("MemId")) {
      axios
        // .get(domain + "/api/member/meta-enduser", { headers: headerMeta })
        .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
          headers: headerMeta,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setMTagsCome(true);
            setMetaData(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      props.history.push({
        pathname: "/login",
      });
    }
  }, []);

  return (
    <>
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} /> : ""}

      <Header_mobile />
      <div>
        <div className="margin-0 d-flex">
          <div className="border-left-dashbord d-none d-md-block">
            <Sidbar />
          </div>
          <div className="bg-main-dashbord">
            <Main lencomments={2}>
              <h5 className="header-main">خروج</h5>
              <div className={"main_div_logout"}>
                <Link to="/members/profile">
                  <img
                    src={close}
                    alt="close"
                    className="close_logout float-start"
                  />
                </Link>
                <p className={"mt28"}>
                  مطمئنید که می‌خواهید از حساب کاربری خارج شوید؟
                </p>
                <div className={"text-last-center pt-1"}>
                  <Link
                    className="balebutton"
                    //  to="/"
                    onClick={exitHandler}
                  >
                    بله
                  </Link>
                </div>
              </div>
            </Main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exit;
