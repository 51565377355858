import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { MDBContainer, MDBBtn } from "mdb-react-ui-kit";
//
import { validate } from "../../components/login/validate";
// config
import configData from "../../config/config.json";
// components
import MetaTagsInfoPage from "../../components/meta-tags-seo/MetaTagsInfoPage";
import Header2 from "../../components/header/Header2";
import Headermobile from "../../components/header-mobile/Headermobile";
import Footer from "../../components/footer/Footer";
import AlertSuccess from "../../components/Dashbord/AlertSuccess/AlertSuccess";
import NotFound from "../NotFound/NotFound";
// rtl
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
const theme = createTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});
// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const Contact = (props) => {
  const [showAlert, setshowAlert] = useState(false);
  // data
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const inputChangeHandler = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const [disable, setDisable] = useState(false);

  // validate
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    setErrors(validate(data, "contact"));
  }, [data, touched]);

  // alert
  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 4000);
  };

  const [pageContent, setPageContent] = React.useState("");
  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  const [ApiDataCome, setApiDataCome] = useState({
    headerbiz: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    headerLogo: {
      title: "",
      link: "",
    },
  });

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: getCookie("_gapp_LId"),
    FooterId: props.match.params.id,
  };
  useEffect(() => {
    axios
      // .get(domain + "/api/footer/menu-content", { headers: header })
      .get(
        configData.API_DOMAIN +
          `/api/v1/Contents?FooterId=${props.match.params.id}`,
        {
          headers: header,
        }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setMTagsCome(true);
          setMetaData(response.data.data.metaTag);
          setPageContent(response.data.data.itemContent);
        } else {
          setPageContent("0");
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 2 header-other
    axios
      .get(configData.API_DOMAIN + "/api/v1/Headers/other", {
        headers: header,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setheaderData(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            headerbiz: true,
          }));
        }
      })
      .catch((error) => {
        // alert(error);
      });

    // 3 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    if (!Object.keys(errors).length) {
      setDisable(true);

      axios
        .post(
          configData.API_DOMAIN + "/api/v1/Contacts",
          {
            fullname: data.name,
            email: data.email,
            message: data.message,
          },
          {
            headers: header,
          }
        )
        .then((response) => {
          setDisable(false);
          if (response.data.isSuccess) {
            // success
            alertHandeler();
            setData({
              name: "",
              email: "",
              message: "",
            });
            setTouched({
              name: false,
              email: false,
            });
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setTouched({
        name: true,
        email: true,
      });
    }
  };

  //getCookie
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <>
      {pageContent === "0" ? (
        <>
          <NotFound />
        </>
      ) : (
        <>
          {MTagsCome ? <MetaTagsInfoPage data={MetaData} /> : ""}

          <div className="d-none d-md-block">
            <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
          </div>
          <div className="d-block d-md-none">
            <div className="fixed-header">
              <Headermobile data={headerData} />
            </div>
            <div className="box-under-header-res"></div>
          </div>
          {/* done header */}
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
              <div className="gish-page-back">
                {/* <div className="gish-con-tit">
              تماس با <span className="color-5ae6a2"> گیشنیز</span>
            </div> */}
                <MDBContainer>
                  <div dangerouslySetInnerHTML={{ __html: pageContent }} />
                  <div className="gish-con-body">
                    {/* <p>
                پیام خود را برای ما ارسال کنید.
                <br className="d-md-none" /> تیم گیشنیز در اسرع وقت به شما پاسخ
                خواهد داد.
              </p> */}
                    <div className="my-4">
                      <div>
                        <TextField
                          name="name"
                          value={data.name}
                          onChange={inputChangeHandler}
                          id="outlined-basic"
                          label="نام و نام‌خانوادگی"
                          variant="outlined"
                          className={
                            errors.name && touched.name
                              ? "label-field-more12 custom-text-field custom-text-field-contact custom-text-field-error"
                              : "label-field-more12 custom-text-field custom-text-field-contact mb-0"
                          }
                        />
                      </div>
                      {errors.name && touched.name && (
                        <span className="input-error input-error-con me-1">
                          {errors.name}
                        </span>
                      )}

                      <div>
                        <TextField
                          type="email"
                          name="email"
                          value={data.email}
                          onChange={inputChangeHandler}
                          id="outlined-basic"
                          label="ایمیل"
                          variant="outlined"
                          className={
                            errors.email && touched.email
                              ? "label-field-less custom-text-field custom-text-field-contact custom-text-field-error mt-3"
                              : "label-field-less custom-text-field custom-text-field-contact mb-0 mt-3"
                          }
                        />
                      </div>
                      {errors.email && touched.email && (
                        <span className="input-error input-error-con me-1">
                          {errors.email}
                        </span>
                      )}
                    </div>
                    <div>
                      <TextField
                        id="outlined-multiline-static"
                        label="پیام خود را بنویسید"
                        name="message"
                        value={data.message}
                        onChange={inputChangeHandler}
                        multiline
                        rows={5}
                        className="label-field-more15 custom-text-field custom-text-field-contact mt-3"
                      />
                    </div>

                    {/* {errors.message && touched.message && (
                <span className="input-error input-error-con me-1">{errors.message}</span>
              )} */}
                    <MDBBtn
                      className="hov-shadow-btn con-btn"
                      disabled={disable}
                      onClick={submitHandler}
                    >
                      ارسال پیام
                    </MDBBtn>
                  </div>
                </MDBContainer>
              </div>
            </ThemeProvider>
          </CacheProvider>
          <Footer data={footerData} dataCome={ApiDataCome.footer} />

          <AlertSuccess
            showAlert={showAlert}
            text={" اطلاعات شما با موفقیت ثبت شد."}
          />
        </>
      )}
    </>
  );
};

export default Contact;
