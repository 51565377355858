import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import "./alert-error.css";

const AlertError = (props) => {
  return (
    <>
      {props.showAlert && (
        <Snackbar
          TransitionComponent={Slide}
          className="alert-error-snackbar"
          open={props.showAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity="error"
            variant="filled"
            className="alert-error-alert"
          >
            {props.text}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AlertError;
