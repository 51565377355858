import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
// 
import { DomainContext, LangIdContext } from "../../App";
// components
import Header2 from "../../components/header/Header2";
import Headermobile from "../../components/header-mobile/Headermobile";
import Footer from "../../components/footer/Footer";
// style
import "./registery.css";
// img
import emailImg from "../../images/registery/email box.svg";
// config
import configData from "../../config/config.json";

function EmailRegisteryPage() {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [ApiDataCome, setApiDataCome] = useState({
    headerbiz: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    HeaderLogo: {
      Title: "",
      Link: "",
    },
  });

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };
  useEffect(() => {
    // 1 header-biz
    axios
      .get(domain + "/api/header-other", { headers: header })
      .then((response) => {
        if ((response.data.msg = "done")) {
          setheaderData(response.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            headerbiz: true,
          }));
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 2 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  return (
    <>
      <div className="d-none d-md-block">
        <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
      </div>
      <div className="d-block d-md-none">
        <div className="fixed-header">
          <Headermobile data={headerData} />
        </div>
        <div className="box-under-header-res "></div>
      </div>

      <div className="registery-bg">
        <object
          data={emailImg}
          alt={"welcome image"}
          className="registery-welcome-img2"
        ></object>
        {/* <img
          src={welImg}
          alt="welcome image"
          className="registery-welcome-img"
        /> */}
        <div className="w-100 position-relative">
          <div className="registery-cir-bg"></div>
          <div className="registery-rec-bg">
            <p className="registery-welcome-text2">
              از این‌که به گیشنیز پیوستید ممنونیم.
              <br />
              لطفا ثبت نام خود را از طریق لینک تاییدی
              <br />
              که به ایمیل شما ارسال شده است، کامل کنید.
            </p>

            <div className="d-flex justify-content-center">
              <p className="registry-resend-email">ایمیل را دریافت نکرده‌ام</p>
            </div>
          </div>
        </div>
      </div>
      <Footer data={footerData} dataCome={ApiDataCome.footer} />
    </>
  );
}

export default EmailRegisteryPage;
