import React, { useState, useEffect, useRef, useContext } from "react";
import { MDBRadio, MDBBtnGroup, MDBBtn } from "mdb-react-ui-kit";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { LangIdContext } from "../App";
import axios from "axios";
// import Skeleton from "@mui/material/Skeleton";
import { Chip } from "@mui/material";
// components
import { validate } from "../components/login/validate";
import MetaTagsEndUserPage from "../components/meta-tags-seo/MetaTagsEndUserPage";
import Header2 from "../components/header/Header2";
import Headermobile from "../components/header-mobile/Headermobile";
import Footer from "../components/footer/Footer";
import ButtonGroupBusinness2 from "../components/ButtonGroupBusinness/ButtonGroupBusinness2";
import AlertError from "../components/alertError/AlertError";
// images
import add1 from "../images/bg-green-add.png"; ///
import iconn from "../images/next-left.png"; ///
import close from "../images/header/close.svg";
// config
import configData from "../config/config.json";

const IntroduceBusiness = (props) => {
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [ApiDataCome, setApiDataCome] = useState({
    headerbiz: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    headerLogo: {
      title: "",
      link: "",
    },
  });
  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  // for keybord language alert
  const [farsiError, setFarsiError] = useState(false);

  // backend datas
  const [categories, setCategories] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  //
  const [emptyInput, setemptyInput] = useState(true);
  const [CatIdsApi, setCatIdsApi] = useState(null);
  const [childCat, setChildCat] = useState([]);
  const [items, setItems] = useState([]);
  const [changedCid, setChangedCid] = useState(true);
  const [cid, setcid] = useState([]);
  // const [locakChild, setlocakChild] = useState(false);
  const [dataCome2, setDataCome2] = useState(false);

  // group validate
  const [groupValidate, setGroupValidate] = useState(true);
  const [showGV, setShowGV] = useState(false);
  const [buttonGroupsId, setbuttonGroupsId] = useState([]);

  // const [inputValues, setInputValues] = useState({});
  const [counter, setCounter] = useState(1); //for input adder
  const [showCounter, setShowCounter] = useState(true); //for input adder

  const [showBtns, setShowBtns] = useState(false);
  const [other, setOther] = useState(false);
  const [otherName, setOtherName] = useState("");
  const inputengName = useRef(null);
  const inputcountryy = useRef(null);
  const inputprovincee = useRef(null);
  const inputcityy = useRef(null);

  //
  const [CountryId, setCountryId] = useState("");
  const [ProvinceId, setProvinceId] = useState("");
  const [CityId, setCityId] = useState("");

  const [data, setData] = useState({
    engName: "",
    perName: "",
    countryy: "",
    countryyId: "",
    provincee: "",
    provinceeId: "",
    cityy: "",
    cityyId: "",
    addresss: "",
    webOrInsta: {},
    category: "",
    categoryId: "",
    group: "",
    othergroup: "",
  });

  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  const [valueCountry, setValueCountry] = useState(null);
  const [inputValueCountry, setInputValueCountry] = useState("");
  const [valueProvince, setValueProvince] = useState(null);
  const [inputValueProvince, setInputValueProvince] = useState("");
  const [valueCity, setValueCity] = useState(null);
  const [inputValueCity, setInputValueCity] = useState("");

  const [inputDisable, setInputDisable] = useState(true);

  const [isFirstRender, setIsFirstRender] = useState(true);

  // this 2 are for input adder
  const handleClick = () => {
    if (counter < 4) {
      setCounter(counter + 1);
    }
    if (counter > 2) {
      setShowCounter(false);
    }
  };
  const handleOnChange = (e) => {
    const abc = {};
    abc[e.target.id] = e.target.value;
    setData({ ...data, webOrInsta: { ...data.webOrInsta, ...abc } });
  };

  // this 3 are for button group
  const showOther = (event) => {
    setOther(true);
    setData({ ...data, group: event.target.value });
  };
  const otherNameHandler = (event) => {
    setOtherName(event.target.value);
    setData({ ...data, othergroup: event.target.value });
  };
  const hideOther = (event) => {
    setOther(false);
    setData({ ...data, group: event.target.value, othergroup: "" });
    // make otherName input null
    setOtherName("");
  };

  // for simple inputs
  const simpleInputHandler = (event) => {
    // const result = event.target.value.replace(/[^A-Za-z\s]/gi, '');
    setData({ ...data, [event.target.name]: event.target.value });
    if (event.target.name == "engName") {
      if (!/^[a-zA-Z0-9 ]*$/.test(event.target.value)) {
        setFarsiError(true);
        setTimeout(() => {
          setFarsiError(false);
        }, 4000);
      }
      var result = event.target.value.replace(/[^A-Za-z0-9\s,]/gi, "");
      setData({ ...data, [event.target.name]: result });
    }
  };

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    setErrors(validate(data, "introduce-business"));
  }, [data, touched]);

  const onButtonClick_fcous = () => {
    // console.log(errors);
    let result = Object.keys(errors)[0];
    switch (result) {
      case "engName":
        inputengName.current.focus();
        break;
      case "countryy":
        inputcountryy.current.scrollIntoView();
        break;
      case "provincee":
        inputprovincee.current.scrollIntoView();
        break;
      case "cityy":
        inputcityy.current.scrollIntoView();
        break;
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();

    localStorage.setItem("business-introduction-data", JSON.stringify(data)); //set local
    localStorage.setItem(
      "business-introduction-data-cat",
      JSON.stringify(value)
    ); //set local

    var size = Object.keys(errors).length;
    if (size > 0) {
      onButtonClick_fcous();
    }

    if (!Object.keys(errors).length) {
      // success
      props.history.push({
        pathname: "/introduce-business/introduce-business-confirm",
        // state: data, // your data array of objects
      });
    } else {
      setTouched({
        engName: true,
        countryy: true,
        provincee: true,
        cityy: true,
      });
    }
  };

  // for new autooooooooooooo
  const [list3, setList3] = useState([]);
  const [listCounter, setListCounter] = useState(0);
  const listHandler = (newValue) => {
    if (list3.length < 4) {
      if (!list3.includes(newValue.title)) {
        setList3([...list3, newValue.title]);
      }
    }
  };

  const deleteHandler = (num) => {
    if (list3.length > 0) {
      setList3(list3.filter((item) => item !== num));
    }
    setValue(null);
    setData({ ...data, category: "" });
  };

  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };

  // const headerCat = {
  //   ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
  //   LangId: langIdState,
  //   Parentid: cid,
  // };

  useEffect(() => {
    // 1 metaTags
    axios
      .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
        headers: {
          ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
          LangId: langIdState,
          Page: "introduce-business",
        },
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setMTagsCome(true);
          setMetaData(response.data.data);
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 2 header-other
    axios
      .get(configData.API_DOMAIN + "/api/v1/Headers/other", {
        headers: header,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setheaderData(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            headerbiz: true,
          }));
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 3 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });

    if (JSON.parse(localStorage.getItem("business-introduction-data"))) {
      const newData = JSON.parse(
        localStorage.getItem("business-introduction-data")
      );
      setData(newData);
      setCountryId(newData.countryyId);
      setInputValueCountry(newData.countryy);
      setValueCountry({ id: newData.countryyId, title: newData.countryy });
      setProvinceId(newData.provinceeId);
      setInputValueProvince(newData.provincee);
      setValueProvince({ id: newData.provinceeId, title: newData.provincee });
      setCityId(newData.cityyId);
      setInputValueCity(newData.cityy);
      setValueCity({ id: newData.cityyId, title: newData.cityy });

      // 4 Countries
      axios
        .get(configData.API_DOMAIN + "/api/v1/Countries", {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setCountries(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });

      axios
        .get(
          configData.API_DOMAIN + "/api/v1/Provinces/" + newData.countryyId,
          {
            headers: header,
          }
        )
        .then((response) => {
          if (response.data.isSuccess) {
            setProvinces(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
      axios
        .get(configData.API_DOMAIN + "/api/v1/Cities/" + newData.provinceeId, {
          headers: header,
        })
        .then((response) => {
          if (response.data.data) {
            setCities(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
      setInputDisable(false);

      if (JSON.parse(localStorage.getItem("business-introduction-data-cat"))) {
        const newCat = JSON.parse(
          localStorage.getItem("business-introduction-data-cat")
        );

        // setItems([]);
        // setbuttonGroupsId([]);
        setValue(newCat);
        setChildCat(newCat.children);
        // setlocakChild(true);
        setcid(newCat.cId);
        listHandler(newCat);
        setShowBtns(true);
      }
    } else {
      // 4 Countries
      axios
        .get(configData.API_DOMAIN + "/api/v1/Countries", {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setCountries(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    }

    axios
      .get(configData.API_DOMAIN + "/api/v1/Categories", {
        headers: header,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setCategories(response.data.data);
        }
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  useEffect(() => {
    if (inputValueCountry != "") {
      axios
        .get(configData.API_DOMAIN + "/api/v1/Provinces/" + CountryId, {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setProvinces(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    }
  }, [CountryId]);

  useEffect(() => {
    if (
      inputValueProvince != "" &&
      inputValueCountry != "" &&
      valueProvince != null
    ) {
      axios
        .get(configData.API_DOMAIN + "/api/v1/Cities/" + ProvinceId, {
          headers: header,
        })
        .then((response) => {
          if (response.data.data) {
            setCities(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    }
  }, [ProvinceId]);

  // useEffect(() => {
  //   if (locakChild) {
  //     setDataCome2(false);
  //     axios
  //       .get(domain + "/api/childcate ", { headers: headerCat })
  //       .then((response) => {
  //         if (response.data.msg == "done") {
  //           setChildCat(response.data.CatList);
  //           setDataCome2(true);
  //         }
  //       })
  //       .catch((error) => {
  //         // alert(error);
  //       });
  //     setlocakChild(false);
  //   }
  // }, [cid, changedCid]);

  useEffect(() => {
    if (isFirstRender) {
      // Skip the first render
      setIsFirstRender(false);
    } else {
      setData({
        ...data,
        countryyId: CountryId,
        provinceeId: ProvinceId,
        cityyId: CityId,
      });
    }
  }, [CountryId, ProvinceId, CityId]);

  useEffect(() => {
    if (isFirstRender) {
      // Skip the first render
      setIsFirstRender(false);
    } else {
      var btnGroupsId;

      if (buttonGroupsId.length == 0) {
        btnGroupsId = "";
      } else if (buttonGroupsId.length == 1) {
        btnGroupsId = buttonGroupsId[0];
      } else if (buttonGroupsId.length == 2) {
        btnGroupsId = buttonGroupsId[0] + "," + buttonGroupsId[1];
      } else if (buttonGroupsId.length == 3) {
        btnGroupsId =
          buttonGroupsId[0] + "," + buttonGroupsId[1] + "," + buttonGroupsId[2];
      }
      var CatId = cid + "," + btnGroupsId;

      setData({
        ...data,
        categoryId: CatId,
      });
    }
  }, [data.category, buttonGroupsId]);

  return (
    <>
      {/* <UrlChecker /> */}
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} noindex={true} /> : ""}

      <div className="d-none d-md-block">
        <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
      </div>
      <div className="d-block d-md-none">
        <div className="fixed-header">
          <Headermobile data={headerData} />
        </div>
        <div className="box-under-header-res"></div>
      </div>

      <div className="container">
        <div className="">
          <div className="centerr text-align-center">
            <p className="introduce-text1">معرفی کسب‌وکار به گیشنیز</p>
            <p className="introduce-text2">
              اطلاعات مربوط به کسب‌وکار را وارد کنید.
            </p>
            <div className="centerr">
              <input
                ref={inputengName}
                name="engName"
                value={data.engName}
                onChange={simpleInputHandler}
                dir="ltr"
                // className="introduce-input1 introduce-placeholder"
                className={
                  errors.engName && touched.engName
                    ? "introduce-input1 introduce-placeholder font-family-en text-right mb-md-1 border-error"
                    : "introduce-input1 introduce-placeholder font-family-en text-right mb-md-1"
                }
                type="text"
                placeholder="*نام کسب‌وکار به انگلیسی"
              />

              {errors.engName && touched.engName && (
                <span className="ib-input-error">{errors.engName}</span>
              )}
            </div>
            <div className="centerr">
              <input
                name="perName"
                value={data.perName}
                onChange={simpleInputHandler}
                className="introduce-input1 introduce-placeholder"
                type="text"
                placeholder="نام کسب‌وکار به فارسی"
              />
            </div>
            <div className="centerr">
              <div className="ib-3input justify-content-between d-flex">
                <div className="centerr auto3">
                  <Autocomplete
                    value={valueCountry}
                    onChange={(event, newValue) => {
                      setValueCountry(newValue);
                      setProvinces([]);
                      setCities([]);
                      setValueProvince(null);
                      setValueCity(null);
                      setCountryId(newValue.id);
                      setInputDisable(false);
                      setProvinceId("");
                    }}
                    inputValue={inputValueCountry}
                    onInputChange={(event, newInputValue) => {
                      setInputValueCountry(newInputValue);
                      setData({
                        ...data,
                        countryy: newInputValue,
                      });
                    }}
                    popupIcon={
                      <img src={iconn} className="icon-ib" alt="category img" />
                    }
                    // open={true}
                    disableClearable
                    disablePortal
                    openOnFocus={true}
                    id="auto3"
                    options={countries}
                    getOptionLabel={(option) => option.title}
                    sx={{
                      fontFamily: "inherit",
                    }}
                    renderInput={(params) => (
                      <div className="d-flex">
                        <TextField
                          ref={inputcountryy}
                          name="countryy"
                          id="auto3"
                          className={
                            errors.countryy && touched.countryy
                              ? "auto-error"
                              : "auto-bord"
                          }
                          {...params}
                          placeholder="کشور*"
                        ></TextField>
                      </div>
                    )}
                  />
                </div>
                <div className="centerr auto3">
                  <Autocomplete
                    value={valueProvince}
                    onChange={(event, newValue) => {
                      setProvinceId(newValue.id);
                      setValueProvince(newValue);
                      setCities([]);
                      setValueCity(null);
                    }}
                    inputValue={inputValueProvince}
                    onInputChange={(event, newInputValue) => {
                      setInputValueProvince(newInputValue);
                      setData({
                        ...data,
                        provincee: newInputValue,
                      });
                    }}
                    popupIcon={
                      <img src={iconn} className="icon-ib" alt="category img" />
                    }
                    // open={true}
                    disableClearable
                    disablePortal
                    openOnFocus={true}
                    id="auto4"
                    disabled={inputDisable}
                    options={provinces}
                    getOptionLabel={(option) => option.title}
                    sx={{
                      fontFamily: "inherit",
                    }}
                    renderInput={(params) => (
                      <div className="d-flex">
                        <TextField
                          disabled={inputDisable}
                          ref={inputprovincee}
                          id="auto4"
                          className={
                            errors.provincee && touched.provincee
                              ? "auto-error"
                              : "auto-bord"
                          }
                          {...params}
                          placeholder="استان*"
                        ></TextField>
                      </div>
                    )}
                  />
                </div>

                <div className=" auto3">
                  <Autocomplete
                    value={valueCity}
                    onChange={(event, newValue) => {
                      setValueCity(newValue);
                      setCityId(newValue.id);
                    }}
                    inputValue={inputValueCity}
                    onInputChange={(event, newInputValue) => {
                      setInputValueCity(newInputValue);
                      setData({ ...data, cityy: newInputValue });
                    }}
                    popupIcon={
                      <img src={iconn} className="icon-ib" alt="category img" />
                    }
                    // open={true}
                    disableClearable
                    disablePortal
                    openOnFocus={true}
                    disabled={inputDisable}
                    id="auto5"
                    options={cities}
                    getOptionLabel={(option) => option.title}
                    sx={{
                      fontFamily: "inherit",
                    }}
                    renderInput={(params) => (
                      <div className="d-flex">
                        <TextField
                          disabled={inputDisable}
                          ref={inputcityy}
                          id="auto5"
                          className={
                            errors.cityy && touched.cityy
                              ? "auto-error"
                              : "auto-bord"
                          }
                          {...params}
                          placeholder="شهر*"
                        ></TextField>
                      </div>
                    )}
                  />
                </div>
              </div>
              {((errors.countryy && touched.countryy) ||
                (errors.provincee && touched.provincee) ||
                (errors.cityy && touched.cityy)) && (
                <span className="ib-input-error">
                  لطفا این قسمت را خالی نگذارید.
                </span>
              )}
            </div>
            <div className="centerr">
              <input
                dir="ltr"
                name="addresss"
                value={data.addresss}
                onChange={simpleInputHandler}
                className="introduce-input1 introduce-placeholder"
                type="text"
                placeholder="آدرس"
              />
            </div>

            {/* get input value for send to server */}
            {/* 
          {Object.keys(inputValues).map((c) => {
            return <p key={c}>{inputValues[c]}</p>;
          })} */}

            {Array.from(Array(counter)).map((c, index) => {
              return (
                <div className="centerr" key={index}>
                  <input
                    dir="ltr"
                    value={data.webOrInsta[index]}
                    //  {...console.log(inputValues[index])}
                    onChange={handleOnChange}
                    key={c}
                    id={index}
                    className="introduce-input1 introduce-placeholder"
                    type="text"
                    // placeholder="آدرس وب‌سایت یا اینستاگرام"
                    placeholder="آدرس وبسایت یا سوشال مدیا"
                    // value={}
                  />
                </div>
              );
            })}

            {/* <div className="d-flex justify-content-center">
              <div className="introduce-add1">
                {showCounter ? (
                  <button
                    className="introduce-add hov-shadow-btn "
                    onClick={handleClick}
                  >
                    <img src={add1} alt="add" className="introduce-add-img" />
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div> */}

            <p className="introduce-text3">
              {" "}
              دسته‌بندی مربوط به کسب‌وکار را انتخاب کنید.
            </p>
            <div className="centerr">
              <div className=" auto6">
                <Autocomplete
                  value={value}
                  onChange={(event, newValue) => {
                    setChangedCid(!changedCid);
                    setItems([]);
                    setbuttonGroupsId([]);
                    setValue(newValue);
                    setChildCat(newValue.children);
                    // setlocakChild(true);
                    setcid(newValue.cId);
                    listHandler(newValue);
                    setShowBtns(true);
                    setData({ ...data, category: newValue.title });
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    setData({
                      ...data,
                      categoryy: newInputValue,
                    });
                  }}
                  disabled={list3.length > 0}
                  popupIcon={
                    <img src={iconn} className="icon-ib" alt="category img" />
                  }
                  // open={true}
                  disableClearable
                  disablePortal
                  openOnFocus={true}
                  id="auto6"
                  options={categories}
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { ml: 2, flexShrink: 0 } }}
                      className="fs-4vw"
                      {...props}
                    >
                      <img
                        src={option.icon}
                        alt={option.title}
                        className="img-header-subcat"
                      />
                      <span> {option.title}</span>
                    </Box>
                  )}
                  className=""
                  sx={{
                    fontFamily: "inherit",
                  }}
                  renderInput={(params) => (
                    <div className="d-flex">
                      <TextField
                        id="auto6"
                        className=""
                        {...params}
                        placeholder=""
                      >
                        {" "}
                      </TextField>
                    </div>
                  )}
                />
              </div>
            </div>

            {list3.map((item) => (
              <Chip
                id="rb-chip"
                // dir="ltr"
                label={item}
                onDelete={() => {
                  if (list3.length == 1) {
                    deleteHandler(item);
                    setShowBtns(false);
                    // setOther(false);
                  } else {
                    deleteHandler(item);
                  }
                }}
                deleteIcon={
                  <img src={close} alt="X" className="s2-chip-icon" />
                }
                key={item}
              />
            ))}

            {/* second part */}
            <div className={showBtns ? "d-block rb2-bgb" : "d-none"}>
              {/* {dataCome2 ? ( */}
              {childCat.length > 0 && (
                <ButtonGroupBusinness2
                  dataActives={data.categoryId}
                  itemss={items}
                  data={childCat}
                  buttonGroupsId={buttonGroupsId}
                  onChangebuttonGroupsId={setbuttonGroupsId}
                  groupValidate={groupValidate}
                  setGroupValidate={setGroupValidate}
                  showGV={showGV}
                  CatIdsApi={CatIdsApi}
                  setCatIdsApi={setCatIdsApi}
                  emptyInput={emptyInput}
                  setemptyInput={setemptyInput}
                />
              )}

              {/* )
              ) : (
                <>
                  <div className="d-flex justify-content-center  mt-5">
                    <Skeleton
                      id="rb-chip-skeleton"
                      variant="rectangular"
                      animation="wave"
                    />
                    <Skeleton
                      id="rb-chip-skeleton"
                      variant="rectangular"
                      animation="wave"
                    />
                    <Skeleton
                      id="rb-chip-skeleton"
                      variant="rectangular"
                      animation="wave"
                    />
                    <Skeleton
                      id="rb-chip-skeleton"
                      variant="rectangular"
                      animation="wave"
                    />
                  </div>
                  <div className="d-flex justify-content-center ">
                    <Skeleton
                      id="rb-chip-skeleton"
                      variant="rectangular"
                      animation="wave"
                    />
                    <Skeleton
                      id="rb-chip-skeleton"
                      variant="rectangular"
                      animation="wave"
                    />
                    <Skeleton
                      id="rb-chip-skeleton"
                      variant="rectangular"
                      animation="wave"
                    />
                    <Skeleton
                      id="rb-chip-skeleton"
                      variant="rectangular"
                      animation="wave"
                    />
                  </div>
                </>
              )} */}
            </div>

            <div className="centerr">
              <MDBBtn
                className="introduce-sabt-btn hov-shadow-btn shadow-off"
                onClick={submitHandler}
              >
                ثبت
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      {/* alert error */}
      <AlertError
        showAlert={farsiError}
        text={`زبان کیبورد را به انگلیسی تغییر دهید.`}
      />
      <Footer data={footerData} dataCome={ApiDataCome.footer} />
    </>
  );
};

export default IntroduceBusiness;
