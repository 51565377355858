import { MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import "./ButtonGroupBusinness.css";
// rtl
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const theme = createTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});
// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const ButtonGroupBusinness2 = (props) => {
  const {
    data,
    buttonGroupsId,
    onChangebuttonGroupsId,
    groupValidate,
    setGroupValidate,
    CatIdsApi,
    setCatIdsApi,
    emptyInput,
    setemptyInput,
    dataActives,
  } = props;

  const [active, setactive] = useState(true);
  const [active2, setactive2] = useState(true);
  const [other, setOther] = useState(false);
  const [otherName, setOtherName] = useState("");
  const [gv1, setgv1] = useState(false);

  const otherNameHandler = (event) => {
    setOtherName(event.target.value);
    setGroupValidate(true);
    setCatIdsApi(event.target.value);
    // if (otherName != "") {
    // localStorage.setItem("inputOthername", JSON.stringify(otherName));
    // }

    // setemptyInput(true);
    // console.log(otherName == "");

    // if (otherName == "") {
    //   setGroupValidate(false);
    //   // console.log("=");
    // } else {
    //   // console.log("no");
    //   setGroupValidate(true);
    // }
  };

  useEffect(() => {
    // if (otherName == "") {
    //   setGroupValidate(false);
    //   console.log("=");
    // } else {
    //   console.log("no");
    //   setGroupValidate(true);
    // }
  }, [otherName]);

  // useEffect(() => {
  //   console.log("groupValidate changed");
  //   console.log(groupValidate);
  // }, [groupValidate]);

  // useEffect(() => {
  //   console.log("emptyInput changed");
  //   console.log(emptyInput);
  // }, [emptyInput]);

  const showInput = (id) => {
    ClickHandeler(id);
    // setGroupValidate(!groupValidate);
    setgv1(!gv1);

    setOtherName("");
    setCatIdsApi(null);
    setOther(!other);
    setGroupValidate(!groupValidate);

    // console.log("groupValidate");
    // console.log(groupValidate);

    setactive2(!active2);
    // setemptyInput(false);

    let active = document.getElementsByClassName("btn_choose_job_active");
    if (document.getElementById(0).className == "btn_choose_job_active") {
      setGroupValidate(false);
      // console.log("000000000000000000000000000000 active");
      // console.log("disable00000000000");
      // console.log(groupValidate);
    } else {
      setGroupValidate(true);
      // console.log("active11111111111");
      // console.log(groupValidate);
    }
    // console.log("000000000000000000000000000000 active");
    // console.log("groupValidate");
    // console.log(groupValidate);

    if (localStorage.getItem("inputOthername")) {
      localStorage.removeItem("inputOthername");
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("business-introduction-data-cat"))) {
      const arrCat = dataActives.split(",");

      for (let index = 1; index < arrCat.length; index++) {
        const element = parseInt(arrCat[index]);
        if (element) {
          let read_button = document.getElementById(element);
          if (read_button.className === "btn_choose_job_active") {
            document.getElementById(element).className = "btn_choose_job";
            onChangebuttonGroupsId(buttonGroupsId.filter((i) => i !== element));
          } else {
            document.getElementById(element).className =
              "btn_choose_job_active";
            if (!buttonGroupsId.includes(element)) {
              onChangebuttonGroupsId((buttonGroupsId) => [
                ...buttonGroupsId,
                element,
              ]);
            }
          }
        }
      }
    }
  }, []);

  const ClickHandeler = (value) => {
    let active = document.getElementsByClassName("btn_choose_job_active");
    let read_button = document.getElementById(value);
    if (read_button.className === "btn_choose_job_active") {
      document.getElementById(value).className = "btn_choose_job";
      onChangebuttonGroupsId(buttonGroupsId.filter((i) => i !== value));
    } else {
      document.getElementById(value).className = "btn_choose_job_active";
      if (!buttonGroupsId.includes(value)) {
        onChangebuttonGroupsId((buttonGroupsId) => [...buttonGroupsId, value]);
      }
    }
    if (active.length === 3) {
      var elementb = document.getElementsByClassName("btn_choose_job");
      for (var i = 0; i < elementb.length; ++i) {
        var item = elementb[i];
        item.disabled = true;
      }
    } else {
      var elementb = document.getElementsByClassName("btn_choose_job");
      for (var i = 0; i < elementb.length; ++i) {
        var item = elementb[i];
        item.disabled = false;
      }
    }
  };

  // get active button
  useEffect(() => {
    // const items = JSON.parse(localStorage.getItem("step2_active_button"));
    if (props.itemss.length > 0) {
      for (let index = 0; index < props.itemss.length; index++) {
        const element = props.itemss[index];
        if (!buttonGroupsId.includes(element)) {
          onChangebuttonGroupsId((buttonGroupsId) => [
            ...buttonGroupsId,
            element,
          ]);
        }
      }
    }
    if (JSON.parse(localStorage.getItem("inputOthername"))) {
      setOther(true);
      setactive2(false);
      setOtherName(JSON.parse(localStorage.getItem("inputOthername")));
      setCatIdsApi(JSON.parse(localStorage.getItem("inputOthername")));
      setGroupValidate(true);
      // setemptyInput(true);
    }
  }, []);

  // set active button
  useEffect(() => {
    // localStorage.setItem("step2_active_button", JSON.stringify(buttonGroupsId));

    if (buttonGroupsId.length > 0) {
      for (let index = 0; index < buttonGroupsId.length; index++) {
        const element = buttonGroupsId[index];
        if (element != null) {
          document.getElementById(element).className = "btn_choose_job_active";
        }
      }
      if (buttonGroupsId.length >= 3) {
        // if (element != null) {
        var elementb = document.getElementsByClassName("btn_choose_job");
        for (var i = 0; i < elementb.length; ++i) {
          var item = elementb[i];
          item.disabled = true;
        }
        // }
      }
    }
    // if (!JSON.parse(localStorage.getItem("step2_active_button")).includes(0)) {
    //   setGroupValidate(true);
    // }
  }, [buttonGroupsId]);

  useEffect(() => {
    if (!active2) {
      localStorage.setItem("inputOthername", JSON.stringify(otherName));
    }
  }, [otherName]);

  return (
    <>
      <div className="d-block">
        <p className="rb-step2-tit2">کسب&zwnj;وکار در چه گروهی قرار دارد ؟</p>

        <div className="centerr">
          <MDBRow className="btn-group-div-bus justify-content-center mb-3">
            {data.map((item) => (
              <button
                key={item.cId}
                id={item.cId}
                onClick={() => ClickHandeler(item.cId)}
                className={!active ? "btn_choose_job_active" : "btn_choose_job"}
              >
                {item.title}
              </button>
            ))}

            {/* <button
              id={0}
              onClick={() => showInput(0)}
              className={!active2 ? "btn_choose_job_active" : "btn_choose_job"}
            >
              دیگر
              <p className="digar-img mb-0"></p>
            </button> */}
          </MDBRow>
          {/* thired part */}
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
              <div className={other ? "d-block " : "d-none"}>
                <div className=" width-100 ms-auto mt-4 mb-2">
                  <TextField
                    type="text"
                    // name="engName"
                    value={otherName}
                    onChange={otherNameHandler}
                    id="outlined-basic"
                    label="گروه مورد نظر در گزینه ها نیست آن را در این کادر وارد کنید"
                    variant="outlined"
                    className={
                      !groupValidate && props.showGV
                        ? // && !emptyInput
                          "custom-text-field-error label-field-more7 custom-text-field custom-text-field-contact mb-0 mt-3 fs-res-12 label-field-other  width-100 "
                        : "label-field-more7 custom-text-field custom-text-field-contact mb-0 mt-3 fs-res-12 label-field-other  width-100 "
                    }
                  />
                </div>
              </div>
            </ThemeProvider>
          </CacheProvider>
          {/* thired part */}
        </div>

        {/* {console.log(props.showGV)} */}
        {/* {console.log(!emptyInput)} */}
        {!groupValidate && (
          // && props.showGV

          // || !emptyInput
          <span className="input-error-auto input-error-auto-center d-md-inline me-md-0 mt-md-n2 me-0 mb-3 ">
            لطفا برای کسب‌وکار خود گروه انتخاب کنید
          </span>
        )}
      </div>
    </>
  );
};

export default ButtonGroupBusinness2;
