import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import "./aa.css";
import IconButton from "@mui/material/IconButton";
import arrow from "../../images/next-left.png";

import closeImg from "../../images/header/close.svg";

export default function ResultFilterModal(props) {
  const [state, setState] = React.useState({
    bottom1: false,
    bottom2: false,
    bottom3: false,
  });

  // for working
  const {
    paymentsFilter,
    setPaymentsFilter,
    amenitiesFilter,
    setAmenitiesFilter,
  } = props;

  const [category, setCategory] = useState([]);
  const [option, setOption] = useState([]);
  const [peyment, setpeyment] = useState([]);

  useEffect(() => {
    if (props.dataComeCategory) {
      setCategory(props.category);
    }
    if (props.dataComeOption) {
      setOption(props.option);
    }
    if (props.dataComePeyment) {
      setpeyment(props.peyment);
    }
  }, [props.dataComeCategory, props.dataComeOption, props.dataComePeyment]);

  const handlePaymentChange = (event, id) => {
    if (event.target.checked) {
      // console.log("piid" + id);
      if (!paymentsFilter.includes(id)) {
        setPaymentsFilter((paymentsFilter) => [...paymentsFilter, id]);
      }
    } else {
      setPaymentsFilter(paymentsFilter.filter((item) => item !== id));
    }
  };

  const handleAmenitiesChange = (event, id) => {
    if (event.target.checked) {
      if (!paymentsFilter.includes(id)) {
        setAmenitiesFilter((amenitiesFilter) => [...amenitiesFilter, id]);
      }
    } else {
      setAmenitiesFilter(amenitiesFilter.filter((item) => item !== id));
    }
    // console.log("amenitiesFilter" + amenitiesFilter);
  };

  // for drawer

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list1 = (anchor) => (
    <Box
      sx={{ width: "bottom" === "top" || "bottom" === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="result-filter-mob-box"
    >
      {category.length ? (
        category.map((item) => (
          <div
            key={item.cId}
            className="result-filter-item result-filter-item-res my-0"
          >
            <Link
              to={item.url}
              className="color-242b2e"
              onClick={() => {
                if (props.setCloseModal) {
                  props.setCloseModal(false);
                }
              }}
            >
              <span>{item.title}</span>
            </Link>
          </div>
        ))
      ) : (
        <p></p>
      )}
    </Box>
  );
  const list2 = (anchor) => (
    <Box
      sx={{ width: "bottom" === "top" || "bottom" === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="result-filter-mob-box"
    >
      {option.length ? (
        option.map((item) => (
          <div key={item.cotId} className="result-filter-item my-0">
            <label className="container-s2 m-0 me-0 mb-0 d-inline-flex">
              <input
                type="checkbox"
                onChange={(event) => handleAmenitiesChange(event, item.cotId)}
                defaultChecked={
                  amenitiesFilter.includes(item.cotId) ? true : false
                }
              />
              <span className="checkmark-s2 back-white"></span>
              <span>{item.title}</span>
            </label>
          </div>
        ))
      ) : (
        <p></p>
      )}
    </Box>
  );
  const list3 = (anchor) => (
    <Box
      sx={{ width: "bottom" === "top" || "bottom" === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="result-filter-mob-box"
    >
      {peyment.length ? (
        peyment.map((item) => (
          <div key={item.ptId} className="result-filter-item my-0">
            <label className="container-s2 m-0 me-0 mb-0 d-inline-flex">
              <input
                type="checkbox"
                onChange={(event) => handlePaymentChange(event, item.ptId)}
                defaultChecked={
                  paymentsFilter.includes(item.ptId) ? true : false
                }
              />
              <span className="checkmark-s2 back-white"></span>
              <span>{item.title}</span>
            </label>
          </div>
        ))
      ) : (
        <p></p>
      )}
    </Box>
  );

  return (
    <div className="d-flex">
      {["bottom1", "bottom2", "bottom3"].map((anchor) => (
        <React.Fragment key={anchor}>
          {anchor == "bottom1" ? (
            <div
              onClick={toggleDrawer(anchor, true)}
              className="filter-dropdown"
            >
              دسته‌بندی
              <img
                src={arrow}
                alt="icon"
                className={
                  state.anchor
                    ? "nextFilter-biz nextFilter-biz-rotate"
                    : "nextFilter-biz nextFilter-biz-rotateback"
                }
              />
            </div>
          ) : (
            ""
          )}
          {anchor == "bottom2" ? (
            <div
              onClick={toggleDrawer(anchor, true)}
              className="filter-dropdown"
            >
              امکانات
              <img
                src={arrow}
                alt="icon"
                className={
                  state.anchor
                    ? "nextFilter-biz nextFilter-biz-rotate"
                    : "nextFilter-biz nextFilter-biz-rotateback"
                }
              />
            </div>
          ) : (
            ""
          )}{" "}
          
          {/* روش پرداخت فعلا حذف شود */}
          {/* {anchor == "bottom3" ? (
            <div
              onClick={toggleDrawer(anchor, true)}
              className="filter-dropdown"
            >
              روش پرداخت
              <img
                src={arrow}
                alt="icon"
                className={
                  state.anchor
                    ? "nextFilter-biz nextFilter-biz-rotate"
                    : "nextFilter-biz nextFilter-biz-rotateback"
                }
              />
            </div>
          ) : (
            ""
          )} */}
          <Drawer
            anchor={"bottom"}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className="result-filter-mob-drawer"
          >
            <div className="result-filter-mob-drawer-tit">
              <p className="">
                {anchor == "bottom1" ? "دسته‌بندی" : ""}
                {anchor == "bottom2" ? "امکانات" : ""}
                {anchor == "bottom3" ? "روش پرداخت" : ""}
              </p>
              <div className="drawer-body">
                <IconButton
                  className="p-0 w-auto"
                  onClick={toggleDrawer(anchor, false)}
                >
                  <img
                    className="header-close-img m-0"
                    src={closeImg}
                    alt="close icon"
                  />
                </IconButton>
                <p> </p>
              </div>
            </div>

            {anchor == "bottom1" ? list1(anchor) : []}
            {anchor == "bottom2" ? list2(anchor) : []}
            {anchor == "bottom3" ? list3(anchor) : []}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
