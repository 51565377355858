import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { MDBBtn } from "mdb-react-ui-kit";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { DomainContext, LangIdContext } from "../../App";
import { useHistory } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import "./Header.css";
// images
import catImg from "../../images/header/category.svg";
import searchImg from "../../images/header/search.svg"; //////////////////////////////////
import searchBlack from "../../images/header/search black.svg";
// config
import configData from "../../config/config.json";
// components
import AlertError from "../alertError/AlertError";

const Inputs = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  // const [inputData, setInputData] = useState({
  //   whatInput: "",
  //   whereInput: "",
  // });
  const [farsiError, setFarsiError] = useState(false);

  const [keyTime, setKeyTime] = useState("");
  const [keyTime2, setKeyTime2] = useState("");

  const [whatInput, setWhatInput] = useState("");
  const [whereInput, setWhereInput] = useState("");
  const [bizList, setBizList] = useState([]);
  const [catList, setCatList] = useState([]);

  const [locList, setLocList] = useState([]);

  const [listComes, setListComes] = useState(false);
  const [listWhereComes, setListWhereComes] = useState(false);

  const [kojaValue, setKojaValue] = useState(null);
  const [kojaInputValue, setKojaInputValue] = useState("");

  const [chiValue, setChiValue] = useState(null);
  const [chiInputValue, setChiInputValue] = useState("");

  // for loading
  const [nullList, setNullList] = useState([
    // {
    //   BId: "001",
    //   Title: "سوگل کافی",
    //   Location: "Ottawa, Canada",
    //   Url: "/biz/2055/سوگل-کافی",
    // },
    // {
    //   CId: "001",
    //   Title: "خدمات لیزر",
    //   Icon: "https://cdn.gishniz.link/images/Image_not_available.jpg",
    //   Url: "/biz/1903/علی-میر-عبدالباقی",
    // },
  ]);
  const [nullListWhere, setNullListWhere] = useState([]);

  // const inputChangeHandler = (event) => {
  //   setInputData({ ...inputData, [event.target.name]: event.target.value });
  // };
  var go =
    "/search?find_desc=" +
    whatInput.replace(/\s+/g, "-") +
    "&find_loc=" +
    whereInput.replace(/\s+/g, "-") +
    "&cat_id=&find_cat=";

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };

  const apiWhat = () => {
    if (whatInput.length > 1) {
      setCatList([]);
      setBizList([]);
      setListComes(false);
      // برای عدم نمایش این لیست بین سرج ها کامنت بشه فعلا
      // if (props.data2) {
      //   if (JSON.parse(localStorage.getItem("LastVisitBusinesses"))) {
      //     var arrNull = [
      //       ...JSON.parse(localStorage.getItem("LastVisitBusinesses")),
      //       ...props.data2,
      //     ];
      //     setNullList(arrNull);
      //   } else {
      //     setNullList(props.data2);
      //   }
      // }

      // setNullList([
      //   {
      //     BId: "001",
      //     Title: "سوگل کافی",
      //     Location: "Ottawa, Canada",
      //     Url: "/biz/2055/سوگل-کافی",
      //   },
      //   {
      //     CId: "001",
      //     Title: "خدمات لیزر",
      //     Icon: "https://cdn.gishniz.link/images/Image_not_available.jpg",
      //     Url: "/biz/1903/علی-میر-عبدالباقی",
      //   },
      // ]);

      axios
        // .post(
        //   domain + "/api/search/what",
        //   { txtWhat: whatInput },
        //   {
        //     headers: header,
        //   }
        // )
        .get(configData.API_DOMAIN + `/api/v1/Search/what/${whatInput}`, {
          headers: header,
        })
        .then((response) => {
          // console.log(response.data);
          if (response.data.isSuccess) {
            setNullList([]);
            const arr = [
              ...response.data.data.businesses,
              ...response.data.data.categories,
            ];
            setCatList(arr);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setCatList([]);
    }
  };

  useEffect(() => {
    if (whatInput.length > 1) {
      setCatList([]);
      setBizList([]);

      setListComes(false);

      clearTimeout(keyTime2);
      setKeyTime2(setTimeout(apiWhat, 1000));
    } else if (whatInput.length == 1 && props.data2) {
      if (JSON.parse(localStorage.getItem("LastVisitBusinesses"))) {
        var arrNull = [
          ...JSON.parse(localStorage.getItem("LastVisitBusinesses")),
          ...props.data2,
        ];
        setNullList(arrNull);
      } else {
        setNullList(props.data2);
      }

      // setNullList([
      //   {
      //     BId: "001",
      //     Title: "سوگل کافی",
      //     Location: "Ottawa, Canada",
      //     Url: "/biz/2055/سوگل-کافی",
      //   },
      //   {
      //     CId: "001",
      //     Title: "خدمات لیزر",
      //     Icon: "https://cdn.gishniz.link/images/Image_not_available.jpg",
      //     Url: "/biz/1903/علی-میر-عبدالباقی",
      //   },
      // ]);
    }
  }, [whatInput]);

  // for disabling
  const [whereCome, setWhereCome] = useState(false);

  const apiWhere = () => {
    setWhereCome(false);
    if (whereInput.length > 1) {
      setListWhereComes(false);
      setLocList([]);
      setNullListWhere([{ id: "001", title: "" }]);

      axios
        // .post(
        //   domain + "/api/search/where",
        //   { txtWhere: whereInput },
        //   {
        //     headers: header,
        //   }
        // )
        .get(configData.API_DOMAIN + `/api/v1/Search/where/${whereInput}`, {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setNullListWhere([]);
            setLocList(response.data.data.locations);
            setWhereCome(true);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setLocList([]);
    }
  };

  useEffect(() => {
    if (whereInput.length > 1) {
      setListWhereComes(false);

      clearTimeout(keyTime);
      setKeyTime(setTimeout(apiWhere, 1000));
    } else if (whereInput.length == 1) {
      setLocList([]);
      setNullListWhere([{ id: "001", title: "" }]);
    }
  }, [whereInput]);

  useEffect(() => {
    if (whatInput.length <= 1 && props.data2) {
      if (JSON.parse(localStorage.getItem("LastVisitBusinesses"))) {
        var arrNull = [
          ...JSON.parse(localStorage.getItem("LastVisitBusinesses")),
          ...props.data2,
        ];
        setNullList(arrNull);
      } else {
        setNullList(props.data2);
      }
    }
    // }, [props.data2 , JSON.parse(localStorage.getItem("LastVisitBusinesses"))]);
  }, [props.data2]);

  // const options = props.data.map((option) => {
  const options = catList.map((option) => {
    if (option.bId || option.bId == "") {
      const op = option.title;
      return {
        op: "آخرین جستجوها",
        ...option,
      };
    } else {
      const op = option.title;
      return {
        op: "دسته‌بندی",
        ...option,
      };
    }
  });
  // for loading
  const optionss2 =
    nullList.length > 0
      ? nullList.map((option) => {
          if (option.bId || option.bId == "") {
            const op = option.title;
            return {
              op: "آخرین بازدیدها",
              ...option,
            };
          } else {
            const op = option.title;
            return {
              op: "دسته‌بندی",
              ...option,
            };
          }
        })
      : [];

  const [removeMask, setRemoveMask] = useState(false);
  const history = useHistory();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (
        !(
          (whereInput.length > 0 && !whereCome) ||
          chiInputValue == "" ||
          (kojaInputValue !== "" && kojaValue == null)
        )
      ) {
        history.push(go);
      }
    }
  };

  const searchHandler = (event) => {
    if (
      !(
        (whereInput.length > 0 && !whereCome) ||
        chiInputValue == "" ||
        (kojaInputValue !== "" && kojaValue == null)
      )
    ) {
      history.push(go);
    }
  };

  return (
    <div className=" mt-2">
      <div className="Searchinput">
        {/* <button onClick={handleKeyDown}>sdsd</button> */}
        {/* <div className="first-input-what-container"> */}
        {/* <TextField
            name="first-input-what"
            type="text"
            className=""
            placeholder="دنبال چی می‌گردی؟"
          /> */}

        <div className="first-input-what-container relative auto1 auto-g">
          <Autocomplete
            onKeyDown={handleKeyDown}
            value={chiValue}
            onChange={(event, newValue) => {
              setChiValue(newValue);
              // newValue.title,
            }}
            inputValue={chiInputValue}
            onInputChange={(event, newInputValue) => {
              var result;
              if (newInputValue == "undefined") {
                result = chiInputValue;
              } else {
                result = newInputValue;
              }
              setChiInputValue(result);
              setWhatInput(result);
            }}
            // open={true}
            disableClearable
            freeSolo={true}
            className=""
            disablePortal
            openOnFocus={false}
            id="grouped-demo"
            // options={whatList}
            filterOptions={(options, state) => options}
            options={
              // whatInput.length > 1 && nullList.length < 1
              whatInput.length > 1
                ? options.sort((a, b) => -b.op.localeCompare(a.op))
                : optionss2.sort((a, b) => -b.op.localeCompare(a.op))
            }
            groupBy={(option) => option.op}
            getOptionLabel={(option) => option.title}
            getOptionDisabled={(option) =>
              option.bId === "001" || option.cId === "001"
            }
            sx={{
              width: 300,
              fontFamily: "inherit",
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { ml: 2, flexShrink: 0 } }}
                className="fs-4vw"
                {...props}
              >
                {/* {(option.bId === "001" || option.cId === "001") && */}
                {options.length == 0 && nullList.length < 1 ? (
                  <div className="d-flex align-items-center px-3 my-1 w-100">
                    <Skeleton
                      variant="circular"
                      width={30}
                      height={26}
                      animation="wave"
                    >
                      <Avatar animation="wave" />
                    </Skeleton>
                    <Skeleton
                      className="mt-1 skeleton-currency"
                      animation="wave"
                    />
                  </div>
                ) : option.bId || option.bId == "" ? (
                  <Link to={option.url} className="width-100">
                    <div className="position-relative d-flex div-auto-g1 color-101010">
                      <img
                        src={option.photoPath}
                        alt="search black"
                        className=" ms-2  autocomplete-search-lap-bizphoto"
                      />
                      <div className="last1-auto">{option.title}</div>
                      <div className="last2-auto">{option.location}</div>
                    </div>
                  </Link>
                ) : (
                  <div
                    // to={option.url.replace(/({loc})/g, whereInput)}
                    className="width-100"
                  >
                    <div className="position-relative d-flex div-auto-g2 color-101010">
                      <img
                        src={searchBlack}
                        alt="search black"
                        className=" ms-2  autocomplete-search-lap"
                      />
                      {option.title}
                    </div>
                  </div>
                )}
              </Box>
            )}
            renderInput={(params) => (
              <div className="d-flex">
                <TextField
                  className=""
                  {...params}
                  placeholder="دنبال چی می‌گردی؟"
                  name="whatInput"
                  value={whatInput}
                  onChange={(event) => {
                    setWhatInput(event.target.value);
                    if (whatInput.length < 1) {
                      setCatList([]);
                    }
                  }}
                >
                  {" "}
                </TextField>
              </div>
            )}
          />
        </div>
        {/* </div> */}

        <div className="category-input-container relative auto1 list-left">
          <Autocomplete
            onKeyDown={handleKeyDown}
            value={kojaValue}
            onChange={(event, newValue) => {
              setKojaValue(newValue);

              // to have city, province and country for search api of search page
              localStorage.setItem("SearchLocation", JSON.stringify(newValue));

              if (chiInputValue !== "" || chiValue) {
                let koja = newValue.title;
                history.push(
                  "/search?find_desc=" +
                    chiInputValue.replace(/\s+/g, "-") +
                    "&find_loc=" +
                    koja.replace(/\s+/g, "-") +
                    "&cat_id=&find_cat="
                );
              }
            }}
            inputValue={kojaInputValue}
            onInputChange={(event, newInputValue) => {
              var result;
              if (newInputValue == "undefined") {
                result = kojaInputValue.replace(/[^A-Za-z\s,]/gi, "");
              } else {
                // for farsi error
                var charCode = newInputValue.charCodeAt(
                  newInputValue.length - 1
                );
                if (
                  !(
                    (charCode >= 97 && charCode <= 122) ||
                    (charCode >= 65 && charCode <= 90) ||
                    charCode == 32
                  ) &&
                  newInputValue !== ""
                ) {
                  setFarsiError(true);
                  setTimeout(() => {
                    setFarsiError(false);
                  }, 4000);
                } else {
                  setFarsiError(false);
                }
                // for farsi error
                result = newInputValue.replace(/[^A-Za-z\s,]/gi, "");
              }
              setKojaInputValue(result);
              setWhereInput(result);
            }}
            // onBlur={() => setFarsiError(false)} // for farsi error
            // open={true}
            disableClearable
            freeSolo={true}
            className=""
            disablePortal
            openOnFocus={false}
            id="auto-koja"
            filterOptions={(options, state) => options}
            options={
              whereInput.length > 1 && nullListWhere.length < 1
                ? locList
                : nullListWhere
            }
            getOptionLabel={(option) => option.title}
            getOptionDisabled={(option) =>
              option.id === "001" || option.id === ""
            }
            sx={{
              width: 300,
              fontFamily: "inherit",
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { ml: 2, flexShrink: 0 } }}
                className="fs-4vw"
                {...props}
              >
                {nullListWhere.length > 0 && option.id === "001" ? (
                  <div className="d-flex align-items-center px-2 w-100">
                    <Skeleton
                      variant="circular"
                      width={30}
                      height={26}
                      animation="wave"
                    >
                      <Avatar animation="wave" />
                    </Skeleton>
                    <Skeleton
                      className="mt-1 skeleton-currency"
                      animation="wave"
                    />
                  </div>
                ) : (
                  <>
                    <div className="">{option.title}</div>
                  </>
                )}
              </Box>
            )}
            renderInput={(params) => (
              <div className="d-flex">
                {/* <img src={catImg} className="icon-inputs position-absolute" alt="category img" /> */}
                <TextField
                  {...params}
                  // className={farsiError ? "farsi-error-input" : ""}
                  placeholder={
                    // farsiError
                    //   ? "به انگلیسی تایپ کنید"
                    //   :
                    "کجا؟ (نام شهر به زبان انگلیسی)"
                  }
                  name="whereInput"
                  value={whereInput}
                  onChange={(event) => {
                    const result = event.target.value.replace(
                      /[^A-Za-z\s,]/gi,
                      ""
                    );
                    setWhereInput(result);
                  }}
                >
                  {" "}
                </TextField>
              </div>
            )}
          />
        </div>
        <MDBBtn
          className="search-submit-btn"
          disabled={
            (whereInput.length > 0 && !whereCome) ||
            chiInputValue == "" ||
            (kojaInputValue !== "" && kojaValue == null)
          }
          onClick={searchHandler}
        >
          <img src={searchImg} className="search-img" alt="search img" />
        </MDBBtn>
      </div>
      {/* alert error */}
      <AlertError
        showAlert={farsiError}
        text={`زبان کیبورد را به انگلیسی تغییر دهید.`}
      />
    </div>
  );
};

// const nullListWhere = [
//   { Id: "001", Title: "" },
// ];
// const nullList = [
//   {
//     BId: "001",
//     Title: "سوگل کافی",
//     Location: "Ottawa, Canada",
//     Url: "/biz/2055/سوگل-کافی",
//   },

//   {
//     CId: "001",
//     Title: "خدمات لیزر",
//     Icon: "https://cdn.gishniz.link/images/Image_not_available.jpg",
//     Url: "/biz/1903/علی-میر-عبدالباقی",
//   },
// ];

export default Inputs;
