import React from "react";
import { useLocation, Link } from "react-router-dom";
//style
import "./Header_mobile.css";
// imgages
import User from "../../../images/end user/user (1).svg";
import greenUser from "../../../images/end user/user f(1).svg";
import resetpass from "../../../images/end user/reset-password.svg";
import resetpassGreen from "../../../images/end user/reset-password green.svg";
import save from "../../../images/end user/save-line black.svg";
import saveGreen from "../../../images/end user/save-line.svg";
import comment from "../../../images/end user/comment.svg";
import commentGreen from "../../../images/end user/comment green.svg";
import payam from "../../../images/end user/send (1).svg";
import payamGreen from "../../../images/end user/send (1) green.svg";
import exit from "../../../images/end user/log-out.svg";
import exitGreen from "../../../images/end user/log-out green.svg";
import logo from "../../../images/home-body/logo gishniz.png";

const Header_mobile = () => {
  const location = useLocation().pathname;

  return (
    <>
      <header className="header_profule_mobile d-md-none new_header centerr">
        <Link to="/">
          <img className={"logo_header_mobile"} src={logo} alt="logo" />
          {/* <object
            src={logo}
            alt="logoffffff"
            className="logo_header_mobile"
            type={"image/svg+xml"}
          ></object> */}
        </Link>
        <ul className="ul_profile_mobile">
          <Link to={"/members/profile"}>
            <li>
              {location === "/members/profile" ? (
                <>
                  <img
                    className={"img_mobile_fa"}
                    src={greenUser}
                    alt="profileFirst"
                  />
                </>
              ) : (
                <img className={"img_mobile_f"} src={User} alt="profileFirst" />
              )}
            </li>
          </Link>
          <Link to={"/members/change-password"}>
            <li>
              {location === "/members/change-password" ? (
                <img
                  className={"img_mobile_fa"}
                  src={resetpassGreen}
                  alt="profileFirst"
                />
              ) : (
                <img
                  className={"img_mobile_f"}
                  src={resetpass}
                  alt="profileFirst"
                />
              )}
            </li>
          </Link>
          <Link to={"/members/saved-business"}>
            <li>
              {location === "/members/saved-business" ? (
                <img
                  className={"img_mobile_fa"}
                  src={saveGreen}
                  alt="profileFirst"
                />
              ) : (
                <img className={"img_mobile_f"} src={save} alt="profileFirst" />
              )}
            </li>
          </Link>
          <Link to={"/members/reviews"}>
            <li>
              {location === "/members/reviews" ? (
                <img
                  className={"img_mobile_fa"}
                  src={commentGreen}
                  alt="profileFirst"
                />
              ) : (
                <img
                  className={"img_mobile_f"}
                  src={comment}
                  alt="profileFirst"
                />
              )}{" "}
            </li>
          </Link>

          <Link to={"/members/messages"}>
            <li>
              {location === "/members/messages" ? (
                <img
                  className={"img_mobile_fa"}
                  src={payamGreen}
                  alt="profileFirst"
                />
              ) : (
                <img
                  className={"img_mobile_f"}
                  src={payam}
                  alt="profileFirst"
                />
              )}
            </li>
          </Link>
          <Link to={"/members/exit"}>
            <li>
              {location === "/members/exit" ? (
                <img
                  className={"img_mobile_fa"}
                  src={exitGreen}
                  alt="profileFirst"
                />
              ) : (
                <img className={"img_mobile_f"} src={exit} alt="profileFirst" />
              )}{" "}
            </li>
          </Link>
        </ul>
      </header>
      <div className="centerr ul_new_back">
        <ul className="ul_new  d-md-none">
          <li className='px-1'>
            {location === "/members/profile" && (
              <div className="button_header_mobile"></div>
            )}
          </li>
          <li className='px-1'>
            {location === "/members/change-password" && (
              <div className="button_header_mobile"></div>
            )}
          </li>
          <li className='px-1'>
            {location === "/members/saved-business" && (
              <div className="button_header_mobile"></div>
            )}
          </li>
          <li className='px-1'>
            {location === "/members/reviews" && (
              <div className="button_header_mobile"></div>
            )}
          </li>
          <li className='px-1'>
            {location === "/members/messages" && (
              <div className="button_header_mobile"></div>
            )}
          </li>
          <li className='px-1'>
            {location === "/members/exit" && (
              <div className="button_header_mobile"></div>
            )}
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header_mobile;
