import React from "react";
import { MDBCard, MDBCardTitle, MDBCardImage } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CategoryCard = (props) => {
  return (
    <Link to={props.link} className="hov-trans2 hover-5ae6a2">
      {/* href={props.link} */}
      <MDBCard
        className="cat-card hov-trans2 text-center shadow-off hov-shadow "
        style={{ maxWidth: "" }}
      >
        {!props.datacome ? (
          <Skeleton
            variant="circular"
            className="img-cat radius-2"
            animation="wave"
          />
        ) : (
          <LazyLoadImage
            // PlaceholderSrc={
            //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
            // }
            className="img-cat text-center col-12"
            position=""
            src={props.img}
            alt={props.title}
          />
        )}
        <MDBCardTitle className="text-center text-cat color-858585 hover-5ae6a2">
          {!props.datacome ? (
            <Skeleton
              className="text-center skeleton-text-cat"
              animation="wave"
            />
          ) : (
            props.title
          )}
        </MDBCardTitle>
      </MDBCard>
    </Link>
  );
};

export default CategoryCard;
