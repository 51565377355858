import React, { useState, useEffect, useContext } from "react";
import Alert from "@mui/material/Alert";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
} from "mdb-react-ui-kit";
import axios from "axios";
//
import { DomainContext, LangIdContext } from "../../../App"; 
// config
import configData from "../../../config/config.json";
//components
import MetaTagsEndUserPage from "../../../components/meta-tags-seo/MetaTagsEndUserPage";
import Sidbar from "../../../components/Dashbord/sidbar/Sidbar";
import Main from "../../../components/Dashbord/Main/Main";
import Header_mobile from "../../../components/Dashbord/Header_mobile/Header_mobile";
// style
import "../indexprofile.css";
// img
import pic from "../../../images/end user/postbox.svg";
import Delete from "../../../images/register-business/delete.svg";

const Messages = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [data, setdata] = useState([
    // {
    //   id: 1,
    //   title: "مدیر سیستم",
    //   body: "درخواست شما برای ارتقا حساب کاربری دریافت شد.",
    // },
    // {
    //   id: 2,
    //   title: "مدیر سیستم",
    //   body: "درخواست شما برای ارتقا حساب کاربری دریافت شد.",
    // },
    // {
    //   id: 3,
    //   title: "مدیر سیستم",
    //   body: "درخواست شما برای ارتقا حساب کاربری دریافت شد.",
    // },
    // {
    //   id: 4,
    //   title: "مدیر سیستم",
    //   body: "درخواست شما برای ارتقا حساب کاربری دریافت شد.",
    // },
  ]);
  const [centredModal, setCentredModal] = useState(false);
  const toggleShow = () => setCentredModal(!centredModal);
  const [idDelete, setIdDelete] = useState(null);
  const [showAlert, setshowAlert] = useState(false);

  const setIDforDelete = (idDelete) => {
    setIdDelete(idDelete);
  };
  const deleteHandeler = () => {
    setdata(data.filter((item) => item.id !== idDelete));
  };
  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 4000);
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //api header
  const headerMeta = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Page: "messages",
  };

  useEffect(() => {
    if (getCookie("MemId")) {
      axios
        // .get(domain + "/api/member/meta-enduser", { headers: headerMeta })
        .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
          headers: headerMeta,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setMTagsCome(true);
            setMetaData(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      props.history.push({
        pathname: "/login",
      });
    }
  }, []);

  return (
    <>
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} /> : ""}
      <Header_mobile />
      <div>
        <div className="margin-0 d-flex">
          <div className="border-left-dashbord d-none d-md-block">
            <Sidbar />
          </div>
          <div className="bg-main-dashbord">
            <Main lenmessage={data.length}>
              <h5 className="header-main">پیام ها</h5>
              {/* messages */}
              <div className="centerr top__39">
                {data.length > 0 ? (
                  data.map((item) => (
                    <div className="message-box">
                      <div className="message-box-tit position-relative">
                        {item.title}

                        <span className="proofile_tooltip">
                          <img
                            src={Delete}
                            alt="Delete"
                            className="img_delete1 mt-1"
                            // onClick={toggleShow}
                            onClick={() => {
                              toggleShow();
                              setIDforDelete(item.id);
                            }}
                          />

                          <span className="tooltiptext tooltiptext_mbox f12">
                            حذف
                          </span>
                        </span>
                      </div>
                      <div className="message-box-body">{item.body}</div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="container_main_message_profile mt150">
                      <div>
                        <img
                          src={pic}
                          alt="pic"
                          className="img_message_profile"
                        />
                      </div>
                    </div>
                    <div className="container_main_message_profile mt25">
                      <p className="text_message_profile">هنوز پیامی نیومده</p>
                    </div>
                  </>
                )}
              </div>

              {/* alert delete */}
              {showAlert ? (
                <div className={"succsess_delete"}>
                  <Alert
                    severity="success"
                    icon={false}
                    className={"alert_succsess_delete"}
                  >
                    <p className="text_alert_delete">پیام حذف شد.</p>
                  </Alert>
                </div>
              ) : (
                ""
              )}
              <MDBModal
                tabIndex="-1"
                show={centredModal}
                setShow={setCentredModal}
                className={"bg_modal_fade"}
                id={idDelete}
              >
                <MDBModalDialog centered className="justify-content-center">
                  <MDBModalContent className="costum_modal">
                    <div className="reletive_profile">
                      <MDBBtn
                        className="btn-close close_modal"
                        color="none"
                        onClick={toggleShow}
                      ></MDBBtn>
                    </div>
                    <div>
                      <p className="text_modal pt-3 pt-md-0">
                        آیا می‌خواهید این پیام پاک شود؟
                      </p>
                    </div>
                    <div className="a_s_c">
                      <button
                        onClick={() => {
                          toggleShow();
                          deleteHandeler();
                          alertHandeler();
                        }}
                        className="button_modal"
                      >
                        حذف شود
                      </button>
                    </div>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
            </Main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Messages;
