import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
// config
import configData from "../../config/config.json";
//
import { DomainContext, LangIdContext } from "../../App";
// components
import ScrollCategory from "../scroll-category/ScrollCategory";
import Application from "../application/Application";
import RecCard from "../rec-card/RecCard";
import Rec2Card from "../rec2-card/Rec2Card";
import OverlayCard from "../overlay-card/OverlayCard";
import CurrencyCard from "../currency-card/CurrencyCard";
import CardCarousel from "../card-carousel/CardCarousel";
// imgs
import blog from "../../images/home-body/blog.svg";
import presentation from "../../images/home-body/presentation.svg";
import Dialog from "@mui/material/Dialog";

const HomeBody = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);
  //
  const [latestbiz, setLatestbiz] = useState({
    businesses: [
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
    ],
    sectionTitle: {
      icon: "",
      title: "",
      url: "",
    },
  });
  const [nearbybiz, setNearbybiz] = useState({
    businesses: [
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
    ],
    sectionTitle: {
      icon: "",
      title: "",
      url: "",
    },
  });
  const [bizrandomTit, setBizrandomTit] = useState({});
  const [bizrandomcat, setBizrandomcat] = useState({
    businesses: [
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
    ],
    sectionTitle: {
      icon: "",
      title: "",
      url: "",
      type: "",
    },
  });
  const [categories, setCategories] = useState([
    { title: "", icon: "" },
    { title: "", icon: "" },
    { title: "", icon: "" },
    { title: "", icon: "" },
    { title: "", icon: "" },
  ]);
  const [currencies, setCurrencies] = useState({
    currencies: [
      // { id: "", Symbol: "", price: "", Icon: "" },
      { symbol: "", price: "", icon: "" },
      { symbol: "", price: "", icon: "" },
      { symbol: "", price: "", icon: "" },
      { symbol: "", price: "", icon: "" },
      { symbol: "", price: "", icon: "" },
      { symbol: "", price: "", icon: "" },
    ],
    sectionTitle: {
      icon: "",
      title: "",
      url: "",
    },
  });
  const [cryptos, setCryptos] = useState({
    data: {
      currencies: [
        // { id: "", Symbol: "", price: "", Icon: "" },
        { symbol: "", price: "", icon: "" },
        { symbol: "", price: "", icon: "" },
        { symbol: "", price: "", icon: "" },
        { symbol: "", price: "", icon: "" },
        { symbol: "", price: "", icon: "" },
        { symbol: "", price: "", icon: "" },
      ],
      sectionTitle: {
        icon: "",
        title: "",
        url: "",
      },
    },
    isSuccess: true,
  });
  const [blogposts, setBlogposts] = useState([
    {
      _embedded: {
        "wp:featuredmedia": [
          {
            media_details: {
              sizes: {
                medium_large: {
                  source_url: "",
                },
              },
            },
          },
        ],
      },
      title: {
        rendered: "",
      },
      excerpt: {
        rendered: "",
      },
      id: "",
      link: "",
    },
    {
      _embedded: {
        "wp:featuredmedia": [
          {
            media_details: {
              sizes: {
                medium_large: {
                  source_url: "",
                },
              },
            },
          },
        ],
      },
      title: {
        rendered: "",
      },
      excerpt: {
        rendered: "",
      },
      id: "",
      link: "",
    },
    {
      _embedded: {
        "wp:featuredmedia": [
          {
            media_details: {
              sizes: {
                medium_large: {
                  source_url: "",
                },
              },
            },
          },
        ],
      },
      title: {
        rendered: "",
      },
      excerpt: {
        rendered: "",
      },
      id: "",
      link: "",
    },
    {
      _embedded: {
        "wp:featuredmedia": [
          {
            media_details: {
              sizes: {
                medium_large: {
                  source_url: "",
                },
              },
            },
          },
        ],
      },
      title: {
        rendered: "",
      },
      excerpt: {
        rendered: "",
      },
      id: "",
      link: "",
    },
  ]);
  const [paidbiz, setPaidbiz] = useState({
    businesses: [
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
    ],
    sectionTitle: {
      icon: "",
      title: "",
      url: "",
      type: "",
    },
  });

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };

  useEffect(() => {
    if (props.nearbybizCome) {
      setNearbybiz(props.nearbybiz);
    }
  }, [props.nearbybizCome]);

  useEffect(() => {
    if (props.latestbizCome) {
      setLatestbiz(props.latestbiz);
    }
  }, [props.latestbizCome]);

  useEffect(() => {
    if (props.paidbizCome) {
      setPaidbiz(props.paidbiz);
    }
  }, [props.paidbizCome]);

  useEffect(() => {
    if (props.bizrandomcatCome) {
      setBizrandomcat(props.bizrandomcat);
      setBizrandomTit(props.bizrandomTit);
    }
  }, [props.bizrandomcatCome]);

  useEffect(() => {
    if (props.categoriesCome) {
      setCategories(props.categories);
    }
  }, [props.categoriesCome]);

  useEffect(() => {
    if (props.blogpostsCome) {
      setBlogposts(props.blogposts);
    }
  }, [props.blogpostsCome]);

  useEffect(() => {
    if (props.currenciesCome) {
      setCurrencies(props.currencies);
    }
  }, [props.currenciesCome]);

  useEffect(() => {
    if (props.cryptosCome) {
      setCryptos(props.cryptos);
    }
  }, [props.cryptosCome]);

  return (
    <>
      {/* کارت های کسب‌وکارهای اطراف */}
      <div className="container">
        <div className="mb-65 mb-xs-31">
          <div className="d-flex align-items-end justify-content-between titbox-index-res mb-md-3">
            <div className="d-flex align-items-end ">
              <object
                data={nearbybiz.sectionTitle.icon}
                alt={nearbybiz.sectionTitle.title}
                className="tit-img1 tit-img"
                type={nearbybiz.sectionTitle.type}
              ></object>

              <a href={nearbybiz.sectionTitle.url} className="color-tit">
                <p className="color-tit2 color-tit my-0 fw-800 fs-18 fs-xs-14 color-101010 ">
                  {nearbybiz.sectionTitle.title}
                </p>
              </a>
            </div>
            {/* <Link to="/result" className="hover-5ae6a2">
              <p className="my-0 fs-14 fs-xs-14 color-7e7e7e hover-5ae6a2">
                بیشتر
              </p>
            </Link> */}
          </div>

          <div className="index-rec-cards">
            {nearbybiz.businesses.map((item) => (
              <div
                className="col-12 col-md-4 rec-md rec-center mt-sm-0"
                key={item.bId}
              >
                <RecCard
                  title={item.title}
                  desc1={item.catTitle}
                  desc2={item.location}
                  rate={item.rate}
                  img={item.photoPath}
                  url={item.url}
                  flag={item.flg}
                  datacome={props.nearbybizCome}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="index-rec-cards index-rec-cards2">
            {nearbybiz.map((item) => (
              <div className="col-12 col-md-4 rec-md rec-center mt-sm-0" key={item.BId}>
                <RecCard
                  title={item.Title}
                  desc1={item.CatTitle}
                  desc2={item.Location}
                  rate={item.Rate}
                  img={item.PhotoPath}
                  url={item.Url}
                />
              </div>
            ))}
          </div> */}

      {/* اسلایدر کارت تازه‌ترین کسب‌وکارها */}
      <div className="container ps-sm-0 p-xs-0">
        <div className="mb-40 mb-xs-12">
          <div className="d-flex align-items-end justify-content-between titbox-index-res mb-0 px-2 px-md-0">
            <div className="d-flex align-items-end ">
              <object
                data={latestbiz.sectionTitle.icon}
                alt={latestbiz.sectionTitle.title}
                className="tit-img"
                type={nearbybiz.sectionTitle.type}
              ></object>
              <a href={latestbiz.sectionTitle.url} className="color-tit">
                <p className="color-tit2 my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                  {latestbiz.sectionTitle.title}
                </p>
              </a>
            </div>
          </div>
          {/* <CardCarousel withRate={true} /> */}
          <div className="d-none d-md-block">
            <CardCarousel
              data={latestbiz.businesses}
              withRate={true}
              datacome={props.latestbizCome}
            />
          </div>
          <div className="d-block d-md-none">
            <ScrollCategory
              data={latestbiz.businesses}
              isCarousel={true}
              withRate={true}
              datacome={props.latestbizCome}
            />
          </div>
        </div>
      </div>

      <div className="container">
        {/*معرفی به گیشنیز*/}
        <div className="text-align-centerr d-none d-md-block">
          <div className="moarefi d-flex justify-content-around  align-items-center ">
            <div className=" fs-17 fw-bold align-self-center text-center ">
              کسب‌وکار فارسی‌زبان اطرافت می‌شناسی؟
              <br />
              به گیشنیز معرفی کن
            </div>
            <img
              className="moarefi-icon"
              src={presentation}
              alt="moarefi icon"
            />
            <Link
              className="moarefi-btn align-self-center shadow-off fs-18 color-242424"
              to="/introduce-business"
            >
              برای معرفی کلیک کن{" "}
            </Link>
          </div>
        </div>
        {/* مجله گیشنیز گیشنیز */}
        <div className="mb-65 mb-xs-16 mb-sm-3 ">
          <div className="d-flex align-items-end justify-content-between titbox-index-res mb-md-3">
            <div className="d-flex align-items-end ">
              <img className="tit-img" src={blog} alt="..." />
              <a
                className="color-tit"
                href={configData.BLOG_URL}
                target="_blank"
              >
                <p className="my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                  مجله گیشنیز
                </p>
              </a>
            </div>
          </div>
          <div className="row mb-md-5">
            <div className="col-12 col-xl-7 col-md-6 mb-4 mb-md-0  px-md-2 px-auto px-0 px-sm-12 ">
              <OverlayCard
                img={
                  blogposts[0]._embedded["wp:featuredmedia"]["0"].media_details
                    .sizes.medium_large.source_url
                }
                title={blogposts[0].title.rendered}
                desc={blogposts[0].excerpt.rendered}
                url={blogposts[0].link}
                datacome={props.blogpostsCome}
              />
            </div>
            <div className="col-12 col-xl-5 col-md-6 pe-md-2 px-auto  ">
              <div className="mb-10px mb-xs-14 rec-center">
                <Rec2Card
                  id={blogposts[1].id}
                  img={
                    blogposts[1]._embedded["wp:featuredmedia"]["0"]
                      .media_details.sizes.medium_large.source_url
                  }
                  title={blogposts[1].title.rendered}
                  desc={blogposts[1].excerpt.rendered}
                  url={blogposts[1].link}
                  datacome={props.blogpostsCome}
                />
              </div>
              <div className="mb-10px mb-xs-14 rec-center">
                <Rec2Card
                  id={blogposts[2].id}
                  img={
                    blogposts[2]._embedded["wp:featuredmedia"]["0"]
                      .media_details.sizes.medium_large.source_url
                  }
                  title={blogposts[2].title.rendered}
                  desc={blogposts[2].excerpt.rendered}
                  url={blogposts[2].link}
                  datacome={props.blogpostsCome}
                />
              </div>
              <div className=" rec-center">
                <Rec2Card
                  id={blogposts[3].id}
                  img={
                    blogposts[3]._embedded["wp:featuredmedia"]["0"]
                      .media_details.sizes.medium_large.source_url
                  }
                  title={blogposts[3].title.rendered}
                  desc={blogposts[3].excerpt.rendered}
                  url={blogposts[3].link}
                  datacome={props.blogpostsCome}
                />
              </div>
            </div>
          </div>
        </div>

        {/* معرفی به گیشنیز سایز موبایل*/}
        <div className="text-align-centerr d-block d-md-none">
          <div className="moarefi d-flex justify-content-between  align-items-center ">
            <div className=" align-self-center text-end text-white alert-m-text">
              کسب‌وکار فارسی‌زبان اطرافت می‌شناسی؟
              <br />
              به گیشنیز معرفی کن
            </div>

            <Link
              className="align-self-center color-242424 alert-m-btn hov-shadow-btn shadow-off"
              to="/introduce-business"
            >
              برای معرفی کلیک کن{" "}
            </Link>
          </div>
        </div>
      </div>

      {/* قیمت ارز */}
      <div className="mb-65 mb-xs-20">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 ps-md-2 mb-xs-16">
              <div className="d-flex align-items-end justify-content-between titbox-index-res mb-sm-3 mb-xs-10">
                <div className="d-flex align-items-end ">
                  <object
                    data={currencies.sectionTitle.icon}
                    alt={currencies.sectionTitle.title}
                    className="tit-img"
                    type={nearbybiz.sectionTitle.type}
                  ></object>
                  <a className="color-tit" href={currencies.sectionTitle.url}>
                    <p className="my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                      {currencies.sectionTitle.title}
                    </p>
                  </a>
                </div>
              </div>
              <div className="rec-center">
                {currencies.currencies.map((item) => (
                  <CurrencyCard
                    currencies={true}
                    key={item.id}
                    // isRed={false}
                    title={item.symbol}
                    price={item.price}
                    icon={item.icon}
                    datacome={props.currenciesCome}
                  />
                ))}
              </div>
            </div>
            {cryptos.isSuccess &&
              cryptos.data.currencies &&
              cryptos.data.currencies.length > 0 && (
                <div className="col-12 col-md-6 pe-md-2">
                  <div className="d-flex align-items-end justify-content-between titbox-index-res mb-sm-3 mt-md-0 mt-sm-3 mb-xs-10">
                    <div className="d-flex align-items-end ">
                      <object
                        data={cryptos.data.sectionTitle.icon}
                        alt={cryptos.data.sectionTitle.title}
                        type={nearbybiz.sectionTitle.type}
                        className="tit-img"
                      ></object>
                      <a
                        className="color-tit"
                        href={cryptos.data.sectionTitle.url}
                      >
                        <p className="my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                          {cryptos.data.sectionTitle.title}
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="rec-center">
                    {cryptos.data.currencies.map((item) => (
                      <CurrencyCard
                        currencies={false}
                        key={item.id}
                        // isRed={true}
                        title={item.symbol}
                        price={item.price}
                        icon={item.icon}
                        datacome={props.cryptosCome}
                      />
                    ))}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      <div className="container">
        {/* کارت های تبلیغات */}
        <div className="mb-65 mb-xs-28">
          <div className="d-flex align-items-end justify-content-between titbox-index-res mb-xs-10 mb-md-3">
            <div className="d-flex align-items-end ">
              <object
                data={paidbiz.sectionTitle.icon}
                alt={paidbiz.sectionTitle.title}
                type={nearbybiz.sectionTitle.type}
                className="tit-img"
              ></object>
              <a href={paidbiz.sectionTitle.url} className="color-tit">
                <p className="my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                  {paidbiz.sectionTitle.title}
                </p>
              </a>
            </div>
          </div>
          <div
            className={
              paidbiz.businesses.length > 2
                ? "index-rec-cards"
                : "index-rec-cards index-rec-cards2"
            }
          >
            {paidbiz.businesses.map((item) => (
              <div className="col-12 col-md-4 rec-md rec-center" key={item.bId}>
                <RecCard
                  title={item.title}
                  desc1={item.catTitle}
                  desc2={item.location}
                  rate={item.rate}
                  img={item.photoPath}
                  url={item.url}
                  flag={item.flg}
                  datacome={props.paidbizCome}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* اسلایدر کارت رندوم */}
      <div className="container ps-sm-0 p-xs-0">
        <div className="mb-65 mb-xs-20">
          <div className="d-flex align-items-end justify-content-between titbox-index-res mb-0 px-2">
            <div className="d-flex align-items-end ">
              <object
                data={bizrandomcat.sectionTitle.icon}
                alt={bizrandomcat.sectionTitle.title}
                className="tit-img"
                // type={bizrandomcat.sectionTitle.Type}
                type="image/png"
              ></object>
              <a
                href={bizrandomcat.sectionTitle.url.replace(/({loc})/g, "")}
                className="color-tit"
              >
                <p className="my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                  {bizrandomcat.sectionTitle.title}
                </p>
              </a>
            </div>
          </div>
          <div className="d-none d-md-block">
            <CardCarousel
              data={bizrandomcat.businesses}
              withRate={true}
              datacome={props.bizrandomcatCome}
            />
          </div>
          <div className="d-block d-md-none">
            <ScrollCategory
              data={bizrandomcat.businesses}
              isCarousel={true}
              withRate={true}
              datacome={props.bizrandomcatCome}
            />
          </div>
        </div>
      </div>

      {/*دسته‌بندی‌ */}
      <div className="container p-xs-0">
        <div className="mb-65 mb-xs-60">
          <ScrollCategory
            data2={categories}
            isCarousel={false}
            withRate={true}
            datacome={props.categoriesCome}
          />
        </div>
      </div>

      {/* دانلود اپلیکیشن گیشنیز*/}
      <div className="container">
        <div className="mb-45 mb-xs-40">
          <Application />
        </div>
      </div>
    </>
  );
};

export default HomeBody;
