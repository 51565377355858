import React, { useState } from "react";

const SocialMedia = (props) => {
  const [hover, setHover] = useState(false);

  return (
    <a
      target="_blank"
      href={
        SocialMedia != null && SocialMedia != undefined
          ? props.item.socialAddress
          : ""
      }
      className="business-information-social"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      {hover ? (
        <img src={props.icon2} alt={props.item.title} />
      ) : (
        <img src={props.icon1} alt={props.item.title} />
      )}
    </a>
  );
};

export default SocialMedia;
