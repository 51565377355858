import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import "./comments.css";
import Cardscommets from "./Cardscommets";
import RateIcon from "../rate-icon/RateIcon";
import LoginModal from "../login-modal/LoginModal";
import { DomainContext, LangIdContext } from "../../App";
// img
import nextg from "../../images/business/next.svg";
import commentsIcon from "../../images/business/comments.svg";

const Comments = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  //give data from api
  const [data, setData] = useState({
    all: 0,
    awli: 0,
    khob: 0,
    motevaset: 0,
    zaief: 0,
    bad: 0,
    rate: 0,
  });

  const [more, setMore] = useState(false);

  const moreCommentHandler = () => {
    setMore(true);
  };

  const [com, setCom] = useState([]);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(() => {
    if (props.dataCome) {
      if (props.data !== null) {
        setData({
          all: props.data.personCount,
          awli: props.data.great,
          khob: props.data.excellent,
          motevaset: props.data.average,
          zaief: props.data.bad,
          bad: props.data.awful,
          rate: props.data.score,
        });
      }
    }
  }, [props.dataCome]);

  useEffect(() => {
    if (props.comCome) {
      if (props.data !== null) {
        setCom(props.com);
      }
    }
  }, [props.comCome]);

  return (
    <>
      <h1 className=" text-main-nazar">امتیاز و دیدگاه کاربران</h1>

      <MDBRow className="mx-0 main-border">
        <MDBCol lg="4" className="padding-col4-comment">
          <MDBRow>
            {" "}
            <div className="d-flex align-items-center">
              <span className="f-22px ml--5 fw-bold">{data.rate}</span>
              <span className="f-12px fw-bold">از 5</span>
            </div>
            <div className="mb-3 pb-md-1">
              <span className="float-end">
                <RateIcon
                  cardrate={parseFloat(data.rate)}
                  isRec={0}
                  isAdd={0}
                  isComment1={1}
                  dir="ltr "
                />
              </span>
              <span className=" text1-comment">از مجموع {data.all} نفر</span>
            </div>
          </MDBRow>
          <div>
            <p className="mb--5">
              <small className="float-end comment-prog-text">عالی</small>
              <small className="float-start comment-prog-numb">
                {data.awli}
              </small>
            </p>
            <progress
              className="progress-color"
              value={data.awli}
              max={data.all}
            ></progress>
          </div>
          <div>
            <p className="mb--5">
              <small className="float-end comment-prog-text">خوب</small>
              <small className="float-start comment-prog-numb">
                {data.khob}
              </small>
            </p>

            <progress
              className="progress-color"
              value={data.khob}
              max={data.all}
            ></progress>
          </div>
          <div>
            <p className="mb--5">
              <small className="float-end comment-prog-text">متوسط</small>
              <small className="float-start comment-prog-numb">
                {data.motevaset}
              </small>
            </p>
            <progress
              className="progress-color"
              value={data.motevaset}
              max={data.all}
            ></progress>
          </div>
          <div>
            {" "}
            <p className="mb--5">
              <small className="float-end comment-prog-text">ضعیف</small>
              <small className="float-start comment-prog-numb">
                {data.zaief}
              </small>
            </p>
            <progress
              className="progress-color"
              value={data.zaief}
              max={data.all}
            ></progress>
          </div>
          <div>
            {" "}
            <p className="mb--5">
              <small className="float-end comment-prog-text">بد</small>
              <small className="float-start comment-prog-numb">
                {data.bad}
              </small>
            </p>
            <progress
              className="progress-color"
              value={data.bad}
              max={data.all}
            ></progress>
          </div>
          <p className="sabt-nazar-comment text-align-center">
            شما هم درباره این کسب‌و‌کار دیدگاه ثبت کنید
          </p>
          {/* <div className="centerr">
            <Link
              className="btn-nazar-comment hov-shadow"
              to=`/write-review?BizId=+`
            >
              دیدگاه
            </Link>
          </div> */}

          {getCookie("MemId") ? (
            <div className="centerr">
              {props.userHaveComment ? (
                <>
                  {/* <div className="btn-nazar-comment">ثبت دیدگاه</div>     برای زمانی که دیدگاه دارد، با کلیک روی این اتفاقی نمی افتد*/}
                  <Link
                    className="btn-nazar-comment hov-shadow"
                    to={`/write-review/${props.id}`}
                  >
                    ویرایش دیدگاه
                  </Link>
                </>
              ) : (
                <Link
                  className="btn-nazar-comment hov-shadow"
                  to={`/write-review/${props.id}`}
                >
                  ثبت دیدگاه
                </Link>
              )}
            </div>
          ) : (
            <LoginModal id={3} url={props.url} text={"ثبت دیدگاه"} />
          )}
        </MDBCol>
        <MDBCol lg="8" className="px-md-0 card-components-col">
          <div className="custom-hr d-md-none"></div>

          {com.length == 0 ? (
            getCookie("MemId") ? (
              // props.userHaveComment ? (
              //   <div className="com-empty-biz mt-4 mt-md-0">
              //     <div className="d-flex align-items-center justify-contet-center">
              //       <div className="text-align-center mx-auto">
              //         <img
              //           src={commentsIcon}
              //           alt="comments"
              //           className="img-com-empty-biz"
              //         />
              //         <p>شما اولین دیدگاه را ثبت کنید.</p>
              //       </div>
              //     </div>
              //   </div>
              // ) : (
              <Link to={`/write-review/${props.id}`}>
                <div className="com-empty-biz mt-4 mt-md-0">
                  <div className="d-flex align-items-center justify-contet-center">
                    <div className="text-align-center mx-auto">
                      <img
                        src={commentsIcon}
                        alt="comments"
                        className="img-com-empty-biz"
                      />
                      <p>شما اولین دیدگاه را ثبت کنید.</p>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              // )
              <LoginModal id={32} url={props.url} text={"ثبت دیدگاه"} />
            )
          ) : (
            com.map((item) => (
              <Cardscommets
                key={item.fullname}
                rate={item.rate}
                likes={item.agree}
                name={item.fullname}
                date={item.commentDate}
                length={267}
                title={item.commentTitle}
                text={item.commentBody}
                img={item.photoPath}
              />
            ))
          )}
          {/* <Cardscommets
            likes={2}
            length={267}
            text="It's a nice, sturdy little stand for a headset. The metal is actually stronger than I expected it to be, and there's rubber where it sits on the desk and where the headband of the headset rests on the stand, which is nice and keeps things where they're supposed to"
          />*/}

          {/* <div className={more ? "d-block" : "d-none"}>
            {" "}
            <Cardscommets
              likes={2}
              length={267}
              text="It's a nice, sturdy little stand for a headset. The metal is actually stronger than I expected it to be, and there's rubber where it sits on the desk and where the headband of the headset rests on the stand, which is nice and keeps things where they're supposed to"
            />
          </div> */}
          {com.length > 4 ? (
            <MDBBtn className="a-arrow-comments" onClick={moreCommentHandler}>
              <span>دیدگاه‌های دیگر</span>
              <span className="arrow-comments">
                <img src={nextg} className="business-comment-more-img" alt="" />
              </span>
            </MDBBtn>
          ) : (
            <p></p>
          )}
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default Comments;
