import React from "react";
import ReactDOM from "react-dom/client";
import "mdb-react-ui-kit/dist/css/mdb.min.css"; // React Mdb5 Css
import "./styles/css/index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="759409307984-5prcgggj3i0tj0ob9fbqboumlh0qqg1v.apps.googleusercontent.com">
  {/* <GoogleOAuthProvider clientId="365675004831-ebm2f3t7ikdn23k0hdcrg6s98k9e584o.apps.googleusercontent.com"> */}
    {/* <GoogleOAuthProvider clientId="25572161176-nr7ueb5nla0r4b2j0gf70t7j3n5uffl9.apps.googleusercontent.com"> */}
    <App />
  </GoogleOAuthProvider>
);
