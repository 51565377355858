import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import "./NotFound.css";
import { DomainContext, LangIdContext } from "../../App";
// components
import Header2 from "../../components/header/Header2";
import Headermobile from "../../components/header-mobile/Headermobile";
import Footer from "../../components/footer/Footer";
// images
import main from "../../images/NotFound/Artboard 1.svg";
import right from "../../images/NotFound/Artboard 2.svg";
import left from "../../images/NotFound/Artboard 3.svg";
// config
import configData from "../../config/config.json";

const NotFound = () => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [ApiDataCome, setApiDataCome] = useState({
    headerbiz: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    headerLogo: {
      title: "",
      link: "",
    },
  });

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };
  useEffect(() => {
    // 1 header-other
    axios
      .get(configData.API_DOMAIN + "/api/v1/Headers/other", {
        headers: header,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setheaderData(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            headerbiz: true,
          }));
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 2 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  const [details, setDetails] = useState(null);
  // this part gives the ip
  // useEffect(() => {
  //   fetch("https://ipapi.co/json/")
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (data) {
  //       console.log("1111111111");
  //       console.log(data);
  //     });
  //   // 2 -
  //   axios
  //     .get("https://api.ipify.org?format=json", {
  //       // headers: header,
  //     })
  //     .then((response) => {
  //       console.log("loc 9999");
  //       console.log(response.data);
  //     })
  //     .catch((error) => {
  //       // alert(error);
  //     });
  //   fetch(
  //     "https://geolocation-db.com/json/f2e84010-e1e9-11ed-b2f8-6b70106be3c8"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log("data");
  //       console.log(data);
  //       setDetails(data);

  //       axios
  //         .get(
  //           `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${data.latitude}&lon=${data.longitude}`,
  //           {
  //             // headers: header,
  //           }
  //         )
  //         .then((response) => {
  //           console.log("loc ----");
  //           console.log(response.data);
  //         })
  //         .catch((error) => {
  //           // alert(error);
  //         });
  //     });
  // }, []);
  // this part gives the ip

  // comment 0
  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     console.log("Available");

  //     navigator.geolocation.getCurrentPosition(
  //       function (position) {
  //         console.log("Latitude is");
  //         console.log("Latitude is :", position.coords.latitude);
  //         console.log("Longitude is :", position.coords.longitude);
  //       },
  //       function (error) {
  //         console.error("Error Code = " + error.code + " - " + error.message);
  //       }
  //     );
  //   } else {
  //     console.log("Not Available");
  //   }
  //   // if (navigator.geolocation) {
  //   //   navigator.geolocation.watchPosition(function (position) {
  //   //     console.log("Latitude is=======================");
  //   //     console.log(position);
  //   //     console.log("222222222======================");

  //   //     console.log("Latitude is :", position.coords.latitude);
  //   //     console.log("Longitude is :", position.coords.longitude);
  //   //   });
  //   // }
  // }, []);

  // var options = {
  //   enableHighAccuracy: true,
  //   timeout: 5000,
  //   maximumAge: 0,
  // };
  // function success(pos) {
  //   var crd = pos.coords;
  //   console.log("Your current position is:");
  //   console.log(`Latitude : ${crd.latitude}`);
  //   console.log(`Longitude: ${crd.longitude}`);
  //   console.log(`More or less ${crd.accuracy} meters.`);
  // }

  // function errors(err) {
  //   console.warn(`ERROR(${err.code}): ${err.message}`);
  // }

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.permissions
  //       .query({ name: "geolocation" })
  //       .then(function (result) {
  //         console.log(result);
  //         if (result.state === "granted") {
  //           //If granted then you can directly call your function here
  //           navigator.geolocation.getCurrentPosition(success, errors, options);
  //         } else if (result.state === "prompt") {
  //           //If prompt then the user will be asked to give permission
  //           navigator.geolocation.getCurrentPosition(success, errors, options);
  //         } else if (result.state === "denied") {
  //           //If denied then you have to show instructions to enable location
  //         }
  //       });
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //   }
  // }, []);

  // useEffect(() => {
  // fetch(
  //   "https://api.ip2location.io/?key=B66F8C4D6ECD714952C17B678B9AF275&ip=138.199.9.179&format=json"
  // )
  //   .then((response) => response.json())
  //   .then((data) => {
  //     console.log("data----5265");
  //     console.log(data);
  //   })
  //   .catch((error) => console.log(error));
  // axios
  //   .get("https://api.ipify.org?format=json", {
  //     // headers: header,
  //   })
  //   .then((response) => {
  //     console.log("response------------------------1--------------");
  //     console.log(response.data.ip);
  //     console.log(
  //       `https://api.ip2location.io/?key=B66F8C4D6ECD714952C17B678B9AF275&ip=${response.data.ip}&format=json`
  //     );
  //     axios
  //       .get(
  //         "https://api.ip2location.io/?key=B66F8C4D6ECD714952C17B678B9AF275&ip=138.199.9.179&format=json",
  //         {
  //           // .get("https://api.ip2location.io/?key=B66F8C4D6ECD714952C17B678B9AF275", {
  //           // headers: header,
  //         }
  //       )
  //       .then((response) => {
  //         console.log("response------------------------9--------------");
  //         console.log(response);
  //         console.log(response.data);
  //       })
  //       .catch((error) => {
  //         // alert(error);
  //       });
  //   })
  //   .catch((error) => {
  //     // alert(error);
  //   });
  // }, []);

  return (
    <>
      <div className="d-none d-md-block">
        <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
      </div>
      <div className="d-block d-md-none">
        <div className="fixed-header">
          <Headermobile data={headerData} />
        </div>
        <div className="box-under-header-res "></div>
      </div>

      <MDBContainer>
        <MDBRow>
          <MDBCol size="12">
            <h3 className="header_not_found mb-md-3">راه رو اشتباه اومدی!</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" md="7" lg="5" order="md-2">
            <div className="mt-30notfound">
              <img src={main} alt="" className="img_notfound" />
              <img className="img_right_notfound" src={right} alt="" />
              <img className="img_left_notfound" src={left} alt="" />
            </div>
          </MDBCol>
          <MDBCol size="12" md="5" lg="7" order="md-1">
            {/* <h3 className="header_not_found2">برو به:</h3> */}

            <ul className="list_notfound">
              <div className="d-flex d-md-block justify-content-center">
                <li>
                  <Link to={"/"} className="button_not_found hov-shadow-btn">
                    صفحه اصلی
                  </Link>
                </li>
                <li>
                  <a
                    href={configData.BLOG_URL}
                    className="button_not_found hov-shadow-btn"
                  >
                    مجله گیشنیز
                  </a>
                </li>
              </div>
              <div className="d-flex d-md-block justify-content-center">
                <li>
                  <Link to={"/search?find_desc=&find_loc=&cat_id=11&find_cat=وکالت%20و%20امور%20حقوقی"} className="button_not_found hov-shadow-btn">
                    وکالت
                  </Link>
                </li>
                <li>
                  <Link to={"/search?find_desc=&find_loc=&cat_id=165&find_cat=املاک"} className="button_not_found hov-shadow-btn">
                    املاک
                  </Link>
                </li>
              </div>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer data={footerData} dataCome={ApiDataCome.footer} />
    </>
  );
};

export default NotFound;
