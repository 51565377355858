import React, { useState } from "react";
import Alert from "@mui/material/Alert";

const AlertSuccess = (props) => {
  return (
    <>
      {props.showAlert && (
        <div className={"succsess_delete succsess_delete_resp1"}>
          <Alert
            severity="success"
            icon={false}
            className={"alert_succsess_delete"}
          >
            <p className="text_alert_delete fw-normal">
             {props.text}
            </p>
          </Alert>
        </div>
      )}
    </>
  );
};

export default AlertSuccess;
