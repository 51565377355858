import React, { useEffect, useState } from "react";
import axios from "axios";

import CategoryCard from "./CategoryCard";
import SquareCard from "../square-card/SquareCard";

const ScrollCategory = (props) => {
  return (
    <>
      {props.isCarousel ? (
        <div className="category-items category-items2 ">
          {props.data.map((item) => (
            <div key={item.bId} className="cat-item2 cat-item2-child-caro ">
              <SquareCard
                title={item.title}
                desc1={item.catTitle}
                desc2={item.location}
                rate={item.rate}
                img={item.photoPath}
                url={item.url}
                withRate={item.withRate}
                flag={item.flg}
                datacome={props.datacome}
              />
            </div>
          ))}
          {/* <div className="cat-item2 me-2 me-md-0">
            <SquareCard
              title={"نادر پناهی "}
              desc1={"مشاور املاک"}
              desc2={"ریچموند هیل، کانادا"}
              rate={"2"}
              withRate={props.withRate}
            />       
          </div> */}
        </div>
      ) : (
        <div className="centerr">
          <div className="text-center color-101010 mb-50 mb-xs-0 fw-bold fs-25 fs-xs-16">
            دسته‌بندی‌
          </div>
          <div className="category-items pt-md-3  pt-xs-40">
            {props.data2.map((item) => (
              <div key={item.cId} className="cat-item cat-item-child-scro">
                <CategoryCard
                  title={item.title}
                  img={item.icon}
                  link={item.url}
                  datacome={props.datacome}
                />
              </div>
            ))}
            {/* <div className="cat-item me-2 me-md-0">
              <CategoryCard
                title="خرید"
                icon=""
                link="/result"
              />
            </div>
 */}
          </div>
        </div>
      )}
    </>
  );
};

export default ScrollCategory;
