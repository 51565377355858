import React, { useState } from "react";
import { MDBRow } from "mdb-react-ui-kit";
import "./comments.css";

import report from "../../images/business/report gray.svg";
import likezero from "../../images/business/like gray.svg";
import likei from "../../images/business/like.svg";
import likedone from "../../images/business/like done.svg";
import userIcon from "../../images/login img/user gray.svg";

import RateIcon from "../rate-icon/RateIcon";

const Cardscommets = (props) => {
  // for like icon and number
  const [click, setClick] = useState(false);
  const [like, setLike] = useState(props.likes);
  // for more and less btn
  const [showLess, setShowLess] = React.useState(true);
  const text = props.text;
  const length = props.length;

  const likeHandler = () => {
    if (!click) {
      setClick(true);
      setLike((prevState) => prevState + 1);
    } else {
      setClick(false);
      setLike((prevState) => prevState - 1);
    }
  };

  return (
    <>
      <MDBRow className="ms-md-0 pr-md-0 padding-row-cardcomment pt-2 ">
        <div className="pt-1">
          <div className="d-flex">
            <img
              src={
                props.img === "https://cdn.gishniz.link" ? userIcon : props.img
              }
              alt=""
              className="image-card-comments"
            />
            <div className="icon-comments">
              <span className="name-comments">{props.name}</span>
              <RateIcon
                cardrate={parseFloat(props.rate)}
                isRec={0}
                isAdd={0}
                isComment2={1}
                dir="ltr "
              />
            </div>
          </div>
        </div>
        <div>
          <div className="comments mt-2 mt-md-3 mb-2">
            <p className="mb-1 fw-bold">{props.title}</p>
            {text.length < length ? (
              // without more or less
              <p className="mb-2">{text}</p>
            ) : (
              <div>
                <p
                  className="mb-2"
                  dangerouslySetInnerHTML={{
                    __html: showLess ? `${text.slice(0, length)}` : text,
                  }}
                ></p>
                <a
                  style={{
                    color: "#67808f",
                    cursor: "pointer",
                    position: "absolute",
                  }}
                  onClick={() => setShowLess(!showLess)}
                >
                  {showLess ? "بیشتر" : ""}
                </a>
              </div>
            )}
            <p className="text-comments-label font-family-en">
              {/* نوشته شده در ونکوور در تاریخ 23 جولای 2022 */}
              {props.date}
            </p>
          </div>
        </div>
        {/* تا فاز دوم کامنت شود */}
        {/* <div className="text-align-last-left ps-3">
          <span className="stle-goza">
            <span className="ml5">گزارش</span>
            <img className="business-comment-img1" src={report} alt="report" />
          </span>
          <span className="stle-goza">
            <span className="ml5">{like}</span>
            {props.likes === 0 ? (
              click ? (
                <img
                  className="business-comment-img2"
                  src={likedone}
                  alt="like"
                  onClick={likeHandler}
                />
              ) : (
                <img
                  className="business-comment-img2"
                  src={likezero}
                  alt="like"
                  onClick={likeHandler}
                />
              )
            ) : click ? (
              <img
                className="business-comment-img2"
                src={likedone}
                alt="like"
                onClick={likeHandler}
              />
            ) : (
              <img
                className="business-comment-img2"
                src={likei}
                alt="like"
                onClick={likeHandler}
              />
            )}
          </span>
        </div> */}
      </MDBRow>
      <div className={props.hrnone ? "d-none" : "custom-hr"}></div>
    </>
  );
};

export default Cardscommets;
