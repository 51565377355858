import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
// import { MDBBtn } from "mdb-react-ui-kit";
import axios from "axios";
import { LangIdContext } from "../App";
// components
import MetaTagsEndUserPage from "../components/meta-tags-seo/MetaTagsEndUserPage";
import Footer from "../components/footer/Footer";
// images
import logo from "../images/home-body/logo gishniz.png";
import closeIcon from "../images/header/close.svg";
// config
import configData from "../config/config.json";

const IntroduceBusinessThanks = (props) => {
  // const { state } = props.location;
  const state = JSON.parse(localStorage.getItem("business-introduction-data"));

  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [bizName, setBizName] = useState("");

  const [ApiDataCome, setApiDataCome] = useState({
    // header: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Authorization: getCookie("token") ? "Bearer " + getCookie("token") : "",
  };
  useEffect(() => {
    // 1 metaTags
    axios
      .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
        headers: {
          ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
          LangId: langIdState,
          Page: "introduce-business",
        },
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setMTagsCome(true);
          setMetaData(response.data.data);
        }
      })
      .catch((error) => {
        // alert(error);
      });

    if (state) {
      setBizName(state.engName);

      const dataPost = {
        enTitle: state.engName,
        faTitle: state.perName,
        countryId: parseInt(state.countryyId),
        provinceId: parseInt(state.provinceeId),
        cityId: parseInt(state.cityyId),
        address: state.addresss,
        webSite: state.webOrInsta[0] || "",
        categories: state.categoryId,
      };

      axios
        // .post(domain + "/api/business-suggest", dataPost, {
        .post(
          configData.API_DOMAIN + "/api/v1/BusinessesEditSuggestion",
          dataPost,
          {
            headers: header,
          }
        )
        .then((response) => {
          if (response.data.isSuccess) {
            setApiDataCome(true);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    }

    // 3 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const clickHandlerMore = () => {
    // delete local storage
    localStorage.removeItem("business-introduction-data");
    localStorage.removeItem("business-introduction-data-cat");
    props.history.push({
      pathname: "/introduce-business",
    });
  };

  const clickHandler = () => {
    // delete local storage
    localStorage.removeItem("business-introduction-data");
    localStorage.removeItem("business-introduction-data-cat");
    props.history.push({
      pathname: "/",
    });
  };

  return (
    <>
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} noindex={true} /> : ""}

      {ApiDataCome ? (
        <>
          <div className="container">
            <div className="centerr">
              <Link onClick={clickHandler} className="ibt-logo-a">
                <img src={logo} alt="logo gishniz" className="ibt-logo" />
              </Link>

              <div className="ibt-box text-end">
                <Link onClick={clickHandler}>
                  <img
                    className="ibt-close-img me-0"
                    src={closeIcon}
                    alt="close icon"
                  />
                </Link>
                <div className="centerr">
                  <p className="ibt-text1">کسب‌وکار با موفقیت ثبت شد.</p>
                  <p className="ibt-text2">
                    ممنون که ({bizName}) را به ما معرفی کردی. اطلاعات این بیزینس
                    پس از بررسی توسط تیم گیشنیز منتشر خواهد شد.
                  </p>
                </div>
              </div>

              {/* <MDBBtn className="ibt-sabt-btn hov-shadow-btn shadow-off"> */}
              <div>
                <Link
                  // to="/introduce-business"
                  onClick={clickHandlerMore}
                  className="ibt-sabt-at hov-shadow2 shadow-off"
                >
                  معرفی کسب‌وکارهای بیشتر
                </Link>
                {/* </MDBBtn> */}
              </div>
            </div>
          </div>
          <Footer data={footerData} dataCome={ApiDataCome.footer} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default IntroduceBusinessThanks;
