import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { LangIdContext } from "../App";
// components
import MetaTagsEndUserPage from "../components/meta-tags-seo/MetaTagsEndUserPage";
import Footer from "../components/footer/Footer";
// config
import configData from "../config/config.json";

const IntroduceBusinessConfirm = (props) => {
  // const { state } = props.location;
  const state = JSON.parse(localStorage.getItem("business-introduction-data"));

  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [ApiDataCome, setApiDataCome] = useState({
    // header: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };
  useEffect(() => {
    // 1 metaTags
    axios
      .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
        headers: {
          ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
          LangId: langIdState,
          Page: "introduce-business",
        },
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setMTagsCome(true);
          setMetaData(response.data.data);
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 2 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  const clickHandler = () => {
    // console.log("state");
    // console.log(state);
    // if user is log in
    if (getCookie("MemId") !== "") {
      props.history.push({
        pathname: "/introduce-business/introduce-business-thanks",
        // state: state, // your data array of objects
      });
    } else {
      props.history.push({
        pathname: "/login",
        state: "from-introduce", // back url
      });
    }
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <>
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} noindex={true} /> : ""}

      <div className="container centerr">
        <div className="ib-confirm-box">
          <div className="ib-confirm-box2">
            <p className="ib-confirm-tit">آیا اطلاعات وارد شده درست است ؟ </p>
            <div className="d-flex justify-content-between">
              <p className="mb-2 mb-md-3">نام کسب‌وکار به انگلیسی</p>
              <p
                className="mb-2 mb-md-3 ib-confirm-ename font-family-en"
                dir="ltr"
              >
                {state.engName}
              </p>
            </div>
            {state.perName && (
              <div className="d-flex justify-content-between">
                <p className="mb-1 mb-md-3">نام کسب‌وکار به فارسی</p>
                <p className="mb-1 mb-md-3 ib-confirm-pname" dir="ltr">
                  {state.perName}
                </p>
              </div>
            )}
            <div className="d-flex justify-content-between mt-2  mt-md-3">
              <p className="mb-2 mb-md-3">کشور</p>
              <p className="mb-2 mb-md-3 " dir="ltr">
                {state.countryy}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-2 mb-md-3">استان</p>
              <p className="mb-2 mb-md-3 " dir="ltr">
                {state.provincee}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-2 mb-md-3">شهر</p>
              <p className="mb-2 mb-md-3 " dir="ltr">
                {state.cityy}
              </p>
            </div>
            <div className="mt-1 mt-md-3">
              {/* <p className="mb-0" dir="ltr"> */}
              {/* www.instagram.com./Tehtonchi.. */}

              {Object.keys(state.webOrInsta).map((c) => {
                return (
                  <div className="d-flex justify-content-between " key={c}>
                    <p className="mb-0 text-end">وبسایت یا سوشال مدیا </p>
                    <p className="mb-0" dir="ltr">
                      {state.webOrInsta[c]}
                    </p>
                  </div>
                );
                // console.log(state.webOrInsta[c]);
              })}
              {/* </p> */}
            </div>
            {/* <div className="d-flex justify-content-between">
            <p className="mb-0">وبسایت</p>
            <p className="mb-0" dir="ltr">
              Tehronchi.com
            </p>
          </div> */}

            <div className="d-flex justify-content-between ">
              {/* <MDBBtn className="ib-sabt-btn1 hov-shadow-btn shadow-off"> */}

              <Link
                to={{
                  pathname: "/introduce-business",
                  state: state,
                  // hash:"1"
                }}
                className="ib-sabt-a1 hov-shadow-btn shadow-off"
              >
                بازگشت
              </Link>
              {/* </MDBBtn> */}
              {/* <MDBBtn className="ib-sabt-btn2 hov-shadow-btn shadow-off"> */}
              <Link
                // to={{
                //   pathname: "/login",
                //   state: state,
                // }}
                onClick={clickHandler}
                className="ib-sabt-a2 hov-shadow-btn shadow-off"
              >
                بله درست است
              </Link>
              {/* </MDBBtn> */}
            </div>
          </div>
        </div>
      </div>
      <Footer data={footerData} dataCome={ApiDataCome.footer} />
    </>
  );
};

export default IntroduceBusinessConfirm;
